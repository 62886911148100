import { IUser, UserToken } from '@shared/types';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Select } from 'antd';
import React from 'react';

interface Props {
  onChange?: (newValue: UserToken) => void;
  disabled?: boolean;
  value?: UserToken;
  managerToken?: UserToken;
}

export const SelectReport: React.FC<Props> = ({
  onChange,
  disabled,
  value,
  managerToken,
}) => {
  const { data: reports, error } = useApi<IUser[], ServerResponseError>(
    managerToken
      ? `/users/reports?managerToken=${managerToken}`
      : '/users/reports',
  );
  if (error || !reports) {
    return null;
  }
  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleSelectChange = (value: UserToken) => {
    onChange?.(value);
  };

  const options = reports.map((user: IUser) => ({
    label: user.name,
    value: user.token,
  }));

  return (
    <Select
      showSearch
      options={options}
      disabled={disabled}
      defaultValue={value}
      filterOption={filterOption}
      onChange={handleSelectChange}
      optionFilterProp="children"
    />
  );
};
