import { createUserMap } from '@backend/common/createUserTokenNameMap';
import { IFeedback, IUser } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Button, Skeleton } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ViewFeedback } from './FeedbackList/ViewFeedback';

export const ViewFeedbackPage: React.FC = () => {
  const { userToken, feedbackToken } = useParams();
  const { data: response, error } = useApi<{
    feedback: IFeedback;
    giver: IUser;
    receiver: IUser;
    requester: IUser;
  }>(`/feedback/${feedbackToken}`);

  if (error) {
    return (
      <ErrorPageContent
        statusCode={error.statusCode}
        title={
          error.statusCode === 404 ? 'This page does not exist' : undefined
        }
        extra={
          <Link to="/feedback">
            <Button>Back to Feedback</Button>
          </Link>
        }
      />
    );
  } else if (!response) {
    return (
      <PageContent>
        <PageHeader
          title="View feedback"
          mobileTitle="View feedback"
          navigateBack
          defaultNavigateBackTo="/feedback"
        />
        <Skeleton />
      </PageContent>
    );
  }

  const userMap = createUserMap([
    response.giver,
    response.receiver,
    response.requester,
  ]);

  return (
    <PageContent>
      <PageHeader
        title="View feedback"
        mobileTitle="View feedback"
        navigateBack
        defaultNavigateBackTo={
          userToken ? `/team/${userToken}/feedback` : '/feedback'
        }
      />
      <ViewFeedback feedback={response.feedback} userMap={userMap} />
    </PageContent>
  );
};
