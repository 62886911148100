import { MetricType, MetricTypeLabels } from '@shared/analytics';
import { Feature } from '@shared/features';
import { useAuth } from '@web/auth/useAuth';
import localStorage from '@web/common/localStorage';
import { useFeature } from '@web/common/useFeature';
import { Select } from 'antd';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

export const SelectMetrics: React.FC = () => {
  const { handleChooseMetrics, metricTypesToShow, metricTypeOptions } =
    useEnabledMetrics();

  return (
    <Select
      mode="multiple"
      value={metricTypesToShow}
      onChange={handleChooseMetrics}
      placeholder="Select metrics you want to view"
    >
      {metricTypeOptions.map((metricType) => (
        <Select.Option value={metricType} key={metricType}>
          {MetricTypeLabels[metricType]}
        </Select.Option>
      ))}
    </Select>
  );
};

const DEFAULT_METRIC_TYPES = [
  MetricType.JOURNAL_USER_WITH_ENTRIES,
  MetricType.JOURNAL_WEEKLY_ENTRIES,
  MetricType.JOURNAL_ALIGNMENT,
  MetricType.GOALS,
  MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED,
  MetricType.ONE_ON_ONES_ALIGNMENT,
  MetricType.MANAGER_ONE_ON_ONES_SCHEDULED,
];

export const useEnabledMetrics = () => {
  const { organization } = useAuth();
  const [metricsChanged, setMetricsChanged] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { booleanValue: journalEnabled } = useFeature(Feature.JOURNAL);
  const { booleanValue: goalsEnabled } = useFeature(Feature.GOALS);
  const { booleanValue: oneOnOnesEnabled } = useFeature(Feature.ENABLE_1ON1s);
  const { booleanValue: alignmentIndicatorsEnabled } = useFeature(
    Feature.ENABLE_ALIGNMENT_INDICATORS,
  );

  const enabledMetrics = new Set<MetricType>();
  if (journalEnabled) {
    enabledMetrics.add(MetricType.JOURNAL_USER_WITH_ENTRIES);
    enabledMetrics.add(MetricType.JOURNAL_WEEKLY_ENTRIES);
    if (alignmentIndicatorsEnabled && organization.journalAlignmentEnabled) {
      enabledMetrics.add(MetricType.JOURNAL_ALIGNMENT);
    }
  }
  if (goalsEnabled) {
    enabledMetrics.add(MetricType.GOALS);
  }
  if (oneOnOnesEnabled) {
    enabledMetrics.add(MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED);
    enabledMetrics.add(MetricType.MANAGER_ONE_ON_ONES_SCHEDULED);
    if (alignmentIndicatorsEnabled && organization.oneOnOnesAlignmentEnabled) {
      enabledMetrics.add(MetricType.ONE_ON_ONES_ALIGNMENT);
    }
  }

  const localDefaultMetricTypes = localStorage.getItem('defaultMetricTypes')
    ? JSON.parse(localStorage.getItem('defaultMetricTypes'))
    : undefined;
  const defaultMetricTypes = DEFAULT_METRIC_TYPES.filter((metric) =>
    enabledMetrics.has(metric),
  );
  const metricTypeOptions = Object.values(MetricType).filter((metric) =>
    enabledMetrics.has(metric),
  );
  const metricTypesToShow: MetricType[] = searchParams.has('metric')
    ? (searchParams.getAll('metric') as any)
    : metricsChanged
    ? []
    : localDefaultMetricTypes ?? defaultMetricTypes;

  const handleChooseMetrics = (metricTypes: MetricType[]) => {
    setMetricsChanged(true);
    localStorage.setItem('defaultMetricTypes', JSON.stringify(metricTypes));
    setSearchParams((prev) => {
      prev.delete('metric');
      for (const metricType of metricTypes) {
        prev.append('metric', metricType);
      }
      return prev;
    });
  };

  return {
    handleChooseMetrics,
    metricTypesToShow,
    metricTypeOptions,
  };
};
