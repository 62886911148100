import { PlusOutlined } from '@ant-design/icons';
import { useAdminSurveyList } from '@client/SurveyCyclesClient';
import { ISurveyCycle, ISurveySummaryRow } from '@shared/surveys';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button, Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminPageContent } from '../../admin/AdminPageContent';
import { AddSurveyModal } from './AddSurveyModal';
import { SurveyCyclesTable } from './SurveyCyclesTable';

const AdminSurveyCyclesPage: React.FC = () => {
  const { data: response } = useAdminSurveyList();
  const navigate = useNavigate();
  const [showAddSurvey, setShowAddSurvey] = React.useState(false);

  const handleReviewCycleClick = (row: ISurveySummaryRow) => {
    navigate(`/admin/surveys/${row.token}`);
  };

  const handleNewSurvey = async (surveyCycle: ISurveyCycle) => {
    navigate(`/admin/surveys/${surveyCycle.token}`);
  };

  return (
    <AdminPageContent>
      <Row gap={6}>
        <GrowingSpacer />
        <Button
          type="primary"
          onClick={() => {
            setShowAddSurvey(true);
          }}
          style={{ maxWidth: 200 }}
        >
          <PlusOutlined /> New survey
        </Button>
      </Row>
      {response ? (
        <SurveyCyclesTable
          rows={response.rows}
          onRowClick={handleReviewCycleClick}
        />
      ) : (
        <Skeleton />
      )}
      {showAddSurvey && (
        <AddSurveyModal
          onAdd={handleNewSurvey}
          onCancel={() => {
            setShowAddSurvey(false);
          }}
        />
      )}
    </AdminPageContent>
  );
};

export default AdminSurveyCyclesPage;
