import { productDetails } from '@shared/reflections';
import { IReviewCycle, IReviewCycleDashboardRow } from '@shared/review-cycles';
import { IUser } from '@shared/types';
import { UserMessage } from '@web/components/UserMessage';
import { Column } from '@web/components/layout';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';

import {
  ManagerReflectionCell,
  PeerFeedbackGivenCell,
  PeerFeedbackReceivedCell,
  PeerSelectionActionCell,
  ReviewsSharedCell,
  SelfReflectionCell,
} from './dashboard';

export const ReviewCycleDashboard: React.FC<{
  reviewCycle: IReviewCycle;
  dashboardRows: IReviewCycleDashboardRow[];
  onChange?: () => void;
  isWidgetView?: boolean;
  emptyText?: string;
}> = ({
  dashboardRows,
  onChange = () => {},
  reviewCycle,
  isWidgetView,
  emptyText = 'No participants found',
}) => {
  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const selfReviewProductName = `Self ${selfReviewProduct.lowerCase}`;
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const managerReviewProductName = `Manager ${managerReviewProduct.lowerCase}`;

  const columns: ColumnsType<IReviewCycleDashboardRow> = [
    isWidgetView
      ? null
      : {
          title: 'Name',
          dataIndex: 'participant',
          key: 'participant',
          fixed: 'left',
          render: (participant: IUser) => {
            return (
              <UserMessage
                user={participant}
                style={{ whiteSpace: 'nowrap' }}
              />
            );
          },
        },
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Peers',
          key: 'peers',
          align: isWidgetView ? 'left' : 'center',
          render: (_, row) => (
            <PeerSelectionActionCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
              align={isWidgetView ? 'flex-start' : 'center'}
            />
          ),
        }
      : null,
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Feedback received',
          key: 'feedbackReceived',
          align: 'center',
          width: 180,
          render: (_, row) => (
            <PeerFeedbackReceivedCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
            />
          ),
        }
      : null,
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Feedback given',
          key: 'feedbackGiven',
          align: 'center',
          width: 150,
          render: (_, row) => (
            <PeerFeedbackGivenCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
            />
          ),
        }
      : null,
    reviewCycle.selfReflectionCycleEnabled
      ? {
          title: selfReviewProductName,
          key: 'selfReflection',
          align: 'center',
          render: (_, row) => (
            <SelfReflectionCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
            />
          ),
        }
      : null,
    reviewCycle.managerReflectionCycleEnabled
      ? {
          title: managerReviewProductName,
          key: 'managerReflection',
          align: 'center',
          render: (_, row) => (
            <ManagerReflectionCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
            />
          ),
        }
      : null,
    reviewCycle.peerReviewCycleEnabled ||
    reviewCycle.managerReflectionCycleEnabled
      ? {
          title: 'Shared',
          key: 'shared',
          align: 'center',
          render: (_, row) => (
            <ReviewsSharedCell
              row={row}
              onChange={onChange}
              reviewCycle={reviewCycle}
              isAdminView={isWidgetView}
            />
          ),
        }
      : null,
  ].filter((item) => !!item) as ColumnsType<IReviewCycleDashboardRow>;
  columns[0].align = 'left';
  return (
    <Column>
      <Table<IReviewCycleDashboardRow>
        columns={columns}
        dataSource={dashboardRows}
        pagination={false}
        locale={{
          emptyText: <Empty description={emptyText} />,
        }}
        rowKey="token"
        scroll={{
          x: true,
        }}
      />
    </Column>
  );
};
