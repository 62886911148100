import { PlusOutlined } from '@ant-design/icons';
import { IReviewCycle } from '@shared/review-cycles';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button } from 'antd';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AdminPageContent } from '../admin/AdminPageContent';
import { ReviewCyclesTable } from './ReviewCyclesTable';

const AdminReviewCyclesPage: React.FC = () => {
  const { isImpersonating } = useAuth();
  const { data: reviewCycles } = useApi<IReviewCycle[]>('/review-cycles');
  const navigate = useNavigate();

  const handleReviewCycleClick = (reviewCycle: IReviewCycle) => {
    navigate(`/admin/review-cycles/${reviewCycle.token}/dashboard`);
  };

  return (
    <AdminPageContent>
      {isImpersonating && (
        <Row gap={6}>
          <GrowingSpacer />
          <Link to="/admin/review-cycles/new">
            <Button type="primary" style={{ maxWidth: 200 }}>
              <PlusOutlined /> Add cycle
            </Button>
          </Link>
        </Row>
      )}
      <ReviewCyclesTable
        reviewCycles={reviewCycles}
        onReviewCycleClick={handleReviewCycleClick}
      />
    </AdminPageContent>
  );
};

export default AdminReviewCyclesPage;
