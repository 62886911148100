import { dateString } from '@shared/dateString';
import { Feature } from '@shared/features';
import {
  FeedbackTemplateToken,
  findTemplateByToken,
  peerFeedbackRequestTemplates,
} from '@shared/feedbackTemplates';
import {
  IReflectionQuestionSet,
  ProductName,
  ReflectionQuestionSetToken,
  findReflectionQuestionSet,
  managerReviewTemplates,
  productDetails,
  selfReviewTemplates,
} from '@shared/reflections';
import { IReviewCycle } from '@shared/review-cycles';
import { patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { SelectDate, SelectDateRange } from '@web/components/SelectDate';
import { SelectHour } from '@web/components/SelectDateHour';
import { Column, Grid, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3 } from '@web/components/typography';
import { Button, Checkbox, Divider, Input, Select, message } from 'antd';
import { addDays, format } from 'date-fns';
import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

import { BorderedPane } from './BorderedPane';

interface IReviewCycleConfig {
  name: string;
  peerReviewCycleEnabled: boolean;
  peerReviewTemplateToken: FeedbackTemplateToken;
  peerSelectionStartDate: string;
  peerSelectionEndDate: string;
  peerApprovalStartDate: string;
  peerApprovalEndDate: string;
  peerFeedbackStartDate: string;
  peerFeedbackEndDate: string;
  selfReflectionQuestionSetToken: ReflectionQuestionSetToken;
  selfReflectionCycleEnabled: boolean;
  selfReflectionProductName: ProductName;
  selfReviewStartDate: string;
  selfReviewEndDate: string;
  managerReflectionQuestionSetToken: ReflectionQuestionSetToken;
  managerReviewProductName: ProductName;
  managerReflectionCycleEnabled: boolean;
  managerReviewStartDate: string;
  managerReviewEndDate: string;
  sharedByDate?: string;
  notificationHour?: number;
}

export const EditReviewCycleForm: React.FC<{
  onSave: (savedReviewCycle: IReviewCycle) => void;
  onCancel: () => void;
  reviewCycleToken: string;
  modalButtonMode?: boolean;
}> = ({ onSave, onCancel, reviewCycleToken, modalButtonMode }) => {
  const { booleanValue: autoScheduleReviewCyclesEnabled } = useFeature(
    Feature.AUTO_SCHEDULE_REVIEW_CYCLES,
  );
  const isEdit = !!reviewCycleToken;
  const { data: reviewCycle } = useApi<IReviewCycle>(
    isEdit ? `/review-cycles/${reviewCycleToken}` : null,
  );
  const [reviewCycleConfig, setReviewCycleConfig] =
    React.useState<IReviewCycleConfig>(getDefaultConfig() as any);
  React.useEffect(() => {
    if (reviewCycle) {
      const updatedConfig: IReviewCycleConfig = {
        name: reviewCycle.name,
        peerReviewCycleEnabled: reviewCycle.peerReviewCycleEnabled,
        peerReviewTemplateToken: reviewCycle.peerReviewTemplateToken,
        peerSelectionStartDate: dateString(reviewCycle.peerSelectionStartDate),
        peerSelectionEndDate: dateString(reviewCycle.peerSelectionEndDate),
        peerApprovalStartDate: dateString(reviewCycle.peerApprovalStartDate),
        peerApprovalEndDate: dateString(reviewCycle.peerApprovalEndDate),
        peerFeedbackStartDate: dateString(reviewCycle.peerFeedbackStartDate),
        peerFeedbackEndDate: dateString(reviewCycle.peerFeedbackEndDate),
        selfReflectionQuestionSetToken:
          reviewCycle.selfReflectionQuestionSetToken,
        selfReflectionCycleEnabled: reviewCycle.selfReflectionCycleEnabled,
        selfReviewStartDate: dateString(reviewCycle.selfReviewStartDate),
        selfReviewEndDate: dateString(reviewCycle.selfReviewEndDate),
        selfReflectionProductName: reviewCycle.selfReflectionProductName,
        managerReflectionQuestionSetToken:
          reviewCycle.managerReflectionQuestionSetToken,
        managerReviewProductName: reviewCycle.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycle.managerReflectionCycleEnabled,
        managerReviewStartDate: dateString(reviewCycle.managerReviewStartDate),
        managerReviewEndDate: dateString(reviewCycle.managerReviewEndDate),
        sharedByDate: reviewCycle.sharedByDate
          ? dateString(reviewCycle.sharedByDate)
          : undefined,
        notificationHour: reviewCycle.notificationHour,
      };
      setReviewCycleConfig(updatedConfig);
    }
  }, [reviewCycle]);

  const handleSave = async () => {
    try {
      const body: IReviewCycleConfig = {
        name: reviewCycleConfig.name,
        peerReviewCycleEnabled: reviewCycleConfig.peerReviewCycleEnabled,
        peerReviewTemplateToken: reviewCycleConfig.peerReviewTemplateToken,
        peerSelectionStartDate: `${reviewCycleConfig.peerSelectionStartDate}T23:59:59.999Z`,
        peerSelectionEndDate: `${reviewCycleConfig.peerSelectionEndDate}T23:59:59.999Z`,
        peerApprovalStartDate: `${reviewCycleConfig.peerApprovalStartDate}T23:59:59.999Z`,
        peerApprovalEndDate: `${reviewCycleConfig.peerApprovalEndDate}T23:59:59.999Z`,
        peerFeedbackStartDate: `${reviewCycleConfig.peerFeedbackStartDate}T23:59:59.999Z`,
        peerFeedbackEndDate: `${reviewCycleConfig.peerFeedbackEndDate}T23:59:59.999Z`,
        selfReflectionQuestionSetToken:
          reviewCycleConfig.selfReflectionQuestionSetToken,
        selfReflectionCycleEnabled:
          reviewCycleConfig.selfReflectionCycleEnabled,
        selfReviewStartDate: `${reviewCycleConfig.selfReviewStartDate}T23:59:59.999Z`,
        selfReviewEndDate: `${reviewCycleConfig.selfReviewEndDate}T23:59:59.999Z`,
        selfReflectionProductName: reviewCycleConfig.selfReflectionProductName,
        managerReflectionQuestionSetToken:
          reviewCycleConfig.managerReflectionQuestionSetToken,
        managerReviewProductName: reviewCycleConfig.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycleConfig.managerReflectionCycleEnabled,
        managerReviewStartDate: `${reviewCycleConfig.managerReviewStartDate}T23:59:59.999Z`,
        managerReviewEndDate: `${reviewCycleConfig.managerReviewEndDate}T23:59:59.999Z`,
        sharedByDate: reviewCycleConfig.sharedByDate
          ? `${reviewCycleConfig.sharedByDate}T23:59:59.999Z`
          : null,
        notificationHour: reviewCycleConfig.notificationHour, // Explicitly pass `null` to clear out the `notificationHour`.
      };
      let savedReviewCycle: IReviewCycle;
      if (isEdit) {
        savedReviewCycle = await patch<IReviewCycleConfig, IReviewCycle>(
          `/review-cycles/${reviewCycleToken}`,
          body,
        );
      } else {
        savedReviewCycle = await post<IReviewCycleConfig, IReviewCycle>(
          '/review-cycles',
          body,
        );
      }

      void message.success('Success');
      onSave(savedReviewCycle);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDateUpdated =
    (property: keyof IReviewCycleConfig) => (date?: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [property]: date,
      });
    };
  const handleDateRangeUpdated =
    (
      startProperty: keyof IReviewCycleConfig,
      endProperty: keyof IReviewCycleConfig,
    ) =>
    (startDate: string, endDate: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [startProperty]: startDate,
        [endProperty]: endDate,
      });
    };
  const handleSetPeerReviewEnabled = (peerReviewCycleEnabled: boolean) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewCycleEnabled,
    });
  };

  const handleSetPeerReviewQuestionSet = (token: FeedbackTemplateToken) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewTemplateToken: token,
    });
  };

  const handleSetSelfReflectionCycleEnabled = (
    selfReflectionCycleEnabled: boolean,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      selfReflectionCycleEnabled,
    });
  };

  const handleSetSelfReflectionQuestionSet = (
    token: ReflectionQuestionSetToken,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      selfReflectionQuestionSetToken: token,
    });
  };
  const handleSetSelfReflectionProductName = (
    selfReflectionProductName: ProductName,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      selfReflectionProductName,
    });
  };

  const handleSetManagerReflectionQuestionSet = (
    token: ReflectionQuestionSetToken,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      managerReflectionQuestionSetToken: token,
    });
  };

  const handleSetManagerReviewProductName = (
    managerReviewProductName: ProductName,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      managerReviewProductName,
    });
  };

  const handleSetManagerReflectionCycleEnabled = (
    managerReflectionCycleEnabled: boolean,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      managerReflectionCycleEnabled,
    });
  };

  const handleNotificationHourChanged = (notificationHour: number) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      notificationHour,
    });
  };

  const canSave =
    !!reviewCycleConfig.name &&
    (!!reviewCycleConfig.notificationHour ||
      isEdit ||
      !autoScheduleReviewCyclesEnabled);

  return (
    <Column gap={24} style={{ maxWidth: 1050 }}>
      <Column gap={12} style={{ alignItems: 'flex-start' }}>
        <Column>
          <Text>Name</Text>
          <Input
            style={{ width: 350 }}
            value={reviewCycleConfig.name}
            onChange={(e) => {
              setReviewCycleConfig({
                ...reviewCycleConfig,
                name: e.target.value,
              });
            }}
          />
        </Column>
        {autoScheduleReviewCyclesEnabled &&
        (!isEdit || reviewCycle.notificationHour) ? (
          <Column>
            <Text>Notification Hour</Text>
            <SelectHour
              hour={reviewCycleConfig.notificationHour}
              onHourChanged={handleNotificationHourChanged}
            />
          </Column>
        ) : null}
        <Divider />
        <Header3>Schedule</Header3>
        <Grid columns="1fr 1fr 1fr" gap={24}>
          <BorderedPane>
            <Column gap={12}>
              <Header3>Peer Reviews</Header3>
              <Checkbox
                checked={reviewCycleConfig.peerReviewCycleEnabled}
                defaultChecked={reviewCycleConfig.peerReviewCycleEnabled}
                onChange={(event) => {
                  handleSetPeerReviewEnabled(event.target.checked);
                }}
                disabled={isEdit}
              >
                Enabled
              </Checkbox>
              <Column>
                <Text>Question List</Text>
                <SelectFeedbackTemplate
                  token={reviewCycleConfig.peerReviewTemplateToken}
                  onChange={handleSetPeerReviewQuestionSet}
                  disabled={isEdit}
                />
              </Column>
              <Column>
                <Text>Peer selection</Text>
                <SelectDateRange
                  start={reviewCycleConfig.peerSelectionStartDate}
                  end={reviewCycleConfig.peerSelectionEndDate}
                  disabled={!reviewCycleConfig.peerReviewCycleEnabled}
                  onChange={handleDateRangeUpdated(
                    'peerSelectionStartDate',
                    'peerSelectionEndDate',
                  )}
                />
              </Column>
              <Column>
                <Text>Peer approvals</Text>
                <SelectDateRange
                  start={reviewCycleConfig.peerApprovalStartDate}
                  end={reviewCycleConfig.peerApprovalEndDate}
                  disabled={!reviewCycleConfig.peerReviewCycleEnabled}
                  onChange={handleDateRangeUpdated(
                    'peerApprovalStartDate',
                    'peerApprovalEndDate',
                  )}
                />
              </Column>
              <Column>
                <Text>Peer feedback</Text>
                <SelectDateRange
                  start={reviewCycleConfig.peerFeedbackStartDate}
                  end={reviewCycleConfig.peerFeedbackEndDate}
                  disabled={!reviewCycleConfig.peerReviewCycleEnabled}
                  onChange={handleDateRangeUpdated(
                    'peerFeedbackStartDate',
                    'peerFeedbackEndDate',
                  )}
                />
              </Column>
            </Column>
          </BorderedPane>
          <BorderedPane>
            <Column gap={12}>
              <Header3>
                Self{' '}
                {pluralize(
                  productDetails(reviewCycleConfig.selfReflectionProductName)
                    .titleCase,
                )}
              </Header3>
              <Checkbox
                checked={reviewCycleConfig.selfReflectionCycleEnabled}
                defaultChecked={reviewCycleConfig.selfReflectionCycleEnabled}
                onChange={(event) => {
                  handleSetSelfReflectionCycleEnabled(event.target.checked);
                }}
                disabled={isEdit}
              >
                Enabled
              </Checkbox>
              <Column>
                <Text>Name</Text>
                <SelectReviewProductName
                  disabled={isEdit}
                  value={reviewCycleConfig.selfReflectionProductName}
                  onChange={handleSetSelfReflectionProductName}
                />
              </Column>
              <Column>
                <Text>Question List</Text>
                <SelectReflectionTemplate
                  templates={selfReviewTemplates}
                  token={reviewCycleConfig.selfReflectionQuestionSetToken}
                  onChange={handleSetSelfReflectionQuestionSet}
                  disabled={isEdit}
                />
              </Column>
              <Column>
                <Text>Schedule</Text>
                <SelectDateRange
                  start={reviewCycleConfig.selfReviewStartDate}
                  end={reviewCycleConfig.selfReviewEndDate}
                  disabled={!reviewCycleConfig.selfReflectionCycleEnabled}
                  onChange={handleDateRangeUpdated(
                    'selfReviewStartDate',
                    'selfReviewEndDate',
                  )}
                />
              </Column>
            </Column>
          </BorderedPane>
          <BorderedPane>
            <Column gap={12}>
              <Header3>
                Manager{' '}
                {pluralize(
                  productDetails(reviewCycleConfig.managerReviewProductName)
                    .titleCase,
                )}
              </Header3>
              <Checkbox
                checked={reviewCycleConfig.managerReflectionCycleEnabled}
                defaultChecked={reviewCycleConfig.managerReflectionCycleEnabled}
                onChange={(event) => {
                  handleSetManagerReflectionCycleEnabled(event.target.checked);
                }}
                disabled={isEdit}
              >
                Enabled
              </Checkbox>
              <Column>
                <Text>Name</Text>
                <SelectReviewProductName
                  disabled={isEdit}
                  value={reviewCycleConfig.managerReviewProductName}
                  onChange={handleSetManagerReviewProductName}
                />
              </Column>
              <Column>
                <Text>Question List</Text>
                <SelectReflectionTemplate
                  templates={managerReviewTemplates}
                  token={reviewCycleConfig.managerReflectionQuestionSetToken}
                  onChange={handleSetManagerReflectionQuestionSet}
                  disabled={isEdit}
                />
              </Column>
              <Column>
                <Text>Schedule</Text>
                <SelectDateRange
                  start={reviewCycleConfig.managerReviewStartDate}
                  end={reviewCycleConfig.managerReviewEndDate}
                  disabled={!reviewCycleConfig.managerReflectionCycleEnabled}
                  onChange={handleDateRangeUpdated(
                    'managerReviewStartDate',
                    'managerReviewEndDate',
                  )}
                />
              </Column>
              <Column>
                <Text>Shared By</Text>
                <SelectDate
                  value={reviewCycleConfig.sharedByDate}
                  onChange={handleDateUpdated('sharedByDate')}
                />
              </Column>
            </Column>
          </BorderedPane>
        </Grid>
      </Column>
      <PaneButtons
        style={{
          marginTop: 12,
          flexDirection: modalButtonMode ? 'row-reverse' : 'row',
        }}
      >
        <Button type="primary" disabled={!canSave} onClick={handleSave}>
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </PaneButtons>
    </Column>
  );
};

export const PaneButtons = styled(Row)`
  gap: 6px;

  .ant-btn {
    width: 120px;
  }
`;

const SelectReviewProductName: React.FC<{
  disabled?: boolean;
  value?: ProductName;
  onChange?: (value: ProductName) => void;
}> = ({ disabled, value, onChange }) => {
  const items = Object.keys(ProductName).map((key) => ({
    value: ProductName[key],
    label: productDetails(ProductName[key]).titleCase,
  }));

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const SelectFeedbackTemplate: React.FC<{
  token?: FeedbackTemplateToken;
  disabled?: boolean;
  onChange?: (value: FeedbackTemplateToken) => void;
}> = ({ token, disabled, onChange }) => {
  const template = findTemplateByToken(token);
  const items = peerFeedbackRequestTemplates.map((template) => ({
    value: template.token,
    label: template.name,
  }));

  return (
    <Select
      value={template?.token}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const SelectReflectionTemplate: React.FC<{
  templates: IReflectionQuestionSet[];
  token?: ReflectionQuestionSetToken;
  disabled?: boolean;
  onChange?: (value: ReflectionQuestionSetToken) => void;
}> = ({ templates, token, disabled, onChange }) => {
  const questionSet = findReflectionQuestionSet(token);
  const items = templates.map((template) => ({
    value: template.token,
    label: template.name,
  }));

  return (
    <Select
      value={questionSet?.token}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const getDefaultConfig = (): Omit<IReviewCycleConfig, 'name'> => {
  return {
    peerReviewCycleEnabled: true,
    peerReviewTemplateToken: 'frt_peer_factoryfix',
    peerSelectionStartDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    peerSelectionEndDate: format(addDays(new Date(), 8), 'yyyy-MM-dd'),
    peerApprovalStartDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    peerApprovalEndDate: format(addDays(new Date(), 9), 'yyyy-MM-dd'),
    peerFeedbackStartDate: format(addDays(new Date(), 10), 'yyyy-MM-dd'),
    peerFeedbackEndDate: format(addDays(new Date(), 12), 'yyyy-MM-dd'),
    selfReflectionQuestionSetToken: 'rqs_self',
    selfReflectionCycleEnabled: true,
    selfReviewStartDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    selfReviewEndDate: format(addDays(new Date(), 9), 'yyyy-MM-dd'),
    selfReflectionProductName: ProductName.REVIEW,
    managerReflectionQuestionSetToken: 'rqs_manager',
    managerReviewProductName: ProductName.REVIEW,
    managerReflectionCycleEnabled: true,
    managerReviewStartDate: format(addDays(new Date(), 13), 'yyyy-MM-dd'),
    managerReviewEndDate: format(addDays(new Date(), 17), 'yyyy-MM-dd'),
  };
};
