import * as React from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import useLayoutEffect from '@react-hook/passive-layout-effect';
/**
 * A React hook for measuring the size of HTML elements including when they change
 *
 * @param target A React ref created by `useRef()` or an HTML element
 * @param options Configures the initial width and initial height of the hook's state
 */

const useSize = (target, options) => {
  const [size, setSize] = React.useState(() => {
    var _options$initialWidth, _options$initialHeigh;

    const targetEl = target && 'current' in target ? target.current : target;
    return targetEl ? [targetEl.offsetWidth, targetEl.offsetHeight] : [(_options$initialWidth = options === null || options === void 0 ? void 0 : options.initialWidth) !== null && _options$initialWidth !== void 0 ? _options$initialWidth : 0, (_options$initialHeigh = options === null || options === void 0 ? void 0 : options.initialHeight) !== null && _options$initialHeigh !== void 0 ? _options$initialHeigh : 0];
  });
  useLayoutEffect(() => {
    const targetEl = target && 'current' in target ? target.current : target;
    if (!targetEl) return;
    setSize([targetEl.offsetWidth, targetEl.offsetHeight]);
  }, [target]); // Where the magic happens

  useResizeObserver(target, entry => {
    const target = entry.target;
    setSize([target.offsetWidth, target.offsetHeight]);
  });
  return size;
};

export default useSize;