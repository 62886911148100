import { findTemplateByToken } from '@shared/feedbackTemplates';
import { QuestionToken } from '@shared/questions';
import {
  FeedbackType,
  IDraftResponse,
  IFeedbackRequest,
  IFeedbackRequestParametersResponse,
  ITask,
  IUser,
  UserMapItem,
} from '@shared/types';
import { put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { message } from 'antd';
import React from 'react';

import {
  RequestedFeedbackFields,
  TemplatedFeedbackForm,
} from './TemplatedFeedbackForm';

export interface GiveTemplatedFeedbackFormProps {
  user: IUser;
  receiver?: UserMapItem;
  task: ITask;
  feedbackRequest: IFeedbackRequest;
  onSuccess?: () => void;
  onCancel?: () => void;
}
export const GiveTemplatedFeedbackForm: React.FC<
  GiveTemplatedFeedbackFormProps
> = ({ user, receiver, task, feedbackRequest, onCancel, onSuccess }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: draftResponses, mutate: invalidateDraftResponses } = useApi<
    IDraftResponse[]
  >(`/feedback_requests/${feedbackRequest.token}/draft_responses`);
  const { data: response } = useApi<IFeedbackRequestParametersResponse>(
    `/feedback_requests/${feedbackRequest.token}/parameters`,
  );
  if (!feedbackRequest || !response || !draftResponses) {
    return null;
  }
  const isSelfRequested =
    feedbackRequest.receiverToken === feedbackRequest.requesterToken;
  const responses = feedbackRequest.feedback?.responses ?? [];
  const isViewing =
    user.token !== feedbackRequest.giverToken ||
    (task.completedDate !== null && responses.length !== 0);

  const initialValues: RequestedFeedbackFields = { feedbackResponses: {} };
  if (isViewing) {
    for (const response of responses) {
      if (response.questionToken) {
        initialValues.feedbackResponses[response.questionToken] = response.text;
      }
    }
  } else {
    for (const draftResponse of draftResponses) {
      if (draftResponse.questionToken) {
        initialValues.feedbackResponses[draftResponse.questionToken] =
          draftResponse.text;
      }
    }
  }

  const template = findTemplateByToken(feedbackRequest.questionSetToken);
  if (!template) {
    return null;
  }

  const handleSubmit = async (fields: RequestedFeedbackFields) => {
    setIsSaving(true);

    try {
      const responses = Object.keys(fields.feedbackResponses)
        .map((questionToken) => {
          return fields.feedbackResponses[questionToken]
            ? {
                questionToken: questionToken as QuestionToken,
                text: fields.feedbackResponses[questionToken],
                visibility: feedbackRequest.visibility,
              }
            : null;
        })
        .filter((response) => !!response);
      await put(`/feedback/template/${feedbackRequest.token}`, responses);
      void message.success('Success');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleAutosave = async (questionToken: QuestionToken, text: string) => {
    await put<IDraftResponse>(
      `/feedback_requests/${feedbackRequest.token}/draft_responses`,
      { questionToken, text },
    );
    void invalidateDraftResponses();
  };

  return (
    <TemplatedFeedbackForm
      receiver={receiver}
      initialValues={initialValues}
      template={template}
      parameters={response.templateParameters}
      disabled={isSaving || isViewing}
      isViewing={isViewing}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isSelfRequested={isSelfRequested}
      isReviewCycleRequest={!!feedbackRequest.reviewCycleToken}
      hideHeader={feedbackRequest.type === FeedbackType.DIMENSION}
      onResponseChange={handleAutosave}
    />
  );
};
