const APOSTROPHE_CHAR = '’';

export const possessive = (str: string) => {
  if (str == '') {
    return str;
  }

  const lastChar = str.slice(-1);
  const endOfWord =
    lastChar.toLowerCase() == 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${str}${endOfWord}`;
};
