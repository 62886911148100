import { DeleteOutlined } from '@ant-design/icons';
import { updateSurveyName } from '@client/SurveyCyclesClient';
import { formatDate } from '@shared/formatDate';
import { ISurveyCycle } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { del, post, put } from '@web/common/api';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { BackLink } from '@web/components/BackButton';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { BorderedPane } from '@web/review-cycles/BorderedPane';
import { Button, Tooltip, message } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminSurveyTabs } from './AdminSurveyTabs';
import { InlineInputTitle } from './InlineInputTitle';

export const AdminSurveyContent: React.FC<{
  surveyCycle: ISurveyCycle;
  onChange?: () => void;
  children: React.ReactNode;
}> = ({ surveyCycle, children, onChange }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const navigate = useNavigate();

  const handleStartSurvey = async () => {
    const userConfirmed = await confirm(
      'This will send notifications to all participants. Are you sure you want to start this survey?',
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await put(`/survey-cycles/${surveyCycle.token}/start`);
      onChange?.();
      void message.success('Survey started');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleEndSurvey = async () => {
    const userConfirmed = await confirm(
      'No new survey submissions will be allowed once the survey has ended. Are you sure you want to end this survey?',
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await put(`/survey-cycles/${surveyCycle.token}/end`);
      onChange?.();
      void message.success('Survey ended');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleRemoveSurvey = async () => {
    const userConfirmed = await confirm(
      'Are you sure you want to remove this survey?',
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await del(`/survey-cycles/${surveyCycle.token}`);
      onChange?.();
      void message.success('Survey removed');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleRunAgain = async () => {
    const userConfirmed = await confirm(
      `This will duplicate the survey and all of its configuration, questions, and participants.`,
    );
    if (!userConfirmed) {
      return;
    }
    try {
      const newSurveyCycle = await post<ISurveyCycle>(
        `/survey-cycles/${surveyCycle.token}/rerun`,
      );
      navigate(`/admin/surveys/${newSurveyCycle.token}/settings`);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleNameSave = async (newName: string) => {
    await updateSurveyName(surveyCycle.token, newName);
  };

  let surveyStartBlockerReason: string | undefined;
  if (surveyCycle.questions.length === 0) {
    surveyStartBlockerReason = 'Survey has no questions';
  } else if (surveyCycle.participants.length === 0) {
    surveyStartBlockerReason = 'Survey has no participants';
  }

  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink to={`/admin/surveys`} />
        </Row>
        <Row gap={6}>
          <InlineInputTitle value={surveyCycle?.name} onSave={handleNameSave} />
          <GrowingSpacer />
          {surveyCycle.endedDate ? (
            <Button type="primary" onClick={handleRunAgain}>
              Run Survey Again
            </Button>
          ) : surveyCycle.startedDate ? (
            <Button type="primary" onClick={handleEndSurvey}>
              End Survey
            </Button>
          ) : surveyStartBlockerReason !== undefined ? (
            <Tooltip title={surveyStartBlockerReason}>
              <Button type="primary" disabled>
                Start Survey
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={handleStartSurvey}>
              Start Survey
            </Button>
          )}
          {!surveyCycle.startedDate && (
            <Button onClick={handleRemoveSurvey}>
              <DeleteOutlined />
            </Button>
          )}
        </Row>
      </Column>
      {surveyCycle.endedDate ? (
        <Text>
          <strong>Status:</strong> Survey ended on{' '}
          {formatDate(surveyCycle.endedDate)}
        </Text>
      ) : surveyCycle.startedDate ? (
        <Text>
          <strong>Status:</strong> Survey started on{' '}
          {formatDate(surveyCycle.startedDate)}
        </Text>
      ) : (
        <Text>
          <strong>Status:</strong> Survey not started
        </Text>
      )}
      <Column>
        <AdminSurveyTabs surveyCycle={surveyCycle} />
        <BorderedPane style={{ borderTopLeftRadius: 0 }}>
          {children}
        </BorderedPane>
      </Column>
      {contextHolder}
    </AdminPageContent>
  );
};
