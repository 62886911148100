import { IUser, UserMap, UserMapItem, UserTokenNameMap } from '@shared/types';

export const createUserTokenNameMap = (users: IUser[]): UserTokenNameMap => {
  const names: UserTokenNameMap = {};
  for (const user of users) {
    if (!user) {
      continue;
    }

    names[user.token] = user.name;
  }
  return names;
};

export const createUserMap = (users: IUser[]): UserMap => {
  const userMap: UserMap = {};
  for (const user of users) {
    if (!user) {
      continue;
    }

    userMap[user.token] = userMapItem(user);
  }
  return userMap;
};

export const populateUserMap = (users: IUser[], existingUserMap: UserMap) => {
  for (const user of users) {
    if (!user) {
      continue;
    }

    existingUserMap[user.token] = userMapItem(user);
  }
};

export const userMapItem = (user: IUser): UserMapItem => ({
  name: user.name,
  email: user.email,
  slackId: user.slackId,
  token: user.token,
  managerTokens: user.managerTokens,
  githubLogin: user.githubLogin,
  slackImageUrl: user.slackImageUrl,
  organizationToken: user.organizationToken,
  onboardingCompletedDate: user.onboardingCompletedDate,
  deletedDate: user.deletedDate,
  role: user.role,
});
