import { IFeedbackTemplate } from '@shared/feedbackTemplates';
import { IFeedback } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Spacer } from '@web/components/layout';
import { Skeleton, Tag } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { EditSelfFeedback } from './EditSelfFeedback';

interface IDraftSelfFeedbackResponse {
  draft: IFeedback;
  template: IFeedbackTemplate;
}

export const SelfFeedbackPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { data, mutate: reloadDraft } =
    useApi<IDraftSelfFeedbackResponse>(`/feedback/self/draft`);

  const handleSave = () => {
    void reloadDraft();
  };

  const handleShare = () => {
    void reloadDraft();
    navigate('/feedback');
  };

  return (
    <PageContent>
      <PageHeader title="Draft Self Feedback" navigateBack />
      <Spacer />
      {data ? (
        <Pane>
          <UserMessage
            user={user}
            tag={data.draft.isDraft ? <Tag>Draft</Tag> : undefined}
            large
          >
            <Spacer size={12} />
            <EditSelfFeedback
              onSaveDraft={handleSave}
              onShare={handleShare}
              draft={data.draft}
              template={data.template}
            />
          </UserMessage>
        </Pane>
      ) : (
        <Skeleton />
      )}
      {isMobile && <Spacer />}
    </PageContent>
  );
};
