/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { QuestionToken } from './questions';

export type RatingScale = Rating[];

export interface Rating {
  label: string;
  description?: string;
  value: number;
}

export const createScale = (labels: string[]): RatingScale => {
  return labels.map((label, index) => ({
    label,
    value: index + 1,
  }));
};

export const createExtremesScale = (adjective: string) =>
  createScale([
    `Not Very ${adjective}`,
    `Somewhat ${adjective}`,
    `Moderately ${adjective}`,
    `Very ${adjective}`,
    `Extremely ${adjective}`,
  ]);

export const RatingScales: Record<string, RatingScale> = {
  Agreement1: createScale([
    'Strongly Disagree',
    'Disagree',
    'Neutral',
    'Agree',
    'Strongly Agree',
  ]),
  Frequency1: createScale([
    'Rarely',
    'Sometimes',
    'Often',
    'Very Often',
    'Always',
  ]),
  Transparency1: createExtremesScale('Transparent'),
  Effective1: createExtremesScale('Effective'),
  Effectively1: createExtremesScale('Effectively'),
  Receptive1: createExtremesScale('Receptive'),
  Impactful1: createExtremesScale('Impactful'),
  Valuable1: createExtremesScale('Valuable'),
  Satisfied1: createExtremesScale('Satisfied'),
  TimeFrequency1: createScale([
    'Once a week or more',
    'A few times a month',
    'About once a month',
    'About once a quarter',
    'Less than once a quarter',
  ]),
  OneOnOneFrequency1: createScale([
    'Weekly',
    'Every other week',
    'Monthly',
    'Once or twice a quarter',
    'I rarely meet with my manager',
  ]),
  TimeFrequency2: createScale([
    'Once a week or more',
    'A few times a month',
    'About once a month',
    'About once a quarter',
    'Less than once a quarter',
  ]),
  OneOnOneFrequency2: createScale([
    'Weekly',
    'Every other week',
    'Monthly',
    'Less than monthly',
    'We don’t do 1-on-1s',
  ]),
};

export type RatingTrendItem = {
  key: string;
  date: string | Date;
  rating: number | null;
};
export type RatingTrend = RatingTrendItem[];
export type RatingTrendMap = Record<QuestionToken, RatingTrend>;
