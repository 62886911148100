import {
  CopyOutlined,
  DeleteOutlined,
  HolderOutlined,
} from '@ant-design/icons';
import { QuestionType, QuestionTypeLabels } from '@shared/QuestionType';
import { QuestionToken } from '@shared/questions';
import { errorColor } from '@web/app/styles/ColorStyles';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3, Small, Text } from '@web/components/typography';
import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export interface ISortableQuestion {
  id: QuestionToken;
  text: string;
  type: QuestionType;
  required: boolean;
  position: number;
}

export const QuestionItemRow: React.FC<{
  questionItem: ISortableQuestion;
  onClick?: () => void;
  onClone?: () => void;
  onDelete?: () => void;
  position: number;
}> = ({ questionItem, onClick, onClone, onDelete, position }) => {
  return (
    <SortableRow
      style={{
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => {
        onClick?.();
      }}
    >
      <ItemDragAnchor>
        <DragAnchor className="drag-anchor" />
      </ItemDragAnchor>
      <Spacer size={12} />
      <ItemPosition>{position}</ItemPosition>
      <Spacer size={12} />
      <Tag style={{ width: 90, textAlign: 'center' }}>
        {QuestionTypeLabels[questionItem.type]}
      </Tag>
      <Spacer size={6} />
      <ItemText>{questionItem.text}</ItemText>
      <Spacer size={6} />
      {questionItem.required && (
        <Tooltip title="Required">
          <ItemRequired>*</ItemRequired>
        </Tooltip>
      )}
      <GrowingSpacer />
      {onClone && (
        <StopPropagation>
          <Popconfirm
            title="Do you want to clone this question?"
            onConfirm={() => {
              onClone?.();
            }}
          >
            <Button size="small" type="text">
              <CopyOutlined />
            </Button>
          </Popconfirm>
        </StopPropagation>
      )}
      {onDelete && (
        <StopPropagation>
          <Popconfirm
            title="Do you want to delete this question?"
            onConfirm={() => {
              onDelete?.();
            }}
          >
            <Button size="small" type="text">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </StopPropagation>
      )}
    </SortableRow>
  );
};
const ItemPosition = styled(Small)`
  height: 20px;
  width: 20px;
  background: #888;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin: 2px 0;
`;
const ItemRequired = styled(Header3)`
  line-height: 24px;
  color: ${errorColor.string()};
`;
const ItemText = styled(Text)`
  line-height: 24px;
`;
const ItemDragAnchor = styled(Column)`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DragAnchor = styled(HolderOutlined)`
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
const SortableRow = styled(Row)`
  align-items: flex-start;
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    ${DragAnchor} {
      cursor: grab;

      &:hover {
        color: #333;
      }
    }
  }
`;
