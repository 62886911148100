import { IChatCompletion } from '@shared/completions';
import { IImpact } from '@shared/impacts';
import {
  ICreatePerformanceSummary,
  ICreatePerformanceSummaryResponse,
  IPerformanceSummary,
  PerformanceSummaryToken,
} from '@shared/performance';
import { OrganizationToken, UserToken } from '@shared/types';
import { get, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function usePerformanceEntries(
  userToken: UserToken,
  startDate: string,
  endDate: string,
) {
  const url = stringifyUrl({
    url: `/performance/${userToken}/entries`,
    query: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  return useApi<IImpact[]>(url);
}

export function performanceEntries(
  userToken: UserToken,
  startDate: string,
  endDate: string,
) {
  const url = stringifyUrl({
    url: `/performance/${userToken}/entries`,
    query: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  return get<IImpact[]>(url);
}

export function generatePerformanceSummary(
  userToken: UserToken,
  prompt: string,
  entriesJson: string,
) {
  return post<
    {
      prompt: string;
      entriesJson: string;
    },
    IChatCompletion
  >(`/performance/${userToken}/summaries/response`, {
    prompt: prompt,
    entriesJson: entriesJson,
  });
}

export function createPerformanceSummary(
  summary: ICreatePerformanceSummary,
  responses: ICreatePerformanceSummaryResponse[],
) {
  return post<
    {
      summary: ICreatePerformanceSummary;
      responses: ICreatePerformanceSummaryResponse[];
    },
    IPerformanceSummary
  >(`/performance/summaries`, {
    summary: summary,
    responses: responses,
  });
}

export function useSummaries(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/performance/summaries`,
    query: {
      organization: organizationToken,
    },
  });

  return useApi<IPerformanceSummary[]>(url);
}

export function summaries(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/performance/summaries`,
    query: {
      organization: organizationToken,
    },
  });

  return get<IPerformanceSummary[]>(url);
}

export function useSummary(performanceSummaryToken: PerformanceSummaryToken) {
  const url = stringifyUrl({
    url: `/performance/summaries/${performanceSummaryToken}`,
    query: {},
  });

  return useApi<IPerformanceSummary>(url);
}

export function summary(performanceSummaryToken: PerformanceSummaryToken) {
  const url = stringifyUrl({
    url: `/performance/summaries/${performanceSummaryToken}`,
    query: {},
  });

  return get<IPerformanceSummary>(url);
}
