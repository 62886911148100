import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { useGetProfile } from '@client/UsersClient';
import { Feature } from '@shared/features';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { OneOnOneAlignment } from '@web/1on1s/OneOnOneAlignment';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { BackButton } from '@web/components/BackButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Spacer, SplitLayout } from '@web/components/layout';
import { Button, Divider, Skeleton, Tooltip } from 'antd';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { FeedbackWidget } from './FeedbackWidget';
import { GoalsWidget } from './GoalsWidget';
import { JournalWidget } from './JournalWidget';
import { RequestsWidget } from './RequestsWidget';
import { ReviewCycleWidget } from './ReviewCycleWidget';
import { TeamWidget } from './TeamWidget';
import { UserProfile } from './UserProfile';

export const HomePage: React.FC = () => {
  const { user: currentUser } = useAuth();
  const { userToken } = useParams<{ userToken?: UserToken }>();
  const { data: profile } = useGetProfile(userToken ?? currentUser.token);
  const { isMobile } = useResponsive();
  const { booleanValue: reviewCyclesEnabled } = useFeature(
    Feature.REVIEW_CYCLES,
  );
  const { booleanValue: goalsEnabled } = useFeature(Feature.GOALS);
  const { booleanValue: journalEnabled } = useFeature(Feature.JOURNAL);
  const { booleanValue: alignmentEnabled } = useFeature(
    Feature.ENABLE_ALIGNMENT_INDICATORS,
  );

  if (!profile) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const { managesPeople, user, organizationMembers } = profile;
  const reports = organizationMembers.filter((member) =>
    isManagerOf(member, user.token),
  );
  const goalsWidget = (
    <GoalsWidget
      user={user}
      extra={
        <Tooltip title="Add Goal">
          <Link to={`/goals/new`}>
            <Button size="small">
              <PlusOutlined style={{ position: 'relative', top: -1 }} />
            </Button>
          </Link>
        </Tooltip>
      }
    />
  );
  const journalWidget = (
    <JournalWidget
      user={user}
      extra={
        <Tooltip title="Add a Journal Entry">
          <Link to={`/journal/new`}>
            <Button size="small">
              <PlusOutlined style={{ position: 'relative', top: -1 }} />
            </Button>
          </Link>
        </Tooltip>
      }
    />
  );
  const feedbackReceived = (
    <FeedbackWidget
      user={user}
      placeholder="No feedback received yet"
      extra={
        <Tooltip title="Request Feedback">
          <Link to={`/feedback/request`}>
            <Button size="small">
              <InboxOutlined style={{ position: 'relative', top: -1 }} />
            </Button>
          </Link>
        </Tooltip>
      }
    />
  );
  const requestsWidget = <RequestsWidget user={user} />;

  const alignmentWidget = <OneOnOneAlignment userToken={user.token} />;

  if (isMobile) {
    return (
      <PageContent>
        <PageHeader mobileTitle="Home" />
        <Column>
          <UserProfile user={user} />
          {managesPeople && (
            <>
              <Divider />
              <TeamWidget reports={reports} managerToken={user.token} />
            </>
          )}
          <Divider />
          {requestsWidget}
          {journalEnabled && (
            <>
              <Divider />
              {journalWidget}
            </>
          )}
          <Divider />
          {feedbackReceived}
          {goalsEnabled && <Divider />}
          {goalsEnabled && goalsWidget}
          {alignmentEnabled && <Divider />}
          {alignmentEnabled && alignmentWidget}
        </Column>
        <Spacer size={48} />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <BackButton />
      <Spacer size={12} />
      <UserProfile user={user} />
      <Spacer size={32} />
      <Pane style={{ minWidth: 0, padding: '24px 32px' }}>
        <SplitLayout>
          <Column gap={32} style={{ paddingBottom: 24 }}>
            {requestsWidget}
            {journalEnabled && journalWidget}
            {feedbackReceived}
          </Column>
          <Column
            gap={24}
            style={{
              padding: '24px 0 24px 32px',
              borderLeft: '1px solid var(--border-color)',
              marginTop: -24,
            }}
          >
            {managesPeople && (
              <TeamWidget reports={reports} managerToken={user.token} />
            )}
            {goalsEnabled && goalsWidget}
            {alignmentEnabled && <OneOnOneAlignment userToken={user.token} />}
          </Column>
        </SplitLayout>
        {reviewCyclesEnabled && <ReviewCycleWidget user={user} />}
      </Pane>
    </PageContent>
  );
};
