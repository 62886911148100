import {
  ActionItemToken,
  AgendaItemToken,
  OneOnOneToken,
} from '@shared/one-on-one';
import { UserToken } from '@shared/types';
import {
  IWebSocketEvent,
  WebSocketEventEntityToken,
  WebSocketEventType,
} from '@shared/webSocketEvents';
import { throttle } from 'lodash';
import PubSub from 'pubsub-js';
import { useEffect } from 'react';
import { io } from 'socket.io-client';

const toPubSubEvent = (event: IWebSocketEvent) =>
  `${event.entityToken}.${event.type}`;

const apiRoot = process.env.API_ROOT ?? '';
const socket = io(`${apiRoot}/websocket-events`, { transports: ['websocket'] });
socket.onAny((_type: WebSocketEventType, event: IWebSocketEvent) => {
  PubSub.publish(toPubSubEvent(event), event);
});

const subscribeEntity = (entityToken: WebSocketEventEntityToken) => {
  if (socket.connected) {
    socket.emit('subscribe', entityToken);
  } else {
    socket.on('connect', () => {
      socket.emit('subscribe', entityToken);
    });
  }
};
const unsubscribeEntity = (entityToken: WebSocketEventEntityToken) => {
  if (socket.connected) {
    socket.emit('unsubscribe', entityToken);
  }
};

export const publishEvent = (event: IWebSocketEvent) => {
  if (socket.connected) {
    socket.emit('publish', event);
  } else {
    socket.on('connect', () => {
      socket.emit('publish', event);
    });
  }
};

export const publishTypingEvent = throttle(
  (
    oneOnOneToken: OneOnOneToken,
    itemToken: AgendaItemToken | ActionItemToken,
    userToken: UserToken,
  ) =>
    publishEvent({
      type: WebSocketEventType.TYPING,
      entityToken: oneOnOneToken,
      payload: { itemToken, userToken },
    }),
  500,
);

export const publishPresenceEvent = (
  oneOnOneToken: OneOnOneToken,
  entityToken: AgendaItemToken | ActionItemToken | null,
  userToken: UserToken,
) =>
  publishEvent({
    type: WebSocketEventType.PRESENCE,
    entityToken: oneOnOneToken,
    payload: { entityToken, userToken },
  });

export const useWebSocketEvent = (
  entityToken: WebSocketEventEntityToken,
  type: WebSocketEventType,
  callback: (event: IWebSocketEvent) => unknown,
) => {
  useEffect(() => {
    const handleCallback = (_message: string, event: IWebSocketEvent) => {
      callback(event);
    };
    PubSub.subscribe(toPubSubEvent({ entityToken, type }), handleCallback);

    return () => {
      PubSub.unsubscribe(handleCallback);
    };
  }, [callback]);
};
export const useWebSocketEntity = (entityToken?: WebSocketEventEntityToken) => {
  useEffect(() => {
    if (!entityToken) {
      return;
    }

    subscribeEntity(entityToken);

    return () => {
      unsubscribeEntity(entityToken);
    };
  }, [entityToken]);
};
