import { GetManagersResponse } from '@shared/teams';
import {
  ExistingTeamResolution,
  ITeam,
  TeamToken,
  UserMapItem,
  UserToken,
} from '@shared/types';
import { del, get, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useTeamPickerManagers() {
  const url = stringifyUrl({
    url: `/teams/picker-managers`,
    query: {},
  });

  return useApi<UserMapItem[]>(url);
}

export function teamPickerManagers() {
  const url = stringifyUrl({
    url: `/teams/picker-managers`,
    query: {},
  });

  return get<UserMapItem[]>(url);
}

export function useTeams() {
  const url = stringifyUrl({
    url: `/teams`,
    query: {},
  });

  return useApi<ITeam[]>(url);
}

export function teams() {
  const url = stringifyUrl({
    url: `/teams`,
    query: {},
  });

  return get<ITeam[]>(url);
}

export function createTeam(
  managerToken: UserToken,
  hrbpToken: UserToken,
  memberTokens: UserToken[],
  name?: string,
) {
  return post<{
    managerToken: `u_${string}`;
    hrbpToken: `u_${string}`;
    memberTokens: `u_${string}`[];
    name: string;
  }>(`/teams`, {
    managerToken: managerToken,
    hrbpToken: hrbpToken,
    memberTokens: memberTokens,
    name: name,
  });
}

export function useGetManagers() {
  const url = stringifyUrl({
    url: `/teams/managers`,
    query: {},
  });

  return useApi<GetManagersResponse>(url);
}

export function getManagers() {
  const url = stringifyUrl({
    url: `/teams/managers`,
    query: {},
  });

  return get<GetManagersResponse>(url);
}

export function updateTeam(
  teamToken: TeamToken,
  managerToken: UserToken,
  hrbpToken: UserToken,
  memberTokens: UserToken[],
  existingTeamResolutions: Record<UserToken, ExistingTeamResolution>,
  name: string,
) {
  return patch<{
    managerToken: `u_${string}`;
    hrbpToken: `u_${string}`;
    memberTokens: `u_${string}`[];
    existingTeamResolutions: Record<`u_${string}`, ExistingTeamResolution>;
    name: string;
  }>(`/teams/${teamToken}`, {
    managerToken: managerToken,
    hrbpToken: hrbpToken,
    memberTokens: memberTokens,
    existingTeamResolutions: existingTeamResolutions,
    name: name,
  });
}

export function deleteTeam(teamToken: TeamToken) {
  return del(`/teams/${teamToken}`);
}
