import { IFeedbackTemplate } from '@shared/feedbackTemplates';
import { IQuestionResponse } from '@shared/question_response';
import { IFeedback, IUser } from '@shared/types';
import { IF_MOBILE } from '@web/app/responsive';
import { post, put } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { Column } from '@web/components/layout';
import { useQuestionResponseMap } from '@web/questions/useQuestionResponseMap';
import { Button, message } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { InputQuestions } from '../../questions/InputQuestions';

export const EditManagerFeedback: React.FC<{
  draft: IFeedback;
  template: IFeedbackTemplate;
  receiver: IUser;
  onSaveDraft: () => void;
  onShare: () => void;
}> = ({ receiver, onSaveDraft, onShare, draft, template }) => {
  const { responseMap, handleQuestionResponse } = useQuestionResponseMap(
    template.feedbackQuestions,
    draft.responses,
  );
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSaveDraft = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const responses = Array.from(responseMap.values());
    try {
      if (responses.length > 0) {
        await put<{ responses: IQuestionResponse[] }>(
          `/feedback/${receiver.token}/manager/draft`,
          { responses },
        );
        onSaveDraft();
        void message.success('Draft saved');
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleShare = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const responses = Array.from(responseMap.values());
    try {
      if (responses.length > 0) {
        await post<{ responses: IQuestionResponse[] }>(
          `/feedback/${receiver.token}/manager/share`,
          {
            responses,
          },
        );
        onShare();
        void message.success('Feedback has been shared');
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Column gap={24} style={{ maxWidth: 800 }}>
      <InputQuestions
        sortedQuestions={template.feedbackQuestions}
        responseMap={responseMap}
        receiver={receiver}
        onResponseChanged={handleQuestionResponse}
      />
      <FormButtons>
        <ConfirmButton
          type="primary"
          onConfirm={() => {
            void handleShare();
          }}
          title="Share feedback"
          description={`Do you wish to share this feedback with ${receiver.name}? This cannot be undone.`}
          okText="Continue"
        >
          Share feedback
        </ConfirmButton>
        <Button
          onClick={() => {
            void handleSaveDraft();
          }}
        >
          Save draft
        </Button>
      </FormButtons>
    </Column>
  );
};

const FormButtons = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    max-width: 100vw;
  }
`;
