import { CaretDownOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { hasManager } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { GrowingSpacer } from '@web/components/layout';
import {
  Text,
  TypographyColors,
  TypographyCss,
} from '@web/components/typography';
import { Dropdown, MenuProps } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarActionButton: React.FC = () => {
  const { user, organization } = useAuth();

  const { booleanValue: upwardFeedbackEnabled } = useFeature(
    Feature.UPWARD_FEEDBACK_ENABLED,
  );
  const { booleanValue: journalEnabled } = useFeature(Feature.JOURNAL);
  const actionItems: MenuProps['items'] = [
    journalEnabled
      ? {
          label: (
            <SidebarActionLink to="/journal/new">
              Create a journal entry
            </SidebarActionLink>
          ),
          key: 'create-entry',
        }
      : null,
    journalEnabled
      ? {
          label: (
            <SidebarActionLink to="/journal/suggest">
              Suggest a journal entry
            </SidebarActionLink>
          ),
          key: 'suggest-entry',
        }
      : null,
    {
      label: (
        <SidebarActionLink to="/feedback/give">Give feedback</SidebarActionLink>
      ),
      key: 'give-feedback',
    },
    {
      label: (
        <SidebarActionLink to="/feedback/request">
          Request feedback
        </SidebarActionLink>
      ),
      key: 'request-feedback',
    },
    upwardFeedbackEnabled && hasManager(user)
      ? {
          label: (
            <SidebarActionLink to="/feedback/upward">
              Give upward feedback
            </SidebarActionLink>
          ),
          key: 'give-upward-feedback',
        }
      : null,
    organization.feedbackConfig?.selfFeedbackEnabled
      ? {
          label: (
            <SidebarActionLink to="/feedback/self/draft">
              Draft self feedback
            </SidebarActionLink>
          ),
          key: 'draft-self-feedback',
        }
      : null,
  ];
  return (
    <Dropdown
      arrow
      placement="top"
      menu={{ items: actionItems }}
      trigger={['click']}
    >
      <SidebarActionButtonContainer>
        <Text>Select an action</Text>
        <GrowingSpacer />
        <CaretDownOutlined style={{ position: 'relative' }} />
      </SidebarActionButtonContainer>
    </Dropdown>
  );
};
const SidebarActionLink = styled(Link)`
  ${TypographyCss.Text}
  line-height: 32px;
  color: ${TypographyColors.text}!important;
`;
const SidebarActionButtonContainer = styled.button`
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
  height: 34px;
  appearance: none;
  outline: 0;
  border: 1px solid #e5e5e5;
  border-radius: 17px;
  color: #666;
  cursor: pointer;
`;
