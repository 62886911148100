import { IQuestionResponse } from '@shared/question_response';
import { IQuestion } from '@shared/questions';
import { FeedbackVisibility, UserMapItem } from '@shared/types';
import { SelectVisibility } from '@web/components/SelectVisibility';
import { Column, Spacer } from '@web/components/layout';
import { Header3, Text, TypographyColors } from '@web/components/typography';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

export const InputTextQuestion: React.FC<{
  autoFocus?: boolean;
  receiver?: UserMapItem;
  question: IQuestion;
  response: IQuestionResponse;
  onResponseChanged: (questionResponse: IQuestionResponse) => void;
  showVisibilityOptions?: boolean;
  disabled?: boolean;
  error?: string;
}> = ({
  question,
  response,
  onResponseChanged,
  receiver,
  autoFocus,
  showVisibilityOptions,
  disabled,
  error,
}) => {
  const handleVisibilityChanged = (visibility: FeedbackVisibility) => {
    onResponseChanged({
      ...response,
      visibility,
    });
  };
  const handleTextChanged = (text: string) => {
    onResponseChanged({
      ...response,
      text,
    });
  };
  return (
    <Column>
      <Header3 style={{ whiteSpace: 'pre-wrap' }}>{question.text}</Header3>
      <Spacer size={10} />
      <TextArea
        value={response.text}
        onChange={(e) => {
          handleTextChanged(e.currentTarget.value);
        }}
        autoFocus={autoFocus}
        style={{ minHeight: 80 }}
        disabled={disabled}
      />
      {showVisibilityOptions && (
        <SelectVisibility
          userName={receiver?.name}
          value={response.visibility}
          onChange={handleVisibilityChanged}
          style={{ alignSelf: 'flex-start' }}
        />
      )}
      {error && <Text style={{ color: TypographyColors.error }}>{error}</Text>}
    </Column>
  );
};
