import { GoalToken } from '@shared/goals';
import {
  GetImpactFeedbackResponse,
  IImpact,
  IImpactTasksResponse,
  ISearchImpactsResults,
  ISuggestedEntry,
  ImpactToken,
  SuggestedEntryToken,
} from '@shared/impacts';
import { QuestionResponseToken } from '@shared/question_response';
import { QuestionToken } from '@shared/questions';
import {
  FeedbackVisibility,
  OrganizationToken,
  UserToken,
} from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function usePublicEntriesForGoal(goalToken: GoalToken) {
  const url = stringifyUrl({
    url: `/impacts/goals/${goalToken}`,
    query: {},
  });

  return useApi<IImpact[]>(url);
}

export function publicEntriesForGoal(goalToken: GoalToken) {
  const url = stringifyUrl({
    url: `/impacts/goals/${goalToken}`,
    query: {},
  });

  return get<IImpact[]>(url);
}

export function useFindJournalEntries(
  skip = 0,
  limit = 100,
  userToken?: UserToken,
) {
  const url = stringifyUrl({
    url: `/impacts`,
    query: {
      skip: skip,
      limit: limit,
      userToken: userToken,
    },
  });

  return useApi<ISearchImpactsResults>(url);
}

export function findJournalEntries(
  skip = 0,
  limit = 100,
  userToken?: UserToken,
) {
  const url = stringifyUrl({
    url: `/impacts`,
    query: {
      skip: skip,
      limit: limit,
      userToken: userToken,
    },
  });

  return get<ISearchImpactsResults>(url);
}

export function addJournalEntry(
  impactAttributes: Partial<IImpact>,
  collaboratorTokens?: UserToken[],
  relatedGoals?: GoalToken[],
) {
  return post<
    {
      impact: Partial<IImpact>;
      collaboratorTokens: `u_${string}`[];
      relatedGoals: `g_${string}`[];
    },
    IImpact
  >(`/impacts`, {
    impact: impactAttributes,
    collaboratorTokens: collaboratorTokens,
    relatedGoals: relatedGoals,
  });
}

export function suggestEntry(
  attributes: Partial<ISuggestedEntry>,
  userToken: UserToken,
  collaboratorTokens?: UserToken[],
) {
  return post<{
    suggestedEntry: Partial<ISuggestedEntry>;
    userToken: `u_${string}`;
    collaboratorTokens: `u_${string}`[];
  }>(`/impacts/suggestions`, {
    suggestedEntry: attributes,
    userToken: userToken,
    collaboratorTokens: collaboratorTokens,
  });
}

export function useGetSuggestedEntry(suggestedEntryToken: SuggestedEntryToken) {
  const url = stringifyUrl({
    url: `/impacts/suggestions/${suggestedEntryToken}`,
    query: {},
  });

  return useApi<ISuggestedEntry>(url);
}

export function getSuggestedEntry(suggestedEntryToken: SuggestedEntryToken) {
  const url = stringifyUrl({
    url: `/impacts/suggestions/${suggestedEntryToken}`,
    query: {},
  });

  return get<ISuggestedEntry>(url);
}

export function useGetOwnTasks() {
  const url = stringifyUrl({
    url: `/impacts/tasks`,
    query: {},
  });

  return useApi<IImpactTasksResponse>(url);
}

export function getOwnTasks() {
  const url = stringifyUrl({
    url: `/impacts/tasks`,
    query: {},
  });

  return get<IImpactTasksResponse>(url);
}

export function updateJournalEntry(
  impactToken: ImpactToken,
  impactAttributes: Partial<IImpact>,
  collaboratorTokens: UserToken[],
  relatedGoals?: GoalToken[],
) {
  return patch<
    {
      impact: Partial<IImpact>;
      collaboratorTokens: `u_${string}`[];
      relatedGoals: `g_${string}`[];
    },
    IImpact
  >(`/impacts/${impactToken}`, {
    impact: impactAttributes,
    collaboratorTokens: collaboratorTokens,
    relatedGoals: relatedGoals,
  });
}

export function deleteImpact(impactToken: ImpactToken) {
  return del(`/impacts/${impactToken}`);
}

export function upsertImpactFeedback(
  impactToken: ImpactToken,
  responses: Array<{
    token?: QuestionResponseToken;
    questionToken: QuestionToken;
    text: string;
    visibility: FeedbackVisibility;
  }>,
) {
  return put<{
    responses: {
      token?: QuestionResponseToken;
      questionToken: QuestionToken;
      text: string;
      visibility: FeedbackVisibility;
    }[];
  }>(`/impacts/${impactToken}/feedback`, { responses: responses });
}

export function useGetImpactFeedbackForUser(impactToken: ImpactToken) {
  const url = stringifyUrl({
    url: `/impacts/${impactToken}/feedback`,
    query: {},
  });

  return useApi<GetImpactFeedbackResponse>(url);
}

export function getImpactFeedbackForUser(impactToken: ImpactToken) {
  const url = stringifyUrl({
    url: `/impacts/${impactToken}/feedback`,
    query: {},
  });

  return get<GetImpactFeedbackResponse>(url);
}

export function sendInactivityToManager(userToken: UserToken) {
  return post<{}>(
    `/impacts/notifications/${userToken}/sendInactivityToManager`,
  );
}

export function sendOrgCreateReminders(organizationToken: OrganizationToken) {
  return post<{}>(
    `/impacts/notifications/${organizationToken}/sendCreateReminderNotifications`,
  );
}
