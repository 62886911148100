import { IReviewCycle, ReviewCycleToken } from '@shared/review-cycles';
import { useApi } from '@web/common/useApi';
import { BackLink } from '@web/components/BackButton';
import { InlineTabLink, InlineTabs } from '@web/components/InlineTabs';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useMatch, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AdminPageContent } from '../admin/AdminPageContent';
import { AdminReviewCycleStatus } from './AdminReviewCycleStatus';
import { DashboardActionsMenu } from './DashboardActionsMenu';
import { ParticipantSummaryTable } from './ParticipantsTableSummary';
import { ReviewCycleStateTag } from './ReviewCycleStateTag';
import { TeamsTableSummary } from './TeamsTableSummary';

const AdminReviewCycleDashboardPage: React.FC = () => {
  const { reviewCycleToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
  }>();
  const { data: reviewCycle, mutate: reloadReviewCycle } = useApi<IReviewCycle>(
    `/review-cycles/${reviewCycleToken}`,
  );

  const isTeamPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/dashboard/teams',
  );

  if (!reviewCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const handleDashboardChange = () => {
    void reloadReviewCycle();
  };

  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink to={`/admin/review-cycles`} />
        </Row>
        <Row gap={6}>
          <Header1>{reviewCycle.name}</Header1>
          <ReviewCycleStateTag reviewCycle={reviewCycle} />
          <GrowingSpacer />
          <DashboardActionsMenu
            reviewCycle={reviewCycle}
            onChange={handleDashboardChange}
          />
        </Row>
      </Column>
      <Column>
        <AdminReviewCycleStatus reviewCycleToken={reviewCycleToken} />
        <Spacer />
        <InlineTabs>
          <InlineTabLink
            to={`/admin/review-cycles/${reviewCycleToken}/dashboard/participants`}
          >
            Participants
          </InlineTabLink>
          <InlineTabLink
            to={`/admin/review-cycles/${reviewCycleToken}/dashboard/teams`}
          >
            Teams
          </InlineTabLink>
        </InlineTabs>
        <BorderedContainer>
          {isTeamPage ? (
            <TeamsTableSummary reviewCycle={reviewCycle} />
          ) : (
            <ParticipantSummaryTable reviewCycle={reviewCycle} />
          )}
        </BorderedContainer>
      </Column>
    </AdminPageContent>
  );
};

export default AdminReviewCycleDashboardPage;

const BorderedContainer = styled.section`
  border: 1px solid #e0e0e0;
  border-radius: 0 var(--default-border-radius) var(--default-border-radius)
    var(--default-border-radius);
  padding: 18px;
  position: relative;
  top: -1px;
`;
