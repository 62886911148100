import _objectSpread from "@babel/runtime/helpers/esm/objectSpread2";
import * as React from 'react';
function parseColWidth(totalWidth) {
  var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (typeof width === 'number') {
    return width;
  }
  if (width.endsWith('%')) {
    return totalWidth * parseFloat(width) / 100;
  }
  return null;
}

/**
 * Fill all column with width
 */
export default function useWidthColumns(columns, scrollWidth) {
  var filledColumns = React.useMemo(function () {
    // Fill width if needed
    if (scrollWidth && scrollWidth > 0) {
      var totalWidth = 0;
      var missWidthCount = 0;

      // collect not given width column
      columns.forEach(function (col) {
        var colWidth = parseColWidth(scrollWidth, col.width);
        if (colWidth) {
          totalWidth += colWidth;
        } else {
          missWidthCount += 1;
        }
      });

      // Fill width
      var restWidth = scrollWidth - totalWidth;
      var restCount = missWidthCount;
      var avgWidth = restWidth / missWidthCount;
      return columns.map(function (col) {
        var clone = _objectSpread({}, col);
        var colWidth = parseColWidth(scrollWidth, clone.width);
        if (colWidth) {
          clone.width = colWidth;
        } else {
          var colAvgWidth = Math.floor(avgWidth);
          clone.width = restCount === 1 ? restWidth : colAvgWidth;
          restWidth -= colAvgWidth;
          restCount -= 1;
        }
        return clone;
      });
    }
    return columns;
  }, [columns, scrollWidth]);
  return filledColumns;
}