import { IFeedbackTemplate } from '@shared/feedbackTemplates';
import { IFeedback, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Spacer } from '@web/components/layout';
import { EditManagerFeedback } from '@web/feedback/continuous/EditManagerFeedback';
import { Skeleton, Tag } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface IDraftManagerFeedbackResponse {
  draft: IFeedback;
  template: IFeedbackTemplate;
}

export const ManagerFeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { memberToken } = useParams<{ memberToken: UserToken }>();
  const { data: receiver } = useApi(`/users/${memberToken}`);
  const { data, mutate: reloadDraft } = useApi<IDraftManagerFeedbackResponse>(
    `/feedback/${memberToken}/manager/draft`,
  );

  const handleSave = () => {
    void reloadDraft();
  };

  const handleShare = () => {
    void reloadDraft();
    navigate('/feedback/given');
  };

  return (
    <PageContent>
      <PageHeader title="Manager Feedback" navigateBack />
      <Spacer />
      {receiver && data ? (
        <Pane>
          <UserMessage
            user={receiver}
            tag={data.draft.isDraft ? <Tag>Draft</Tag> : undefined}
            large
          >
            <Spacer size={12} />
            <EditManagerFeedback
              receiver={receiver}
              onSaveDraft={handleSave}
              onShare={handleShare}
              draft={data.draft}
              template={data.template}
            />
          </UserMessage>
        </Pane>
      ) : (
        <Skeleton />
      )}
      {isMobile && <Spacer />}
    </PageContent>
  );
};
