import { IGoal } from './goals';
import { IImpact } from './impacts';
import { IEntity, IUser, OrganizationToken, UserToken } from './types';
import { isManagerOf } from './users';

export type OneOnOneToken = `oo_${string}`;
export type OneOnOneRelationshipToken = `oor_${string}`;

export interface IOneOnOneDetails
  extends Omit<IOneOnOne, 'agenda' | 'actionItems'> {
  privateNote: string;
  // The current user's shared notes.
  sharedNotes: string;
  // The 'other' user's shared notes.
  otherSharedNotes: string;
}

export interface IOneOnOne extends IEntity {
  token: OneOnOneToken;
  ownerUser: IUser;
  counterpartUser: IUser;
  endedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  agenda: IAgendaItem[];
  actionItems: IActionItem[];
  sortedAgenda: AgendaItemToken[];
  sortedActionItems: ActionItemToken[];
  relationship?: IOneOnOneRelationship;
}

export enum ScheduleType {
  AD_HOC = 'AD_HOC',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
}

export interface IOneOnOneRelationship extends IEntity {
  token: OneOnOneRelationshipToken;
  organizationToken: OrganizationToken;
  ownerUserToken: UserToken;
  ownerUser: IUser;
  counterpartUserToken: UserToken;
  counterpartUser: IUser;
  startDate?: Date;
  schedule: ScheduleType;
  /** This property indicates if/when the relationship's schedule was updated last */
  scheduleUpdatedDate?: Date;
  archivedDate?: Date;
}

export type AgendaItemToken = `ai_${string}`;
export interface IAgendaItem extends IEntity {
  token: AgendaItemToken;
  text?: string;
  createdBy?: IUser;
  createdByToken?: UserToken;
  completedBy?: IUser;
  completedByToken?: UserToken;
  completedDate?: Date;
  journalEntries: IImpact[];
  goals: IGoal[];
}

export type ActionItemToken = `act_${string}`;
export interface IActionItem extends IEntity {
  token: ActionItemToken;
  createdBy?: IUser;
  createdByToken?: UserToken;
  completedBy?: IUser;
  completedByToken?: UserToken;
  text: string;
  completedDate?: Date;
}

export interface IOneOnOneMetrics {
  completedOneOnOnes: number;
  // The ScheduleType of the 1:1 or undefined if it wasn't explicitly scheduled.
  managerType: ScheduleType | undefined;
}

export const canArchive = ({
  ownerUser,
  counterpartUser,
}: IOneOnOneDetails) => {
  if (
    isManagerOf(ownerUser, counterpartUser.token) ||
    isManagerOf(counterpartUser, ownerUser.token)
  ) {
    return false;
  }

  return true;
};
