import { sortSurveyQuestions } from '@client/SurveyCyclesClient';
import { mapByToken } from '@shared/mapByToken';
import { IQuestion, QuestionToken } from '@shared/questions';
import { ISurveyCycle, SurveyCycleToken } from '@shared/surveys';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { ISortableQuestion, QuestionItemRow } from './QuestionItemRow';

interface Props {
  surveyCycleToken: SurveyCycleToken;
  sortedSections: ISurveyCycle['sortedSections'];
  questions?: IQuestion[];
  onEdit: (question: IQuestion) => void;
  onDelete: (questionToken: QuestionToken) => void;
  onClone: (questionToken: QuestionToken) => void;
  readonly?: boolean;
}

const hasChanged = (
  itemsA: ISortableQuestion[],
  itemsB: ISortableQuestion[],
) => {
  const idHashA = itemsA.map((item) => item.id).join(':');
  const idHashB = itemsB.map((item) => item.id).join(':');
  return idHashA !== idHashB;
};
export const QuestionsList: React.FC<Props> = ({
  surveyCycleToken,
  sortedSections,
  questions = [],
  onEdit,
  onClone,
  onDelete,
  readonly,
}) => {
  const [sortedQuestionItems, setSortedQuestionItems] = React.useState<
    ISortableQuestion[]
  >(createSortableQuestionItems(questions, sortedSections));
  React.useEffect(() => {
    setSortedQuestionItems(
      createSortableQuestionItems(questions, sortedSections),
    );
  }, [questions, sortedSections]);

  const sortQuestionItems = (newSortedQuestionItems: ISortableQuestion[]) => {
    if (!hasChanged(sortedQuestionItems, newSortedQuestionItems)) {
      return;
    }

    const newPositions: Record<QuestionToken, number> = {};
    for (let i = 0; i < newSortedQuestionItems.length; i++) {
      const token = newSortedQuestionItems[i].id;
      newPositions[token] = i;
    }
    void sortSurveyQuestions(surveyCycleToken, newPositions);
    setSortedQuestionItems(newSortedQuestionItems);
  };

  return (
    <ReactSortable
      list={sortedQuestionItems}
      setList={sortQuestionItems}
      easing="cubic-bezier(0.55, 0, 1, 0.45)"
      animation={100}
      handle=".drag-anchor"
    >
      {sortedQuestionItems.map((item, i) => (
        <QuestionItemRow
          key={item.id}
          questionItem={item}
          position={i + 1}
          onClick={() => {
            const question = questions.find(
              (question) => question.token === item.id,
            );
            if (question) {
              onEdit(question);
            }
          }}
          onDelete={
            !readonly
              ? () => {
                  onDelete(item.id);
                }
              : undefined
          }
          onClone={
            !readonly
              ? () => {
                  onClone(item.id);
                }
              : undefined
          }
        />
      ))}
    </ReactSortable>
  );
};

const createSortableQuestionItems = (
  questions: IQuestion[],
  sortedSections: ISurveyCycle['sortedSections'],
): ISortableQuestion[] => {
  const questionPositions: Record<QuestionToken, number> = {};
  sortedSections.forEach((token, index) => {
    questionPositions[token] = index;
  });

  const questionsByToken = mapByToken(questions);

  return sortedSections
    .filter((t) => questionsByToken.has(t))
    .map((token) => {
      const question = questionsByToken.get(token);
      if (!question) {
        return null;
      }
      return {
        id: question.token as string,
        text: question.text,
        type: question.type,
        position: question.position as any,
        required: question.required ?? false,
      };
    })
    .filter((section) => !!section) as ISortableQuestion[];
};
