"use strict";
"use client";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Collapse = _interopRequireDefault(require("./Collapse"));
var _default = _Collapse.default;
exports.default = _default;