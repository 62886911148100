import { QuestionType } from '@shared/QuestionType';
import { QuestionToken } from '@shared/questions';
import {
  ISurveyCycle,
  ISurveyParticipant,
  sortedSurveyQuestions,
} from '@shared/surveys';
import { PageContent } from '@web/app/Page';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Max, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { InputQuestions } from '@web/questions/InputQuestions';
import { useQuestionResponseMap } from '@web/questions/useQuestionResponseMap';
import { Button, Skeleton, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ISurveyDetails {
  surveyCycle: ISurveyCycle;
  participant: ISurveyParticipant;
}

export const TakeSurveyPage: React.FC = () => {
  const { reloadOutstandingTaskCount } = useOutstandingCount();
  const navigate = useNavigate();
  const navigateBackOrGoto = useNavigateBack();
  const { surveyCycleToken } = useParams();
  const { data: surveyDetails, mutate: reloadSurveyDetails } =
    useApi<ISurveyDetails>(`/survey-cycles/${surveyCycleToken}/details`);

  const handleSurveyComplete = () => {
    void reloadOutstandingTaskCount();
    void reloadSurveyDetails();
    navigateBackOrGoto('/requests');
  };

  if (!surveyDetails?.surveyCycle) {
    return (
      <PageContent>
        <PageHeader
          title={surveyDetails?.surveyCycle.name ?? ''}
          mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
          navigateBack
          defaultNavigateBackTo="/requests"
        />
        <Skeleton />
      </PageContent>
    );
  }

  if (surveyDetails.participant.submittedDate) {
    navigate('view', { replace: true });
  }

  return (
    <PageContent>
      <PageHeader
        title={surveyDetails?.surveyCycle.name ?? ''}
        mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
        navigateBack
        defaultNavigateBackTo="/requests"
      />
      <Pane>
        <Max width="800px">
          {surveyDetails.surveyCycle.endedDate ? (
            <Text>Survey is no longer active</Text>
          ) : (
            <>
              <Text style={{ fontStyle: 'italic' }}>
                {surveyDetails.surveyCycle.anonymous
                  ? 'This survey is anonymous.'
                  : 'Your responses will be shared with your manager.'}
              </Text>
              <Spacer size={12} />
              <TakeSurvey
                surveyCycle={surveyDetails.surveyCycle}
                participant={surveyDetails.participant}
                onComplete={handleSurveyComplete}
              />
            </>
          )}
        </Max>
      </Pane>
    </PageContent>
  );
};

interface ITakeSurveyProps {
  surveyCycle: ISurveyCycle;
  participant: ISurveyParticipant;
  onComplete: () => void;
}
const TakeSurvey: React.FC<ITakeSurveyProps> = ({
  surveyCycle,
  participant,
  onComplete,
}) => {
  const [requiredErrors, setRequiredErrors] = React.useState<
    Record<QuestionToken, string>
  >({});
  const { responseMap, handleQuestionResponse, validResponses } =
    useQuestionResponseMap(surveyCycle.questions, participant.responses);

  const handleSubmit = async () => {
    const requiredErrors: Record<QuestionToken, string> = {};
    for (const question of surveyCycle.questions) {
      if (question.required) {
        const response = responseMap.get(question.token);
        if (
          !response ||
          (question.type === QuestionType.RATING && !response?.rating) ||
          (question.type === QuestionType.OPEN_ENDED && !response?.text)
        ) {
          requiredErrors[question.token] = 'Response is required';
        }
      }
    }
    if (Object.values(requiredErrors).length > 0) {
      setRequiredErrors(requiredErrors);
      return;
    }

    try {
      await post(`/survey-cycles/${surveyCycle.token}/responses`, {
        responses: validResponses,
      });
      void message.success('Success');
      onComplete();
    } catch (error) {
      void message.error('Error');
    }
  };

  if (participant.submittedDate) {
    return (
      <InputQuestions
        sortedQuestions={sortedSurveyQuestions(surveyCycle)}
        responseMap={responseMap}
        onResponseChanged={handleQuestionResponse}
        errors={requiredErrors}
      />
    );
  }

  return (
    <Column gap={24}>
      <InputQuestions
        sortedQuestions={sortedSurveyQuestions(surveyCycle)}
        responseMap={responseMap}
        onResponseChanged={handleQuestionResponse}
        errors={requiredErrors}
      />
      <Row gap={12}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={validResponses.length === 0}
        >
          Submit
        </Button>
      </Row>
    </Column>
  );
};
