const IS_BOLD = 1;
const IS_ITALIC = 2;
const IS_UNDERLINE = 8;
const IS_CODE = 16;
export {
  IS_BOLD,
  IS_CODE,
  IS_ITALIC,
  IS_UNDERLINE
};
