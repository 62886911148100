import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { deactivateUser } from '@client/DeactivationClient';
import { Feature } from '@shared/features';
import { ISearchResults, IUser, UserMapItem } from '@shared/types';
import { otherManagerTokensOf, primaryManagerTokenOf } from '@shared/users';
import { useFeature } from '@web/common/useFeature';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/text';
import { EditUserButton } from '@web/users/EditUserButton';
import { Button, Table, Tooltip, message } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SelectPrimaryManagerModal } from './SelectPrimaryManagerModal';

interface Props {
  searchResults: ISearchResults<IUser>;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onChange: () => void;
}

export const AdminUsersTable: React.FC<Props> = ({
  page,
  searchResults,
  pageSize,
  onPageChange,
  onChange,
}) => {
  const { confirm, contextHolder } = useModalConfirm();
  const { booleanValue: allowMultipleManagers } = useFeature(
    Feature.ALLOW_MULTIPLE_MANAGERS,
  );
  const [editPrimaryManagerUser, setEditPrimaryManagerUser] =
    React.useState<UserMapItem>();
  const {
    results: users,
    total,
    userMap,
  } = searchResults ?? { results: [], total: 0 };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Person',
      dataIndex: 'name',
      key: 'name',
      render: (name, user) => {
        return (
          <Row gap={12}>
            <UserAvatar user={user} />
            <Column>
              <Row>
                <Text
                  style={{
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  {name}
                </Text>
                <Spacer size={6} />
                <Link to={`/profile/${user.token}`}>
                  <Button size="small" type="text">
                    <SearchOutlined />
                  </Button>
                </Link>
                <EditUserButton user={user} onChange={onChange} />
              </Row>
              <Text style={{ wordWrap: 'break-word' }}>{user.email}</Text>
            </Column>
          </Row>
        );
      },
    },
    {
      title: allowMultipleManagers ? 'Primary Manager' : 'Manager',
      key: 'manager',
      render: (_, user) => {
        const managerToken = primaryManagerTokenOf(user);
        return managerToken ? (
          <Row gap={3}>
            <Text>{userMap[managerToken].name}</Text>{' '}
            {user.managerTokens?.length > 1 && (
              <Button
                size="small"
                type="text"
                onClick={() => {
                  setEditPrimaryManagerUser(user);
                }}
              >
                <EditOutlined />
              </Button>
            )}
          </Row>
        ) : (
          ''
        );
      },
    },
  ];

  if (allowMultipleManagers) {
    columns.push({
      title: 'Other Managers',
      key: 'manager',
      render: (_, user) => {
        const managerTokens = otherManagerTokensOf(user);
        return managerTokens?.length > 0 ? (
          <Text>
            {managerTokens.map((token) => userMap[token].name).join(', ')}
          </Text>
        ) : (
          ''
        );
      },
    });
  }

  columns.push({
    title: 'Actions',
    key: 'actions',
    align: 'right',
    render: (_, user) => {
      const handleDeactivate = async () => {
        const confirmed = await confirm(
          `This will remove ${user.name} from your organization entirely.`,
        );
        if (!confirmed) {
          return;
        }

        try {
          await deactivateUser(user.token);
          void message.success('User');
          onChange();
        } catch (error) {
          void message.error('Error');
        }
      };
      return (
        <Tooltip title={`Deactivate this account`}>
          <Button type="text" onClick={handleDeactivate}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      );
    },
  });

  const pagination: TablePaginationConfig = {
    total,
    onChange: onPageChange,
    current: page,
    pageSize,
    showSizeChanger: false,
    showTotal: (total) => `${total} users`,
  };

  return (
    <>
      <NoRowHoverTable
        rowKey="token"
        pagination={pagination}
        columns={columns}
        dataSource={users}
      />
      {editPrimaryManagerUser && (
        <SelectPrimaryManagerModal
          onCancel={() => {
            setEditPrimaryManagerUser(null);
          }}
          onSave={() => {
            setEditPrimaryManagerUser(null);
            onChange();
          }}
          userToken={editPrimaryManagerUser.token}
          managers={editPrimaryManagerUser.managerTokens.map(
            (token) => userMap[token],
          )}
        />
      )}
      {contextHolder}
    </>
  );
};

const NoRowHoverTable = styled(Table)`
  tbody tr:hover td {
    background: unset !important;
  }
`;
