import { QuestionType, QuestionTypeLabels } from '@shared/QuestionType';
import {
  IQuestion,
  IRatingQuestion,
  isRatingQuestion,
} from '@shared/questions';
import { RatingScale } from '@shared/rating_scales';
import { ISurveyCycle } from '@shared/surveys';
import { patch, post } from '@web/common/api';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import {
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { sortBy } from 'lodash';
import React from 'react';

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
  open: boolean;
  surveyCycle: ISurveyCycle;
  question?: IQuestion;
  readonly?: boolean;
}

export const EditQuestionModal: React.FC<Props> = ({
  onUpdate,
  onCancel,
  open,
  surveyCycle,
  question,
  readonly,
}) => {
  const [form] = Form.useForm<IQuestionForm>();
  const questionType = Form.useWatch('type', form);
  const [isSaving, setIsSaving] = React.useState(false);
  const [required, setRequired] = React.useState(
    question ? question.required : false,
  );

  const isEditMode = !!question;

  const handleOk = async () => {
    setIsSaving(true);
    let questionForm: IQuestionForm;
    try {
      questionForm = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const questionAttributes: Partial<IQuestion> = {
        text: questionForm.text,
        type: questionForm.type,
        scale: createScale(questionForm),
        required,
      };
      if (isEditMode) {
        await patch<IQuestion>(
          `/survey-cycles/${surveyCycle.token}/questions/${question.token}`,
          questionAttributes,
        );
      } else {
        await post<IQuestion>(
          `/survey-cycles/${surveyCycle.token}/questions`,
          questionAttributes,
        );
      }

      void message.success('Success');
      form.resetFields();
      onUpdate();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  const initialQuestionFormValues: Partial<IQuestionForm> = isEditMode
    ? {
        text: question.text,
        type: question.type ?? QuestionType.RATING,
        ...flattenScale(question as IRatingQuestion),
      }
    : {
        type: QuestionType.RATING,
      };

  return (
    <Modal
      title={
        readonly
          ? 'View Question'
          : isEditMode
          ? 'Edit Question'
          : 'Add Question'
      }
      open={open}
      onOk={() => {
        void handleOk();
      }}
      okButtonProps={{
        disabled: readonly,
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="600px"
    >
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        initialValues={initialQuestionFormValues}
      >
        <Form.Item label="Question Type" name="type">
          <Select disabled={isSaving || isEditMode}>
            <Select.Option value={QuestionType.RATING}>
              {QuestionTypeLabels[QuestionType.RATING]}
            </Select.Option>
            <Select.Option value={QuestionType.OPEN_ENDED}>
              {QuestionTypeLabels[QuestionType.OPEN_ENDED]}
            </Select.Option>
            <Select.Option value={QuestionType.LABEL}>
              {QuestionTypeLabels[QuestionType.LABEL]}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Question Text"
          name="text"
          rules={[{ required: true, message: 'Text is required' }]}
        >
          <TextArea autoSize disabled={isSaving || readonly} autoFocus />
        </Form.Item>
        {questionType === QuestionType.RATING && (
          <>
            <Typography.Title level={5}>Responses</Typography.Title>
            <Form.Item
              name="scaleLabel1"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="1." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item
              name="scaleLabel2"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="2." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item
              name="scaleLabel3"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="3." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item name="scaleLabel4">
              <Input prefix="4." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item name="scaleLabel5">
              <Input prefix="5." disabled={isSaving || readonly} />
            </Form.Item>
          </>
        )}
        {questionType !== QuestionType.LABEL && (
          <Row gap={6}>
            <Checkbox
              checked={required}
              onChange={() => {
                setRequired(!required);
              }}
            />
            <Text>Required</Text>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

interface IQuestionForm {
  text: string;
  type: QuestionType;
  scaleLabel1?: string;
  scaleLabel2?: string;
  scaleLabel3?: string;
  scaleLabel4?: string;
  scaleLabel5?: string;
  noLabel?: string;
  yesLabel?: string;
}

const flattenScale = (question: IRatingQuestion) => {
  const sortedScale = sortBy(question.scale ?? [], 'value');
  if (isRatingQuestion(question)) {
    return {
      scaleLabel1: sortedScale[0]?.label,
      scaleLabel2: sortedScale[1]?.label,
      scaleLabel3: sortedScale[2]?.label,
      scaleLabel4: sortedScale[3]?.label,
      scaleLabel5: sortedScale[4]?.label,
    };
  }
  return {};
};

const createScale = (questionForm: IQuestionForm): RatingScale | undefined => {
  if (questionForm.type === QuestionType.RATING) {
    const scale: RatingScale = [];
    if (questionForm.scaleLabel1) {
      scale.push({
        value: scale.length + 1,
        label: questionForm.scaleLabel1,
      });
    }
    if (questionForm.scaleLabel2) {
      scale.push({
        value: scale.length + 1,
        label: questionForm.scaleLabel2,
      });
    }
    if (questionForm.scaleLabel3) {
      scale.push({
        value: scale.length + 1,
        label: questionForm.scaleLabel3,
      });
    }
    if (questionForm.scaleLabel4) {
      scale.push({
        value: scale.length + 1,
        label: questionForm.scaleLabel4,
      });
    }
    if (questionForm.scaleLabel5) {
      scale.push({
        value: scale.length + 1,
        label: questionForm.scaleLabel5,
      });
    }
    return scale;
  }
};
