import { formatDate } from '@shared/formatDate';
import {
  IFeedback,
  IRecentFeedbackResponse,
  IUser,
  UserMap,
  isImpactToken,
} from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text, TruncateText } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Widget, WidgetDate, WidgetRow } from './Widget';

export const FeedbackWidget: React.FC<{
  user: IUser;
  given?: boolean;
  extra?: React.ReactNode;
  placeholder: string;
}> = ({ user, given = false, extra, placeholder }) => {
  const { data: feedbackResponse } = useApi<IRecentFeedbackResponse>(
    `/feedback/recent?limit=3&userToken=${user.token}`,
  );
  const { user: currentUser } = useAuth();
  const isReceiver = currentUser.token === user.token;
  const feedback = given ? feedbackResponse?.given : feedbackResponse?.feedback;
  return (
    <Column>
      <Row gap={12}>
        {given ? (
          <Header2>Feedback Given</Header2>
        ) : (
          <Link to={isReceiver ? '/feedback' : `/team/${user.token}/feedback`}>
            <Header2>Feedback Received</Header2>
          </Link>
        )}
        {extra}
      </Row>
      <Spacer size={6} />
      {!feedback ? (
        <Skeleton />
      ) : feedback.length > 0 ? (
        <Widget>
          {feedback.map((feedback, index) => (
            <FeedbackWidgetRow
              hideBorder={index === 0}
              key={feedback.token}
              feedback={feedback}
              userMap={feedbackResponse.userMap}
            />
          ))}
        </Widget>
      ) : (
        <Text>{placeholder}</Text>
      )}
    </Column>
  );
};
const FeedbackWidgetRow: React.FC<{
  feedback: IFeedback;
  userMap: UserMap;
  hideBorder: boolean;
}> = ({ feedback, userMap, hideBorder }) => {
  const { user } = useAuth();
  const isReceiver = user.token === feedback.receiverToken;
  const giver = userMap[feedback.giverToken];
  const receiver = userMap[feedback.receiverToken];
  // this shouldn't happen but will if a user is deleted and associated
  // feedback given is not removed. unclear how this might happen
  if (!giver) {
    return null;
  }

  let summary: string;
  if (isReceiver) {
    summary = `${giver.name} gave you feedback`;
    if (isImpactToken(feedback.entityToken)) {
      summary = `${giver.name} gave input on your Journal entry`;
    } else if (feedback.reviewCycleToken) {
      summary = `${giver.name} gave you peer feedback`;
    }
  } else {
    summary = `${giver.name} gave ${receiver.name} feedback`;
    if (isImpactToken(feedback.entityToken)) {
      summary = `${giver.name} gave Journal feedback to ${receiver.name}`;
    } else if (feedback.reviewCycleToken) {
      summary = `${giver.name} gave ${receiver.name} peer feedback`;
    }
  }

  return (
    <Link
      to={
        isReceiver
          ? `/feedback/${feedback.token}/view`
          : `/team/${feedback.receiverToken}/feedback/${feedback.token}/view`
      }
    >
      <WidgetRow hideBorder={hideBorder}>
        <UserAvatar user={giver} size={20} />
        <TruncateText>{summary}</TruncateText>
        <WidgetDate>{formatDate(feedback.updatedDate)}</WidgetDate>
      </WidgetRow>
    </Link>
  );
};
