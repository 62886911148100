import { IReviewCycleStatus, ReviewCycleToken } from '@shared/review-cycles';
import { useApi } from '@web/common/useApi';
import { Grid } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';

import { BorderedPane } from './BorderedPane';
import { Status } from './Status';

export const AdminReviewCycleStatus: React.FC<{
  reviewCycleToken: ReviewCycleToken;
}> = ({ reviewCycleToken }) => {
  const { data: reviewCycleStatus } = useApi<IReviewCycleStatus>(
    `/review-cycles/${reviewCycleToken}/admin/status`,
  );

  if (!reviewCycleStatus) {
    return <Skeleton />;
  }

  const { peerReview, selfReview, managerReview } = reviewCycleStatus;
  const statuses = [peerReview, selfReview, managerReview].filter(
    (status) => !!status,
  );
  const columns = new Array<string>(statuses.length).fill('1fr').join(' ');
  return (
    <Grid gap={12} columns={columns}>
      {statuses.map((reviewStatus) => (
        <BorderedPane key={reviewStatus.title}>
          <Status
            title={reviewStatus.title}
            startDate={reviewStatus.startDate}
            endDate={reviewStatus.endDate}
            text={reviewStatus.text}
            progressPercent={reviewStatus.progressPercent}
          />
        </BorderedPane>
      ))}
    </Grid>
  );
};
