import {
  IQuestion,
  IRankOption,
  IRankQuestion,
  isOpenEndedQuestion,
  isRankQuestion,
  isRatingQuestion,
  validateRanking,
} from '@shared/questions';
import {
  ReflectionResponseInput,
  isRatingResponse,
  isTextResponse,
} from '@shared/reflections';
import { errorColor } from '@web/app/styles/ColorStyles';
import { Column, Grid, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { InputNumber, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import styled from 'styled-components';

export const ReflectionQuestionInput: React.FC<{
  question: IQuestion;
  value?: ReflectionResponseInput;
  onChange?: (questionResponse: ReflectionResponseInput) => void;
  disabled?: boolean;
  hasSubmitted?: boolean;
}> = ({ question, value, onChange, disabled, hasSubmitted }) => {
  if (isOpenEndedQuestion(question)) {
    const handleTextChanged = (content: string) => {
      onChange({
        ...value,
        type: 'text',
        content,
      });
    };
    return (
      <TextArea
        value={isTextResponse(value) ? value.content : ''}
        onChange={(e) => {
          handleTextChanged(e.currentTarget.value);
        }}
        disabled={disabled}
      />
    );
  } else if (isRatingQuestion(question)) {
    const includeValueInLabel = question.includeValueInLabel ?? true;
    return (
      <Column gap={12}>
        {question.scale.map((rating) => (
          <Row
            gap={6}
            key={`rating_option_${rating.value}`}
            style={{ cursor: 'pointer', paddingLeft: 12 }}
            onClick={() => {
              onChange({
                ...value,
                type: 'rating',
                rating: rating.value,
              });
            }}
          >
            <StyledRadio
              checked={
                isRatingResponse(value)
                  ? value.rating === rating.value
                  : undefined
              }
              disabled={disabled}
            />
            <Text>
              {includeValueInLabel
                ? `${rating.value} - ${rating.label}`
                : rating.label}
            </Text>
          </Row>
        ))}
      </Column>
    );
  } else if (isRankQuestion(question)) {
    return (
      <ReflectionRankQuestionInput
        question={question}
        ranking={(value as any).ranking}
        onChange={(ranking) =>
          onChange({
            ...value,
            type: 'rank',
            ranking,
          } as any)
        }
        disabled={disabled}
        hasSubmitted={hasSubmitted}
      />
    );
  }
};
const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: #888;
  }
`;

const ReflectionRankQuestionInput: React.FC<{
  question: IRankQuestion;
  ranking?: Record<IRankOption['token'], number>;
  onChange?: (ranking: Record<IRankOption['token'], number>) => void;
  disabled?: boolean;
  hasSubmitted?: boolean;
}> = ({ question, ranking = {}, onChange, disabled, hasSubmitted }) => {
  const { duplicates, missing } = validateRanking(
    question.rankingOptions,
    ranking,
  );

  const missingError =
    hasSubmitted && missing.size > 0
      ? 'A value must be provided for all options'
      : null;
  const duplicatesError =
    duplicates.size > 0 ? 'Rank values can only be used once' : null;

  return (
    <Column gap={6} style={{ margin: '0 0 0 24px' }}>
      {question.rankingOptions.map((option) => (
        <Grid key={`rating_option_${option.token}`} columns="330px 1fr" gap={6}>
          <Text
            style={{
              lineHeight: '20px',
              alignSelf: 'center',
              fontWeight: 500,
            }}
          >
            {option.label}
          </Text>
          <Row gap={6}>
            <StyledInputNumber
              min={1}
              max={question.rankingOptions.length}
              onChange={(rank) => {
                onChange({
                  ...ranking,
                  [option.token]: rank,
                });
              }}
              precision={0}
              controls={false}
              disabled={disabled}
              status={duplicates.has(option.token) ? 'error' : ''}
              value={ranking[option.token]}
            />
          </Row>
        </Grid>
      ))}
      {(missingError || duplicatesError) && (
        <Text style={{ color: errorColor.string() }}>
          {duplicatesError ?? missingError}
        </Text>
      )}
    </Column>
  );
};

const StyledInputNumber = styled(InputNumber)`
  width: 50px;
  align-self: flex-start;
  input.ant-input-number-input {
    padding-left: 9px;
    text-align: center;
  }
`;
