import { BadRequestException, PipeTransform } from '@nestjs/common';
import { isNil } from 'lodash';
import randomstring from 'randomstring';

export const generateToken =
  <T>(prefix: string, length = 8) =>
  (): T => {
    return `${prefix}${randomstring.generate(length)}` as T;
  };

export const isToken =
  <T extends string>(prefix: string) =>
  (testToken: string): testToken is T => {
    return !!testToken && testToken.startsWith(prefix);
  };

export interface ParseTokenPipeOptions {
  optional?: boolean;
}

export type IsTokenFunc<T extends string> = (
  testToken: string,
) => testToken is T;

export const ParseTokenPipe = <T extends string>(
  entity: TokenEntityClass<T>,
  options?: ParseTokenPipeOptions,
): PipeTransform<string, Promise<T>> => {
  return {
    transform: async (value: string): Promise<T> => {
      if (isNil(value) && options?.optional) {
        return value;
      }
      if (entity.isToken(value)) {
        return value;
      }
      throw new BadRequestException('Validation failed');
    },
  };
};

export interface TokenEntityClass<T extends string> {
  isToken: IsTokenFunc<T>;
}
