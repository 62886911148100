import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { SelectUser } from '@web/components/users/SelectUser';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { oneOnOneUrl } from './List1on1sPage';

export const Add1on1Modal: React.FC<{
  onCancel: () => void;
  omitUserTokens?: UserToken[];
}> = ({ onCancel, omitUserTokens }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedUserToken, setSelectedUserToken] = useState<UserToken>(null);

  const handleOk = async () => {
    navigate(oneOnOneUrl(selectedUserToken, user.token));
  };

  const handleUsersSelected = (userToken: UserToken) => {
    setSelectedUserToken(userToken);
  };

  return (
    <Modal
      title="Add 1-on-1"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      onCancel={onCancel}
      width="400px"
      okText="Add"
      okButtonProps={{
        disabled: selectedUserToken === null,
      }}
    >
      <SelectUser
        style={{ width: '100%' }}
        organizationToken={user.organizationToken}
        onChange={handleUsersSelected}
        omitUserTokens={omitUserTokens}
      />
    </Modal>
  );
};
