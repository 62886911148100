import { DatePicker } from 'antd';
import { addDays, format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

export function parseDayjs(dateString: string) {
  return dayjs(new Date(`${dateString}T23:59:59.999Z`));
}

export const SelectDate: React.FC<{
  value?: string;
  disabled?: boolean;
  onChange: (newValue: string) => void;
  bordered?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}> = ({ value, onChange, disabled, bordered, placeholder, style }) => {
  const dayjsValue = value ? parseDayjs(value) : undefined;

  const handleDatePickerChanged = (newDaysJsValue: Dayjs) => {
    const newValue = newDaysJsValue
      ? newDaysJsValue.format('YYYY-MM-DD')
      : undefined;
    onChange(newValue);
  };

  return (
    <DatePicker
      value={dayjsValue}
      disabled={disabled}
      onChange={handleDatePickerChanged}
      allowClear
      bordered={bordered}
      placeholder={placeholder}
      style={style}
    />
  );
};

export const SelectDateRange: React.FC<{
  start: string;
  end: string;
  disabled?: boolean;
  onChange: (newStart: string, newEnd: string) => void;
  style?: React.CSSProperties;
}> = ({
  start = format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  end = format(addDays(new Date(), 2), 'yyyy-MM-dd'),
  onChange,
  disabled,
  style,
}) => {
  const dayjsStart = parseDayjs(start);
  const dayjsEnd = parseDayjs(end);

  const handleDatePickerChanged = ([dayjsStart, dayjsEnd]: Dayjs[]) => {
    const startDate = dayjsStart.format('YYYY-MM-DD');
    const endDate = dayjsEnd.format('YYYY-MM-DD');
    onChange(startDate, endDate);
  };

  return (
    <DatePicker.RangePicker
      value={[dayjsStart, dayjsEnd]}
      disabled={disabled}
      onChange={handleDatePickerChanged}
      style={style}
    />
  );
};
