"use strict";
"use client";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Table = _interopRequireDefault(require("./Table"));
var _default = _Table.default;
exports.default = _default;