import { RequestFeedbackTemplatePage } from '@web/feedback/RequestFeedbackTemplatePage';
import { ViewFeedbackPage } from '@web/feedback/ViewFeedbackPage';
import { ViewReportsFeedbackPage } from '@web/feedback/ViewReportsFeedbackPage';
import { RequestUpwardFeedbackPage } from '@web/feedback/continuous/RequestUpwardFeedbackPage';
import { GoalPage } from '@web/goals/GoalPage';
import { NewGoalPage } from '@web/goals/NewGoalPage';
import { ReportsGoalsPage } from '@web/goals/ReportsGoalsPage';
import { JournalManagerPage } from '@web/journal/JournalManagerPage';
import { SuggestEntryPage } from '@web/journal/SuggestEntryPage';
import { ViewJournalEntryPage } from '@web/journal/ViewJournalEntryPage';
import { ViewReportsReflectionsPage } from '@web/reflections/ViewReportsReflectionsPage';
import TeamMembersPage from '@web/team/TeamMembersPage';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Redirect } from './Redirect';

export const teamRoutes = ({
  upwardFeedbackEnabled,
}: {
  upwardFeedbackEnabled: boolean;
}) => {
  return (
    <>
      <Route index element={<Navigate to="members" replace />} />
      <Route path="feedback" element={<Navigate to="../members" replace />} />
      <Route path="entries" element={<Navigate to="../members" replace />} />
      <Route path="members">
        <Route index element={<TeamMembersPage />} />
      </Route>
      <Route
        path="feedback/request"
        element={<RequestFeedbackTemplatePage />}
      />
      <Route
        path="feedback/:userToken/request"
        element={<RequestFeedbackTemplatePage />}
      />
      {upwardFeedbackEnabled && (
        <Route
          path="feedback/:userToken/request/upward"
          element={<RequestUpwardFeedbackPage />}
        />
      )}
      <Route path=":userToken">
        <Route index element={<Navigate to="feedback" replace />} />
        <Route
          path="entries"
          element={
            <Redirect to={({ userToken }) => `/team/${userToken}/journal`} />
          }
        />
        <Route
          path="entries/:impactToken"
          element={
            <Redirect
              to={({ userToken, impactToken }) =>
                `/team/${userToken}/journal/${impactToken}`
              }
            />
          }
        />
        <Route path="feedback" element={<ViewReportsFeedbackPage />} />
        <Route path="goals">
          <Route index element={<ReportsGoalsPage />} />
          <Route path="new" element={<NewGoalPage />} />
          <Route path=":goalToken" element={<GoalPage />} />
        </Route>
        <Route
          path="feedback/:feedbackToken/view"
          element={<ViewFeedbackPage />}
        />
        <Route path="journal" element={<JournalManagerPage />} />
        <Route path="journal/suggest" element={<SuggestEntryPage />} />
        <Route path="journal/:impactToken" element={<ViewJournalEntryPage />} />
        <Route path="members" element={<TeamMembersPage />} />
        <Route path="reflections" element={<ViewReportsReflectionsPage />} />
      </Route>
    </>
  );
};
