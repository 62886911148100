import { findTemplateByToken } from '@shared/feedbackTemplates';
import { IFeedback, UserMapItem } from '@shared/types';
import { Colors } from '@web/app/styles/ColorStyles';
import { Tag } from 'antd';
import * as React from 'react';

import { QuestionResponses } from '../../questions/QuestionResponses';
import { UserFeedback } from '../FeedbackList/UserFeedback';

export const UpwardFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
}> = ({ feedback, giver, receiver }) => {
  const template = findTemplateByToken(feedback.templateToken);

  if (!template) {
    return null;
  }

  return (
    <UserFeedback
      giver={giver}
      receiver={receiver}
      date={feedback.updatedDate}
      tag={<Tag color={Colors.supernova}>Upward Feedback</Tag>}
    >
      <QuestionResponses
        questions={template.feedbackQuestions}
        responses={feedback.responses}
      />
    </UserFeedback>
  );
};
