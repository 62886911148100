import { isEqual, mapValues, sample, times } from 'lodash';

import { QuestionType } from './QuestionType';
import {
  dimensionFeedbackTemplates,
  managerFeedbackRequestTemplates,
  managerFeedbackTemplates,
  peerFeedbackRequestTemplates,
  selfFeedbackRequestTemplates,
  selfFeedbackTemplates,
  upwardFeedbackTemplates,
} from './feedbackTemplates';
import { RatingScale } from './rating_scales';
import { reflectionTemplates } from './reflections';
import { OrganizationToken } from './types';

/**
 * An arbitrary sentinel value to record when users decline
 * to respond to a rating question
 */
export const DECLINE_TO_RESPOND_RATING = -42;

export enum MeasurableBehavior {
  COMMUNICATION = 'COMMUNICATION',
  COLLABORATION = 'COLLABORATION',
  OPENNESS = 'OPENNESS',
  PROBLEM_SOLVING = 'PROBLEM_SOLVING',
  IMPACT = 'IMPACT',
  PLANNING = 'PLANNING',
  PRODUCTIVITY = 'PRODUCTIVITY',
  OWNERSHIP = 'OWNERSHIP',
  RESPECT = 'RESPECT',
  TRANSPARENCY = 'TRANSPARENCY',
  TRUST = 'TRUST',
  CAREER_DEVELOPMENT = 'CAREER_DEVELOPMENT',
  FAIRNESS = 'FAIRNESS',
  // Organization assessement measures
  FEEDBACK_VALUE = 'FEEDBACK_VALUE',
  GROWTH_OUTLOOK = 'GROWTH_OUTLOOK',
  RECOGNITION = 'RECOGNITION',
  DIVERSE_PERSPECTIVES = 'DIVERSE_PERSPECTIVES',
  EMPOWERMENT = 'EMPOWERMENT',
}

export const feedbackDimensions = [
  MeasurableBehavior.COMMUNICATION,
  MeasurableBehavior.COLLABORATION,
  MeasurableBehavior.OPENNESS,
  MeasurableBehavior.PROBLEM_SOLVING,
  MeasurableBehavior.IMPACT,
  MeasurableBehavior.PLANNING,
  MeasurableBehavior.PRODUCTIVITY,
  MeasurableBehavior.OWNERSHIP,
].sort();

export interface IMeasurableBehaviourContext {
  label: string;
  description?: string;
  selfDescription?: string;
}

export const MeasurableBehaviourContext: Record<
  MeasurableBehavior,
  IMeasurableBehaviourContext
> = {
  [MeasurableBehavior.COMMUNICATION]: {
    label: 'Communication',
    description:
      'Communication involves conveying information clearly to others and facilitating the exchange of ideas and information. Think about how they share information and ideas with each other, enabling better outcomes for themselves and their teammates.',
    selfDescription:
      'Communication involves conveying information clearly to others and facilitating the exchange of ideas and information. Think about how you share information and ideas with others, and how you may enable better outcomes for you and your teammates.',
  },
  [MeasurableBehavior.COLLABORATION]: {
    label: 'Collaboration',
    description:
      "Collaboration includes working well with others to achieve a common purpose. Think about thow they show respect for others' contributions, are open to experimenting with others' ideas, and show sensitivity to how their actions may affect others.",
    selfDescription:
      "Collaboration includes working well with others to achieve a common purpose. Think about thow you show respect for others' contributions, are open to experimenting with others' ideas, and show sensitivity to how your actions may affect others.",
  },
  [MeasurableBehavior.OPENNESS]: {
    label: 'Openness',
    description:
      'Openness to feedback is the ability to receive and apply constructive information based on the knowledge, skills, abilities, and behaviors required for the role.  Think about how they react when people comment on their work, how they apply what they learn, and whether or not they continue to seek out feedback from others.',

    selfDescription:
      'Openness to feedback is the ability to receive and apply constructive information based on the knowledge, skills, abilities, and behaviors required for the role. Think about how you react when people comment on their work, how they apply what they learn, and whether or not you continue to seek out feedback from others.',
  },
  [MeasurableBehavior.PROBLEM_SOLVING]: {
    label: 'Problem Solving',
    description:
      "Problem solving is the ability to think on your feet and come up with creative solutions for both simple and complex problems. Think about how they react when things don't go their way, when they encounter unexpected obstacles, and how they get access to resources they need to be successful.",

    selfDescription:
      "Problem solving is the ability to think on your feet and come up with creative solutions for both simple and complex problems. Think about how you react when things don't go your way, when you encounter unexpected obstacles, and how you get access to resources you need to be successful.",
  },
  [MeasurableBehavior.IMPACT]: {
    label: 'Impact',
    description:
      "Impact measures the value of an individual's contributions to the organization. Consider the outcomes that the individual was able to generate, and the impact those outcomes had on the business and tracked metrics.",

    selfDescription:
      "Impact measures the value of an individual's contributions to the organization. Consider the outcomes that you are able to generate, and the impact those outcomes had on the business and tracked metrics.",
  },
  [MeasurableBehavior.PLANNING]: {
    label: 'Planning',
    description:
      "Planning and organization is the process of developing a clear, achievable strategy, and the successful execution of the strategy throughout a project's duration. Think about how they identify tasks and communicate goals, dedicate and assign resources, and how they prioritize tasks and monitor overall progress.",
    selfDescription:
      "Planning and organization is the process of developing a clear, achievable strategy, and the successful execution of the strategy throughout a project's duration. Think about how you identify tasks and communicate goals, dedicate and assign resources, and how you prioritize tasks and monitor overall progress.",
  },
  [MeasurableBehavior.PRODUCTIVITY]: {
    label: 'Productivity',
    description:
      'Productivity is characterized by the efficient completion of tasks towards achieving specific goals. Consider the quantity of work an individual is able to produce relative to what is expected of them, as well as their resource utilization.',

    selfDescription:
      'Productivity is characterized by the efficient completion of tasks towards achieving specific goals. Consider the quantity of work you are able to produce relative to what is expected of you, as well as your resource utilization.',
  },
  [MeasurableBehavior.OWNERSHIP]: {
    label: 'Ownership',
    description:
      'Ownership is the sense of accountability towards a business outcome. Think about how they respond when things go wrong, if they actively seek our opportunities for improvement, and the level of effort they put in to achieve their goals.',
    selfDescription:
      'Ownership is the sense of accountability towards a business outcome. Think about how you respond when things go wrong, if you actively seek our opportunities for improvement, and the level of effort you put in to achieve your goals.',
  },
  [MeasurableBehavior.RESPECT]: {
    label: 'Respect',
    description:
      "Respect is the attitude and behavior that someone shows towards others, and involves treating others with dignity, listening to others' perspectives, and valuing diversity. Think about ways your manager may make you feel included on your team, how they listen when you share your ideas, and how they react to your contributions and accomplishments.",
    selfDescription:
      "Respect is the attitude and behavior that someone shows towards others, and involves treating others with dignity, listening to others' perspectives, and valuing diversity. Think about ways you make others feel included, how well you listen when others share their ideas, and how you react to others' contribtions in the decision making process.",
  },
  [MeasurableBehavior.TRUST]: {
    label: 'Trust',
    description:
      'Trust is the result of an environment where others feel safe to bring their whole selves to work.  Think about how your manager reacts when you share new ideas, when you ask for help, and how they respond to mistakes.',
    selfDescription:
      'Trust is the result of an environment where others feel safe to bring their whole selves to work. Think about how you react when someone shares their new ideas, how you react when you are asked for help, and how you respond to mistakes.',
  },
  [MeasurableBehavior.CAREER_DEVELOPMENT]: {
    label: 'Career Development',
    description:
      'Career development involves enhancing your knowledge, skills, and abilities to achieve professional success and growth. Consider how your manager supports you in utilizing learning and development opportunities, acquiring new skills within your role, and helping you find a mentor or networking opportunities.',
    selfDescription:
      'Career development involves enhancing your knowledge, skills, and abilities to achieve professional success and growth. Consider how you support your team members in utilizing learning and development opportunities, acquiring new skills within their role, and helping them find a mentor or networking opportunities.',
  },
  [MeasurableBehavior.FAIRNESS]: {
    label: 'Fairness',
    description:
      'Fairness exists when expectations, opportunities, and resources are equally accessible to everyone. Think about whether or not your manager is objective when providing feedback, how they share information across the team, and how they make decisions.',
    selfDescription:
      'Fairness exists when expectations, opportunities, and resources are equally accessible to everyone. Think about whether or not you are objective when providing feedback, how you share information across the team, and how you make decisions.',
  },
  [MeasurableBehavior.FEEDBACK_VALUE]: { label: 'Value of Feedback' },
  [MeasurableBehavior.GROWTH_OUTLOOK]: { label: 'Growth Outlook' },
  [MeasurableBehavior.RECOGNITION]: { label: 'Recognition' },
  [MeasurableBehavior.DIVERSE_PERSPECTIVES]: { label: 'Diverse Perspectives' },
  [MeasurableBehavior.EMPOWERMENT]: { label: 'Empowerment' },
  [MeasurableBehavior.TRANSPARENCY]: { label: 'Transparency' },
};

export const MeasurableBehaviorLabels = mapValues(
  MeasurableBehaviourContext,
  (context) => context.label,
);

export enum Perspective {
  SELF = 'SELF',
  OTHER = 'OTHER',
}
export type QuestionToken = `q_${string}`;
export function isQuestionToken(token: string): token is QuestionToken {
  return !!token?.startsWith('q_');
}

export interface IQuestionText {
  text: string;
  perspective: Perspective;
  context?: string;
}
export type WordSentiment = 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL';
export interface IQuestionWord {
  word: string;
  sentiment: WordSentiment;
}
export interface IRankOption {
  token: `ro_${string}`;
  label: string;
  description: string;
}
export type RankingResponse = Record<IRankOption['token'], number>;
export const validateRanking = (
  rankingOptions: IRankOption[],
  ranking: RankingResponse,
) => {
  const missing = new Set<number>(times(rankingOptions.length, (n) => n + 1));
  const duplicates = new Set<IRankOption['token']>();
  const usedRanks = new Set<number>();
  for (const option of rankingOptions) {
    const currentRank = ranking[option.token];
    missing.delete(currentRank);
    if (usedRanks.has(currentRank)) {
      duplicates.add(option.token);
    } else if (currentRank) {
      usedRanks.add(currentRank);
    }
  }
  return { duplicates, missing };
};
export interface IRawQuestion {
  token: QuestionToken;
  type?: QuestionType;
  texts?: IQuestionText[];
  text?: string;
  scale?: RatingScale;
  measure?: MeasurableBehavior;
  words?: IQuestionWord[];
  rankingOptions?: IRankOption[];
  position?: number;
  required?: boolean;
  organizationToken?: OrganizationToken;
  correlationToken?: QuestionToken;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
}

export type IQuestion =
  | IOpenEndedQuestion
  | IRatingQuestion
  | IWordBasedQuestion
  | IYesNoQuestion
  | ILabelQuestion
  | IRankQuestion;

export interface IBaseQuestion {
  token: QuestionToken;
  type: QuestionType;
  text: string;
  position?: number;
  correlationToken?: QuestionToken;
  required?: boolean;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
}

export interface IOpenEndedQuestion extends IBaseQuestion {
  type: QuestionType.OPEN_ENDED;
  required?: boolean;
  measure?: MeasurableBehavior;
}

export interface IRankQuestion extends IBaseQuestion {
  type: QuestionType.RANK;
  rankingOptions?: IRankOption[];
  required?: boolean;
}

export interface ILabelQuestion extends IBaseQuestion {
  type: QuestionType.LABEL;
}

export function isQuestionLabel(
  question: IQuestion,
): question is ILabelQuestion {
  return question.type === QuestionType.LABEL;
}

export function isYesNoQuestion(
  question: IQuestion,
): question is IYesNoQuestion {
  return question.type === QuestionType.YES_NO;
}

export function isOpenEndedQuestion(
  question: IQuestion,
): question is IOpenEndedQuestion {
  return question.type === QuestionType.OPEN_ENDED;
}

export interface IRatingQuestion extends IBaseQuestion {
  type: QuestionType.RATING;
  required?: boolean;
  scale: RatingScale;
  // Whether to include the value as a prefix to the question. Default: true.
  includeValueInLabel?: boolean;
}

export function isRatingQuestion(
  question: IQuestion,
): question is IRatingQuestion {
  return question.type === QuestionType.RATING;
}

export function isRankQuestion(question: IQuestion): question is IRankQuestion {
  return question.type === QuestionType.RANK;
}

export interface IWordBasedQuestion extends IBaseQuestion {
  type: QuestionType.WORD_BASED;
  required?: boolean;
  measure?: MeasurableBehavior;
  words: IQuestionWord[];
}

export interface IYesNoQuestion extends IBaseQuestion {
  type: QuestionType.YES_NO;
  required?: boolean;
  scale: RatingScale;
}

export const MIN_WORDS_REQUIRED = 3;

export const feedbackRequestQuestions: IQuestion[] = [
  {
    token: 'q_feedback_request_strengths',
    type: QuestionType.OPEN_ENDED,
    text: 'What do you think are my strengths, and how can I build on those strengths to be even more effective?',
  },
  {
    token: 'q_feedback_request_role',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve and grow in my role?',
  },
  {
    token: 'q_feedback_request_team_support',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to be provide more support to my team?',
  },
  {
    token: 'q_feedback_request_communication',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my communication?',
    measure: MeasurableBehavior.COMMUNICATION,
  },
  {
    token: 'q_feedback_request_collaboration',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to be a better collaborator?',
    measure: MeasurableBehavior.COLLABORATION,
  },
  {
    token: 'q_feedback_request_openness',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to help me be more receptive to constructive feedback?',
    measure: MeasurableBehavior.OPENNESS,
  },
  {
    token: 'q_feedback_request_problem_solving',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my problem solving skills?',
    measure: MeasurableBehavior.PROBLEM_SOLVING,
  },
  {
    token: 'q_feedback_request_impact',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me so that I could make a bigger impact on the team?',
    measure: MeasurableBehavior.IMPACT,
  },
  {
    token: 'q_feedback_request_planning',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to improve my planning skills?',
    measure: MeasurableBehavior.PLANNING,
  },
  {
    token: 'q_feedback_request_productivity',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to help me be more productive?',
    measure: MeasurableBehavior.PRODUCTIVITY,
  },
  {
    token: 'q_feedback_request_ownership',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to take better ownership over my work?',
    measure: MeasurableBehavior.OWNERSHIP,
  },
  {
    token: 'q_feedback_request_respect',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to ensure that our workplace is inclusive and respectful for everyone?',
    measure: MeasurableBehavior.RESPECT,
  },
  {
    token: 'q_feedback_request_trust',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to increase the level of trust within the team?',
    measure: MeasurableBehavior.TRUST,
  },
  {
    token: 'q_feedback_request_career_development',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to create a culture of continuous learning and development within the team?',
    measure: MeasurableBehavior.CAREER_DEVELOPMENT,
  },
  {
    token: 'q_feedback_request_fairness',
    type: QuestionType.OPEN_ENDED,
    text: 'What advice would you give me to ensure that our team operates fairly and equitably?',
    measure: MeasurableBehavior.FAIRNESS,
  },
  {
    token: 'q_feedback_request_team_effectiveness',
    type: QuestionType.OPEN_ENDED,
    text: "What advice would you give me about how can I grow my team's effectiveness?",
  },
];

// Questions that we have to keep around
// since they have responses but that
// we no longer want inside a question list.
const DELETED_QUESTIONS: IQuestion[] = [
  {
    token: 'q_madebymary_2024_06_h1_examples',
    type: QuestionType.OPEN_ENDED,
    text: 'Share 1-2 specific examples that support your rating.',
    required: true,
  },
];

export type QuestionMap = Record<QuestionToken, IQuestion>;
const questionMap: QuestionMap = {};
export const addQustionsToMap = (questionsToAdd: IQuestion[]) => {
  for (const question of questionsToAdd) {
    const existingQuestion = questionMap[question.token];
    if (existingQuestion && !isEqual(question, existingQuestion)) {
      throw new Error(
        `Duplicate question does not match.\nExisting Question:\n${JSON.stringify(
          existingQuestion,
        )}\nNew Question:\n${JSON.stringify(question)}`,
      );
    }
    questionMap[question.token] = question;
  }
};
addQustionsToMap(
  dimensionFeedbackTemplates.flatMap((template) => template.feedbackQuestions),
);
addQustionsToMap(
  upwardFeedbackTemplates.flatMap((template) => template.feedbackQuestions),
);
addQustionsToMap(feedbackRequestQuestions);
addQustionsToMap(
  managerFeedbackTemplates.flatMap((template) => template.feedbackQuestions),
);
addQustionsToMap(
  selfFeedbackTemplates.flatMap((template) => template.feedbackQuestions),
);
addQustionsToMap(
  managerFeedbackRequestTemplates.flatMap(
    (template) => template.feedbackQuestions,
  ),
);
addQustionsToMap(
  selfFeedbackRequestTemplates.flatMap(
    (template) => template.feedbackQuestions,
  ),
);
addQustionsToMap(
  peerFeedbackRequestTemplates.flatMap(
    (template) => template.feedbackQuestions,
  ),
);
addQustionsToMap(DELETED_QUESTIONS);
addQustionsToMap(reflectionTemplates.flatMap((template) => template.questions));

export const getQuestion = (questionToken?: QuestionToken) => {
  if (!questionToken) {
    return undefined;
  }

  return questionMap[questionToken];
};

export const getNextQuestionToken = (
  questionTokens: QuestionToken[],
  previousQuestionToken?: QuestionToken,
) => {
  const previousQuestionIndex = previousQuestionToken
    ? questionTokens.indexOf(previousQuestionToken)
    : -1;

  if (previousQuestionIndex === -1) {
    return sample(questionTokens);
  } else if (previousQuestionIndex + 1 === questionTokens.length) {
    return questionTokens[0];
  } else {
    return questionTokens[previousQuestionIndex + 1];
  }
};

export const impactStrengthQuestion: IQuestion = {
  token: 'q_impact_strength',
  text: 'What did they do well and should continue doing?',
  type: QuestionType.OPEN_ENDED,
};

export const impactOpportunityQuestion: IQuestion = {
  token: 'q_impact_opportunity',
  text: 'What could they do differently in the future to have more impact?',
  type: QuestionType.OPEN_ENDED,
};

addQustionsToMap([impactStrengthQuestion, impactOpportunityQuestion]);

export const performanceAccomplishmentsQuestion: IQuestion = {
  token: 'q_performance_accomplishments',
  text: 'What are the top 1-2 things that you accomplished that you believe highlight performance in your role?',
  type: QuestionType.OPEN_ENDED,
};
export const performanceStrengthsQuestion: IQuestion = {
  token: 'q_performance_strengths',
  text: 'What are 2-3 strengths that you demonstrated?',
  type: QuestionType.OPEN_ENDED,
};
addQustionsToMap([
  performanceAccomplishmentsQuestion,
  performanceStrengthsQuestion,
]);
