import { OrganizationToken, UserMapItem, UserToken } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { primaryColor } from '@web/app/styles/ColorStyles';
import { Select } from 'antd';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { useSelectUserSearch } from './useSelectUserSearch';

interface UserOption {
  label: string;
  value: UserToken;
}

interface SelectUsersProps {
  disabled?: boolean;
  initialUsers?: UserMapItem[];
  onBeforeChange?: (newValue: UserToken[]) => Promise<boolean>;
  onChange?: (newValue: UserToken[]) => void;
  organizationToken?: OrganizationToken;
  style?: React.CSSProperties;
  hideUsers?: UserToken[];
  placeholder?: string;
  suggestions?: UserMapItem[];
}

const createUserOptions = (users: UserMapItem[]) => {
  return (
    users?.map((user) => ({
      value: user.token,
      label: user.name,
    })) ?? []
  );
};

export const SelectUsers: React.FC<SelectUsersProps> = ({
  disabled,
  onBeforeChange,
  onChange,
  organizationToken,
  style,
  hideUsers = [],
  placeholder,
  initialUsers = [],
  suggestions,
}) => {
  const { isMobile } = useResponsive();
  const [selectedUserOptions, setSelectedUserOptions] = React.useState<
    UserOption[]
  >(createUserOptions(initialUsers));
  const selectedUserTokens = selectedUserOptions.map((option) => option.value);
  const hiddenUsersSet = new Set<UserToken>([
    ...hideUsers,
    ...selectedUserTokens,
  ]);
  const { users, search, clearSearch } = useSelectUserSearch({
    organizationToken,
    omitUserTokens: Array.from(hiddenUsersSet),
  });
  const userOptions = users.map((user) => ({
    value: user.token,
    label: user.name,
  }));
  const visibleSuggestions = suggestions
    ?.filter((user) => !hiddenUsersSet.has(user.token))
    .slice(0, isMobile ? 3 : 5);

  const handleSelectChange = async (userOptions: UserOption[]) => {
    const newUserTokens = userOptions.map((option) => option.value);
    if (onBeforeChange) {
      const canChange = await onBeforeChange(newUserTokens);
      if (!canChange) {
        return;
      }
    }

    clearSearch();
    setSelectedUserOptions(userOptions);
    onChange?.(newUserTokens);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      clearSearch();
    }
  };

  const handleSuggestionClick = (suggestionOption: UserOption) => {
    if (disabled) {
      return;
    }

    const newOptions = [...selectedUserOptions, suggestionOption];
    setSelectedUserOptions(newOptions);
    void handleSelectChange(newOptions);
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        showSearch
        mode="multiple"
        disabled={disabled}
        notFoundContent={null}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        value={selectedUserOptions}
        labelInValue
        onSearch={search}
        style={style}
        options={userOptions}
        filterOption={false}
        onChange={handleSelectChange}
        choiceTransitionName=""
        transitionName=""
        animation=""
        defaultActiveFirstOption={false}
      />
      {visibleSuggestions?.length > 0 && (
        <Suggestions>
          {visibleSuggestions.map((collaborator) => (
            <Suggestion
              onClick={() => {
                handleSuggestionClick({
                  value: collaborator.token,
                  label: collaborator.name,
                });
              }}
              key={collaborator.token}
              disabled={disabled}
            >
              {collaborator.name}
            </Suggestion>
          ))}
        </Suggestions>
      )}
    </>
  );
};

const Suggestions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`;

const Suggestion = styled.div<{ disabled?: boolean }>`
  color: var(--primary-color);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;

  ${(props) =>
    props.disabled
      ? css`
          color: #aaa;
        `
      : css`
          &:hover {
            color: ${primaryColor.darken(0.4)};
          }
        `}
`;
