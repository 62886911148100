import { EditOutlined } from '@ant-design/icons';
import { useGetUserManagerReview } from '@client/ReviewCyclesClient';
import { findReflectionQuestionSet } from '@shared/reflections';
import { ReviewCycleToken } from '@shared/review-cycles';
import { UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { CollapsableSection } from '@web/comments/CollapsableSection';
import { EditedLabel } from '@web/components/EditedLabel';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, SubduedText } from '@web/components/typography';
import { ReactionSummaryContext } from '@web/reactions/ReactionSummaryContext';
import { ReflectionResponseList } from '@web/reflections/ReflectionResponseList';
import { ReflectionSharedDate } from '@web/reflections/ReflectionSharedDate';
import { pageDescription, pageTitle } from '@web/reflections/reflections';
import { Skeleton } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export const ViewReviewPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const { reviewCycleToken, userToken } = useParams<{
    reviewCycleToken: ReviewCycleToken;
    userToken: UserToken;
  }>();

  const { data: response, error } = useGetUserManagerReview(
    reviewCycleToken,
    userToken,
  );

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.review) {
    return (
      <PageContent>
        <PageHeader />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  const reflection = response.review;

  const isAuthor = reflection.authorToken === user.token;
  const isManager = isManagerOf(reflection.receiver, user.token);
  const isReceiver = reflection.receiver.token === user.token;
  const isSelfReview = reflection.receiverToken === reflection.authorToken;

  const templateToken = reflection.questionSetToken ?? 'rqs_manager';
  const questions = findReflectionQuestionSet(templateToken).questions;

  return (
    <ReactionSummaryContext.Provider value={response.reactions}>
      <PageContent>
        <PageHeader
          title={pageTitle(user, reflection)}
          mobileTitle={pageTitle(user, reflection)}
          description={pageDescription(reflection)}
          navigateBack
          defaultNavigateBackTo={
            reflection.reviewCycleToken
              ? `/cycles/${reflection.reviewCycleToken}`
              : '/reflections'
          }
          primaryAction={
            reflection.canEdit && reflection.author.token === user.token
              ? {
                  label: 'Edit',
                  icon: <EditOutlined />,
                  linkTo: `/cycles/${reviewCycleToken}/users/${userToken}/reflection/edit`,
                }
              : undefined
          }
        />
        <Column gap={12}>
          {!isMobile && (
            <Header2 style={{ marginTop: 12 }}>
              <EditedLabel
                entity={{
                  createdDate:
                    reflection.sharedDate ?? reflection.completedDate,
                  updatedDate: reflection.updatedDate,
                }}
              />
            </Header2>
          )}
          <Pane>
            <Section>
              <Row style={{ alignItems: 'flex-start' }}>
                <Column gap={24}>
                  {!isSelfReview && (
                    <UserMessage user={reflection.author} large />
                  )}
                  <ReflectionResponseList
                    questions={questions}
                    responses={reflection.responses}
                    receiverName={
                      isSelfReview ? undefined : reflection.receiver?.name
                    }
                    allowReply={isReceiver || isManager}
                  />
                </Column>
                <GrowingSpacer />
                {!isMobile && <ReflectionSharedDate reflection={reflection} />}
              </Row>
            </Section>
            {isAuthor && reflection.includePrivateNote ? (
              <Section>
                <CollapsableSection title="Private Notes" startExpanded>
                  {reflection.privateNote ? (
                    <Markdown value={reflection.privateNote} />
                  ) : (
                    <SubduedText>No private notes</SubduedText>
                  )}
                </CollapsableSection>
              </Section>
            ) : undefined}
          </Pane>
        </Column>
      </PageContent>
    </ReactionSummaryContext.Provider>
  );
};

const Section = styled(Column)`
  margin-bottom: 8px;
`;
