import { findTemplateByToken } from '@shared/feedbackTemplates';
import { IFeedback, UserMapItem } from '@shared/types';
import { Tag } from 'antd';
import * as React from 'react';

import { QuestionResponses } from '../../questions/QuestionResponses';
import { UserFeedback } from '../FeedbackList/UserFeedback';

export const ViewManagerFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
}> = ({ feedback, giver, receiver }) => {
  const template = findTemplateByToken(feedback.templateToken);
  if (!template) {
    return null;
  }

  return (
    <UserFeedback
      giver={giver}
      receiver={receiver}
      date={feedback.updatedDate}
      tag={<Tag color="var(--primary-color)">Manager Feedback</Tag>}
    >
      <QuestionResponses
        questions={template.feedbackQuestions}
        responses={feedback.responses}
        receiverName={receiver.name}
      />
    </UserFeedback>
  );
};
