import { IPerformanceSummary } from '@shared/performance';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { filter } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const SummariesTable: React.FC<{ summaries: IPerformanceSummary[] }> = ({
  summaries,
}) => {
  const navigate = useNavigate();

  const columns: ColumnsType<IPerformanceSummary> = filter([
    {
      title: 'Description',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render(user) {
        return (
          <Row gap={6}>
            <UserAvatar user={user} size={20} />
            <Text>{user.name}</Text>
          </Row>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
  ]);

  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={summaries}
      onRow={(row) => ({
        onClick: () => {
          navigate(row.token);
        },
        style: {
          cursor: 'pointer',
        },
      })}
    />
  );
};
