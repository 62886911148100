import { sortBy } from 'lodash';

import { IComment } from './comments';
import { IChatCompletion } from './completions';
import { IGithubPull } from './github';
import { IGoal } from './goals';
import { IQuestionResponse } from './question_response';
import { EntityReactionSummaryMap, IReaction } from './reactions';
import {
  ContentType,
  IEntity,
  IFeedback,
  IFeedbackResponse,
  ISearchResults,
  ITask,
  IUser,
  OrganizationToken,
  UserMap,
  UserMapItem,
  UserToken,
} from './types';

export type ImpactToken = `i_${string}`;

export interface IImpact extends IEntity {
  token: ImpactToken;
  organizationToken: OrganizationToken;
  title: string;
  description: string;
  descriptionMarkdown?: string;
  contentType: ContentType;
  date: Date;
  userToken: UserToken;
  user: IUser;
  collaborators: IUser[];
  /** @deprecated */
  relatedGoals?: IGoal[];
  suggestedEntryToken?: string;
  chatCompletionToken?: string;
  isPublic: boolean;
}

export interface IImpactFeedbackResponse extends IFeedbackResponse {
  impact: IImpact;
}

export interface GetImpactFeedbackResponse {
  feedback: IFeedback;
}

export enum ImpactActivityType {
  NEW_ENTRY = 'NEW_ENTRY',
  NEW_FEEDBACK = 'NEW_FEEDBACK',
  NEW_COMMENT = 'NEW_COMMENT',
}

export interface IImpactActivity {
  entityToken: string;
  feedback?: IFeedback;
  comment?: IComment;
  impactToken: string;
  type: ImpactActivityType;
  date: Date | string;
}

export interface IImpactActivityResponse {
  activities: IImpactActivity[];
  impactEntryMap: Record<string, IImpact>;
  userMap: UserMap;
  reactionsMap: Record<string, IReaction[]>;
}

export type ImpactEntryMap = Record<string, IImpact>;

export interface IFullImpactResponse {
  impactEntry: IImpact;
  comments: ISearchResults<IComment>;
  feedbackResponse: IFeedbackResponse;
  reactionSummaryMap: EntityReactionSummaryMap;
  reactionsUserMap: UserMap;
  assignedTask?: ITask;
  manager?: UserMapItem;
  permissions: {
    canViewComments: boolean;
    canComment: boolean;
    canViewFeedback: boolean;
    canGiveFeedback: boolean;
    canReact: boolean;
  };
}

export interface ISearchImpactsResults {
  impacts: IImpact[];
  total: number;
  userMap?: UserMap;
  reactionSummaryMap?: EntityReactionSummaryMap;
}

export interface IImpactTasksResponse {
  tasks: ITask[];
  reactionSummaryMap: EntityReactionSummaryMap;
  reactionsUserMap: UserMap;
}

export interface IManagerImpactSummary {
  fromDate: Date;
  toDate: Date;
  metrics: IManagerImpactSummaryMetrics[];
  overflowMetrics: IManagerImpactSummaryOverflowMetrics;
}

export interface IManagerImpactSummaryOverflowMetrics {
  // overflowCount is the number of impact entries excluded
  // from the manager summary.
  overflowCount: number;

  // overflowUsers is the users who own entries excluded
  // from the manager summary.
  overflowUsers: IUser[];
}

export interface IManagerImpactSummaryMetrics {
  impact: IImpact;
  newImpactEntry: boolean;
  newFeedbackCount: number;
  newCommentCount: number;
}

export enum SuggestedEntryType {
  /** Entry was requested by a user from their organization */
  USER = 'USER',
  /** Entry was generated by flint when a manager first onboards, suggestor will be undefined */
  MANAGER_TEAM_ENTRY = 'MANAGER_TEAM_ENTRY',
  GITHUB_PULL_REQUEST = 'GITHUB_PULL_REQUEST',
}

export enum SuggestedEntryState {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
}

export type SuggestedEntryToken = `se_${string}`;

export interface ISuggestedEntry extends IEntity {
  token: SuggestedEntryToken;
  organizationToken?: OrganizationToken;
  title?: string;
  date?: Date;
  type: SuggestedEntryType;
  state: SuggestedEntryState;
  description?: string;
  githubPullRequestUrl?: string;
  user: IUser;
  suggestor?: IUser;
  collaborators: IUser[];
  githubPulls: IGithubPull[];
  chatCompletion?: IChatCompletion;
}

export interface ISuggestEntryRequest {
  suggestedEntry: Partial<ISuggestedEntry>;
  userToken: UserToken;
  collaboratorTokens: UserToken[];
}

export const entryLink = ({
  isManager,
  isAuthor,
  entry,
}: {
  isManager: boolean;
  isAuthor: boolean;
  entry: IImpact;
}) => {
  if (isManager) {
    return `/team/${entry.userToken}/journal/${entry.token}`;
  }
  if (isAuthor) {
    return `/journal/${entry.token}`;
  }
  return `/requests/journal/${entry.token}`;
};

const questionOrder = {
  q_impact_strength: 1,
  q_impact_opportunity: 2,
};

export const sortResponses = (responses: IQuestionResponse[]) =>
  sortBy(responses, (response) => {
    if (!response.questionToken) {
      throw new Error('Response missing questionToken');
    }

    return questionOrder[response.questionToken];
  });
