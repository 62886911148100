import { useFindJournalEntries } from '@client/ImpactsClient';
import { formatDate } from '@shared/formatDate';
import { IImpact } from '@shared/impacts';
import { IUser } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text, TruncateText } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Widget, WidgetDate, WidgetRow } from './Widget';

export const JournalWidget: React.FC<{
  user: IUser;
  title?: string;
  extra?: React.ReactNode;
  limit?: number;
}> = ({ user, extra, limit = 3, title = 'Journal Entries' }) => {
  const { data } = useFindJournalEntries(0, limit, user.token);
  const { user: currentUser } = useAuth();
  const isReceiver = currentUser.token === user.token;

  return (
    <Column>
      <Link to={isReceiver ? '/journal' : `/team/${user.token}/journal`}>
        <Row gap={12}>
          <Header2>{title}</Header2>
          {extra}
        </Row>
      </Link>
      <Spacer size={6} />
      {!data?.impacts ? (
        <Skeleton />
      ) : data?.impacts.length > 0 ? (
        <Widget>
          {data.impacts.map((impact, index) => (
            <JournalEntryWidgetRow
              hideBorder={index === 0}
              key={impact.token}
              impact={impact}
            />
          ))}
        </Widget>
      ) : (
        <Text>No journal entries</Text>
      )}
    </Column>
  );
};
const JournalEntryWidgetRow: React.FC<{
  impact: IImpact;
  hideBorder: boolean;
}> = ({ impact, hideBorder }) => {
  const { user } = useAuth();
  const isReceiver = user.token === impact.userToken;

  return (
    <Link
      to={
        isReceiver
          ? `/journal/${impact.token}`
          : `/team/${impact.userToken}/journal/${impact.token}`
      }
    >
      <WidgetRow hideBorder={hideBorder}>
        <TruncateText>{impact.title}</TruncateText>
        <WidgetDate>{formatDate(impact.date)}</WidgetDate>
      </WidgetRow>
    </Link>
  );
};
