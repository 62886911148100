import { Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

interface Props {
  onCancel: () => void;
  onSave: (text: string) => void;
  initialText: string;
  title: string;
}

export const EditTextModal: React.FC<Props> = ({
  initialText,
  onSave,
  onCancel,
  title,
}) => {
  const [text, setText] = React.useState(initialText);

  return (
    <Modal
      title={title}
      open={true}
      onOk={() => {
        onSave(text);
      }}
      afterClose={onCancel}
      onCancel={onCancel}
      width="800px"
      okText="Update"
    >
      <TextArea
        value={text}
        onChange={(e) => {
          setText(e.currentTarget.value);
        }}
        style={{ height: 200, maxHeight: '80vh' }}
        autoSize
      />
    </Modal>
  );
};
