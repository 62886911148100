import { UserRole } from '@shared/types';
import { AdminEditTeamPage } from '@web/admin/AdminEditTeamPage';
import AdminGithubPage from '@web/admin/AdminGithubPage';
import { AdminReflectionsPage } from '@web/admin/AdminReflectionsPage';
import AdminSlackPage from '@web/admin/AdminSlackPage';
import AdminTeamsPage from '@web/admin/AdminTeamsPage';
import AdminUsersPage from '@web/admin/AdminUsersPage';
import AdminAlignmentPage from '@web/admin/alignment/AdminAlignmentPage';
import { RolesRouteGuard } from '@web/auth/RolesRouteGuard';
import { AdminMessagesPage } from '@web/messages/AdminMessagesPage';
import { AdminEditReviewCyclePage } from '@web/review-cycles/AdminEditReviewCyclePage';
import { AdminEditCycleBatchNotificationsPage } from '@web/review-cycles/AdminReviewCycleBatchNotificationsPage';
import AdminReviewCycleDashboardPage from '@web/review-cycles/AdminReviewCycleDashboardPage';
import { AdminEditCycleNotificationsPage } from '@web/review-cycles/AdminReviewCycleNotificationsPage';
import AdminReviewCyclesPage from '@web/review-cycles/AdminReviewCyclesPage';
import { AdminSurveyCycleParticipantsPage } from '@web/surveys/cycles/AdminSurveyCycleParticipantsPage';
import { AdminSurveyCycleQuestionsPage } from '@web/surveys/cycles/AdminSurveyCycleQuestionsPage';
import AdminSurveyCycleResponsesPage from '@web/surveys/cycles/AdminSurveyCycleResponsesPage';
import AdminSurveyCycleSettingsPage from '@web/surveys/cycles/AdminSurveyCycleSettingsPage';
import { AdminSurveyCycleViewResponsesPage } from '@web/surveys/cycles/AdminSurveyCycleViewResponsesPage';
import AdminSurveyCyclesPage from '@web/surveys/cycles/AdminSurveyCyclesPage';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Redirect } from './Redirect';

export const adminRoutes = () => (
  <Route
    path="admin"
    element={
      <RolesRouteGuard
        roles={[UserRole.SUPER, UserRole.ADMIN, UserRole.HR_ADMIN]}
      />
    }
  >
    <Route index element={<Navigate to="/admin/teams" />} />
    <Route path="users">
      <Route index element={<AdminUsersPage />} />
    </Route>
    <Route path="alignment">
      <Route index element={<AdminAlignmentPage />} />
    </Route>
    <Route path="slack">
      <Route index element={<AdminSlackPage />} />
    </Route>
    <Route path="github">
      <Route index element={<AdminGithubPage />} />
    </Route>
    <Route path="teams">
      <Route index element={<AdminTeamsPage />} />
      <Route path="new" element={<AdminEditTeamPage />} />
      <Route path=":teamToken" element={<AdminEditTeamPage />} />
    </Route>
    <Route path="review-cycles">
      <Route index element={<AdminReviewCyclesPage />} />
      <Route path="new" element={<AdminEditReviewCyclePage />} />
      <Route path=":reviewCycleToken" element={<AdminEditReviewCyclePage />} />
      <Route
        path=":reviewCycleToken/dashboard"
        element={<Redirect to={() => 'participants'} />}
      />
      <Route
        path=":reviewCycleToken/dashboard/participants"
        element={<AdminReviewCycleDashboardPage />}
      />
      <Route
        path=":reviewCycleToken/dashboard/teams"
        element={<AdminReviewCycleDashboardPage />}
      />
      <Route
        path=":reviewCycleToken/batch-notifications"
        element={<AdminEditCycleBatchNotificationsPage />}
      />
      <Route
        path=":reviewCycleToken/notifications"
        element={<AdminEditCycleNotificationsPage />}
      />
    </Route>
    <Route path="reflections">
      <Route index element={<AdminReflectionsPage />} />
    </Route>
    <Route path="messages">
      <Route index element={<AdminMessagesPage />} />
    </Route>
    <Route path="surveys">
      <Route index element={<AdminSurveyCyclesPage />} />
      <Route
        path=":surveyCycleToken"
        element={
          <Redirect
            to={({ surveyCycleToken }) =>
              `/admin/surveys/${surveyCycleToken}/settings`
            }
          />
        }
      />
      <Route
        path=":surveyCycleToken/settings"
        element={<AdminSurveyCycleSettingsPage />}
      />
      <Route
        path=":surveyCycleToken/participants"
        element={<AdminSurveyCycleParticipantsPage />}
      />
      <Route
        path=":surveyCycleToken/participants/:surveyParticipantToken"
        element={<AdminSurveyCycleViewResponsesPage />}
      />
      <Route
        path=":surveyCycleToken/questions"
        element={<AdminSurveyCycleQuestionsPage />}
      />
      <Route
        path=":surveyCycleToken/responses"
        element={<AdminSurveyCycleResponsesPage />}
      />
    </Route>
  </Route>
);
