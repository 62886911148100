import {
  completeActionItem,
  createActionItem,
  sortActionItems,
  uncompleteActionItem,
  useActionItems,
} from '@client/OneOnOnesClient';
import { mapByToken } from '@shared/mapByToken';
import {
  ActionItemToken,
  IActionItem,
  OneOnOneToken,
} from '@shared/one-on-one';
import { UserMapItem } from '@shared/types';
import { WebSocketEventType } from '@shared/webSocketEvents';
import * as React from 'react';

import { useWebSocketEvent } from '../common/useWebSocketEvent';
import { ActionItemDrawer } from './ActionItemDrawer';
import { ITaskItem, TaskList } from './items/TaskList';

export const ActionItemsList: React.FC<{
  oneOnOneToken: OneOnOneToken;
  otherUser: UserMapItem;
}> = ({ oneOnOneToken, otherUser }) => {
  const { selectedActionItem, reloadData, ...service } =
    useActionTaskItems(oneOnOneToken);
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.ACTION_ITEMS_UPDATED,
    () => {
      void reloadData();
    },
  );
  const handleClose = () => {
    service.select(null);
  };

  return (
    <>
      <TaskList title="Actions" taskService={service} otherUser={otherUser} />
      {selectedActionItem && (
        <ActionItemDrawer
          actionItem={selectedActionItem}
          onClose={handleClose}
          oneOnOneToken={oneOnOneToken}
          otherUser={otherUser}
        />
      )}
    </>
  );
};

const useActionTaskItems = (oneOnOneToken: OneOnOneToken) => {
  const { data: sortedActionItems, mutate: reloadData } =
    useActionItems(oneOnOneToken);
  const [selectedActionItemToken, setSelectedActionItemToken] =
    React.useState<ActionItemToken>(null);

  const add = async (text: string): Promise<ITaskItem<ActionItemToken>> => {
    const actionItem = await createActionItem(oneOnOneToken, text);
    await reloadData();
    return actionToTask(actionItem);
  };

  const resolve = async (
    updatedItem: ITaskItem<ActionItemToken>,
    resolved: boolean,
  ): Promise<void> => {
    if (resolved) {
      await completeActionItem(oneOnOneToken, updatedItem.id);
    } else {
      await uncompleteActionItem(oneOnOneToken, updatedItem.id);
    }
    await reloadData();
  };

  const sort = async (
    newlySortedItems: Array<ITaskItem<ActionItemToken>>,
  ): Promise<void> => {
    await sortActionItems(
      oneOnOneToken,
      newlySortedItems.map((item) => item.id),
    );
    await reloadData();
  };

  const select = (taskItemId: ActionItemToken = null) => {
    setSelectedActionItemToken(taskItemId);
  };

  const actionItemMap = mapByToken(sortedActionItems);
  const selectedActionItem = selectedActionItemToken
    ? actionItemMap.get(selectedActionItemToken)
    : undefined;

  const taskItems: Array<ITaskItem<ActionItemToken>> = sortedActionItems
    ? sortedActionItems.map(actionToTask)
    : undefined;

  return {
    add,
    resolve,
    sort,
    taskItems,
    select,
    selectedActionItem,
    reloadData,
  };
};

const actionToTask = (actionItem: IActionItem): ITaskItem<ActionItemToken> => ({
  id: actionItem.token,
  resolved: !!actionItem.completedDate,
  text: actionItem.text,
});
