import { DeleteOutlined } from '@ant-design/icons';
import { ISurveyCycle, ISurveyParticipant } from '@shared/surveys';
import { useAuth } from '@web/auth/useAuth';
import { del, post } from '@web/common/api';
import { IS_PRODUCTION } from '@web/common/const';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, message } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const DashboardRowActionsMenu: React.FC<{
  participant: ISurveyParticipant;
  onChange: () => void;
  surveyCycle: ISurveyCycle;
}> = ({ participant, onChange, surveyCycle }) => {
  const { isImpersonating } = useAuth();

  const handleRemove = async () => {
    try {
      await del(
        `/survey-cycles/${surveyCycle.token}/participants/${participant.token}`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleMockResponses = async () => {
    try {
      await post(
        `/survey-cycles/${surveyCycle.token}/participants/${participant.token}/mockResponses`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const hasResponses =
    participant.responses && participant.responses.length > 0;
  const canMockResponses =
    isImpersonating &&
    !IS_PRODUCTION &&
    !!surveyCycle.startedDate &&
    !hasResponses;
  const canRemoveParticipants = !surveyCycle.startedDate;
  if (!canMockResponses && !canRemoveParticipants && !hasResponses) {
    return <Text>N/A</Text>;
  }

  return (
    <Row gap={6} style={{ justifyContent: 'center' }}>
      {hasResponses && (
        <Link to={participant.token}>
          <Button>View Responses</Button>
        </Link>
      )}
      {canMockResponses && (
        <ConfirmButton
          onConfirm={handleMockResponses}
          description={`Are you sure you want to mock responses?`}
        >
          Mock Responses
        </ConfirmButton>
      )}
      {canRemoveParticipants && (
        <ConfirmButton
          onConfirm={handleRemove}
          description={`Are you sure you wish to remove ${participant.user.name} from this
        survey?`}
        >
          <DeleteOutlined />
        </ConfirmButton>
      )}
    </Row>
  );
};
