"use strict";
"use client";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _progress = _interopRequireDefault(require("./progress"));
var _default = _progress.default;
exports.default = _default;