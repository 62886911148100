import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useOverviewAnalytics } from '@client/AnalyticsClient';
import {
  ITeamMetrics,
  METRIC_COLORS,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  MetricTypeDescriptions,
  MetricTypeLabels,
} from '@shared/analytics';
import { TeamToken } from '@shared/types';
import { IF_MOBILE } from '@web/app/responsive';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Empty, Skeleton, Tooltip } from 'antd';
import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import { TitleTooltipIcon } from './MetricColumnTitle';
import { LineGraphMetric } from './visualizations/LineGraphMetric';
import { PieChartMetric } from './visualizations/PieChartMetric';
import { CircleProgressMetric } from './visualizations/ProgressMetric';

export const MetricsOverview: React.FC<{
  metricTypes: MetricType[];
  teamToken?: TeamToken;
}> = ({ metricTypes: visibleMetrics, teamToken }) => {
  const { data: overviewMetrics } = useOverviewAnalytics(teamToken);

  if (!overviewMetrics) {
    return <Skeleton />;
  }

  if (overviewMetrics.userCount === 0) {
    return <Empty description="There are no employees on this team" />;
  }

  return (
    <Column gap={12}>
      <Text>
        Showing metrics for {overviewMetrics.userCount}{' '}
        {pluralize('employee', overviewMetrics.userCount)}
      </Text>
      <MetricsContainer>
        {visibleMetrics.map((metricType) => (
          <MetricContainer key={metricType}>
            <MetricText>
              {MetricTypeLabels[metricType]}{' '}
              <Tooltip title={MetricTypeDescriptions[metricType]}>
                <TitleTooltipIcon />
              </Tooltip>
            </MetricText>
            <Spacer size={18} />
            <VisualizationContainer>
              <ViewMetric type={metricType} metrics={overviewMetrics} />
            </VisualizationContainer>
            <Spacer size={18} />
          </MetricContainer>
        ))}
      </MetricsContainer>
    </Column>
  );
};

const MetricText = styled(Text)`
  line-height: 14px;
`;

const MetricsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 968px;

  ${IF_MOBILE} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MetricContainer = styled(Column)`
  border: 1px solid var(--border-color);
  border-radius: var(--default-border-radius);
  padding: 12px 14px;
`;

const VisualizationContainer = styled(Column)`
  align-items: center;
  justify-content: center;
`;

const ViewMetric: React.FC<{ type: MetricType; metrics: ITeamMetrics }> = ({
  type,
  metrics,
}) => {
  switch (type) {
    case MetricType.GOALS:
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
          ]}
        />
      );
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return (
        <CircleProgressMetric
          value={metrics.oneOnOnesUsersWithCompleted}
          total={metrics.userCount}
          color={METRIC_COLORS[4]}
          suffix={<UserCountIcon count={metrics.userCount} />}
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return (
        <CircleProgressMetric
          value={metrics.journalUsersWithEntries}
          total={metrics.userCount}
          color={METRIC_COLORS[2]}
          suffix={<UserCountIcon count={metrics.userCount} />}
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      return <LineGraphMetric labeledMetrics={metrics.journalWeeklyUsage} />;
    case MetricType.ONE_ON_ONES_ALIGNMENT:
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.oneOnOnesAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.oneOnOnesAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.oneOnOnesAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
        />
      );

    case MetricType.JOURNAL_ALIGNMENT:
      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.journalAlignmentOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.journalAlignmentAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.journalAlignmentOffTrack,
              color: METRIC_RED,
            },
          ]}
        />
      );
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <CircleProgressMetric
          value={metrics.managerOneOnOnesScheduled}
          total={metrics.userCount}
          color={METRIC_COLORS[2]}
          suffix={<UserCountIcon count={metrics.userCount} />}
        />
      );
    default:
      return null;
  }
};

const UserCountIcon: React.FC<{ count: number }> = ({ count }) =>
  count === 1 ? (
    <UserOutlined style={{ fontSize: 16 }} />
  ) : (
    <TeamOutlined style={{ fontSize: 16 }} />
  );
