import { useApi } from '@web/common/useApi';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { Empty, Skeleton, Typography } from 'antd';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import * as React from 'react';

export const FeatureUtilization: React.FC<{ userToken: string }> = ({
  userToken,
}) => {
  const { data: chartOptions } = useApi<EChartsOption>(
    `/charts/user-feature-utilization?user=${userToken}`,
  );

  return (
    <Column>
      <Typography.Title level={5} style={{ fontWeight: 400 }}>
        Feature Utitlization
      </Typography.Title>
      <Pane>
        {chartOptions ? (
          chartOptions.series ? (
            <ReactECharts option={chartOptions} />
          ) : (
            <Empty />
          )
        ) : (
          <Skeleton />
        )}
      </Pane>
    </Column>
  );
};
