import React__default from "react";
import { ButtonWithTooltip } from "../primitives/toolbar.js";
import { linkDialogPluginHooks } from "../../link-dialog/index.js";
const CreateLink = () => {
  const openLinkDialog = linkDialogPluginHooks.usePublisher("openLinkEditDialog");
  const [iconComponentFor] = linkDialogPluginHooks.useEmitterValues("iconComponentFor");
  return /* @__PURE__ */ React__default.createElement(
    ButtonWithTooltip,
    {
      title: "Create link",
      onClick: (_) => {
        openLinkDialog(true);
      }
    },
    iconComponentFor("link")
  );
};
export {
  CreateLink
};
