import {
  FeedbackTemplateToken,
  IFeedbackTemplate,
  managerFeedbackTemplates,
  selfFeedbackTemplates,
} from '@shared/feedbackTemplates';
import {
  IFeedbackConfig,
  IOrganization,
  OrganizationToken,
} from '@shared/types';
import { PageContent } from '@web/app/Page';
import { put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Checkbox, Select, Skeleton, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

export const FeedbackSettingsPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: organization, mutate: refreshOrganization } =
    useApi<IOrganization>(`/organizations/${organizationToken}`);

  return (
    <PageContent>
      <PageHeader title="Feedback Settings" />
      <Pane>
        {organization ? (
          <FeedbackSettings
            organizationToken={organizationToken}
            feedbackConfig={organization.feedbackConfig}
            onChange={refreshOrganization}
          />
        ) : (
          <Skeleton />
        )}
      </Pane>
    </PageContent>
  );
};

export const FeedbackSettings: React.FC<{
  organizationToken: OrganizationToken;
  feedbackConfig?: IFeedbackConfig;
  onChange: () => void;
}> = ({ organizationToken, feedbackConfig, onChange }) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const [managerFeedbackEnabled, setManagerFeedbackEnabled] = React.useState(
    feedbackConfig?.managerFeedbackEnabled ?? false,
  );
  const [selfFeedbackEnabled, setSelfFeedbackEnabled] = React.useState(
    feedbackConfig?.selfFeedbackEnabled ?? false,
  );
  const [managerFeedbackTemplateToken, setManagerFeedbackTemplateToken] =
    React.useState(
      feedbackConfig?.managerFeedbackTemplateToken ??
        managerFeedbackTemplates[0].token,
    );
  const [selfFeedbackTemplateToken, setSelfFeedbackTemplateToken] =
    React.useState(
      feedbackConfig?.selfFeedbackTemplateToken ??
        selfFeedbackTemplates[0].token,
    );

  const handleUpdate = async () => {
    setIsSaving(true);
    try {
      await put<IFeedbackConfig>(
        `/organizations/${organizationToken}/feedback`,
        {
          managerFeedbackEnabled,
          selfFeedbackEnabled,
          managerFeedbackTemplateToken,
          selfFeedbackTemplateToken,
        },
      );
      void message.success('Successfully updated feedback settings');
      onChange();
    } catch (error) {
      void message.error('Failed to update feedback settings');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Column gap={12}>
      <Row gap={12}>
        <Row
          gap={12}
          onClick={() => {
            setManagerFeedbackEnabled(!managerFeedbackEnabled);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox checked={managerFeedbackEnabled} />
          <Text>Enable manager feedback with template:</Text>
        </Row>
        <SelectTemplate
          token={managerFeedbackTemplateToken}
          onChange={setManagerFeedbackTemplateToken}
          disabled={!managerFeedbackEnabled}
          templateOptions={managerFeedbackTemplates}
        />
      </Row>
      <Row gap={12}>
        <Row
          gap={12}
          onClick={() => {
            setSelfFeedbackEnabled(!selfFeedbackEnabled);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox checked={selfFeedbackEnabled} />
          <Text>Enable self feedback with template:</Text>
        </Row>
        <SelectTemplate
          token={selfFeedbackTemplateToken}
          onChange={setSelfFeedbackTemplateToken}
          disabled={!selfFeedbackEnabled}
          templateOptions={selfFeedbackTemplates}
        />
      </Row>
      <Row>
        <Button onClick={handleUpdate} loading={isSaving}>
          Update
        </Button>
      </Row>
    </Column>
  );
};

const SelectTemplate: React.FC<{
  token: FeedbackTemplateToken;
  onChange: (token: FeedbackTemplateToken) => void;
  disabled?: boolean;
  templateOptions: IFeedbackTemplate[];
}> = ({ token, onChange, disabled, templateOptions }) => {
  return (
    <Select value={token} onChange={onChange} disabled={disabled}>
      {templateOptions.map((template) => (
        <Select.Option key={template.token} value={template.token}>
          {template.name}
        </Select.Option>
      ))}
    </Select>
  );
};
