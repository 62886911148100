import { ISurveyCycle } from '@shared/surveys';
import { put } from '@web/common/api';
import { SelectDate } from '@web/components/SelectDate';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header3, Small, Text } from '@web/components/typography';
import { Button, Checkbox, Divider, message } from 'antd';
import React, { useState } from 'react';

import { useScheduledEvent } from './useScheduledEvent';

export const SurveyCycleSettingsForm: React.FC<{
  surveyCycle: ISurveyCycle;
  onSave: (surveyCycle: ISurveyCycle) => void;
}> = ({ onSave, surveyCycle }) => {
  const [anonymous, setAnonymous] = useState(surveyCycle.anonymous);
  const startScheduledEvent = useScheduledEvent(
    surveyCycle?.startEvent ?? { manual: true },
  );
  const [reminderScheduled, setReminderScheduled] = useState(
    !!surveyCycle?.reminderEvent,
  );
  const reminderScheduledEvent = useScheduledEvent(surveyCycle?.reminderEvent);
  const [finalReminderScheduled, setFinalReminderScheduled] = useState(
    !!surveyCycle?.finalReminderEvent,
  );
  const finalReminderScheduledEvent = useScheduledEvent(
    surveyCycle?.finalReminderEvent,
  );
  const endScheduledEvent = useScheduledEvent(
    surveyCycle?.endEvent ?? { manual: true },
  );

  const handleSave = async () => {
    try {
      const updatedSurveyCycle = await put<any, ISurveyCycle>(
        `/survey-cycles/${surveyCycle.token}`,
        {
          anonymous,
          startEvent: startScheduledEvent.getEntity(),
          endEvent: endScheduledEvent.getEntity(),
          reminderEvent: reminderScheduled
            ? reminderScheduledEvent.getEntity()
            : null,
          finalReminderEvent: finalReminderScheduled
            ? finalReminderScheduledEvent.getEntity()
            : null,
        },
      );
      onSave(updatedSurveyCycle);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column gap={24}>
      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Row gap={6}>
          <Checkbox
            checked={anonymous}
            onChange={() => {
              setAnonymous(!anonymous);
            }}
            disabled={!!surveyCycle.startedDate}
          />{' '}
          <Text>Survey responses are anonymous</Text>
        </Row>
        <Divider />
        <Header3>Scheduling</Header3>
        <Spacer size={1} />
        <Row gap={6}>
          <Checkbox
            checked={!startScheduledEvent.manual}
            onChange={() => {
              startScheduledEvent.setManual(!startScheduledEvent.manual);
            }}
          />{' '}
          <Text>Auto start survey and send notifications to participants</Text>
        </Row>
        {startScheduledEvent.manual ? (
          <>
            <Text>Start Date</Text>
            <SelectDate
              value={startScheduledEvent.date}
              onChange={(newValue) => {
                startScheduledEvent.setDate(newValue);
              }}
            />
            <Small>
              Survey must be manually started to notify participants
            </Small>
          </>
        ) : (
          <>
            <Text>Start Date/Time</Text>
            <SelectDateHour
              date={startScheduledEvent.date}
              hour={startScheduledEvent.hour}
              timezone={startScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                startScheduledEvent.onChange(newDate, newHour);
              }}
            />
            <Small>
              Survey will start automatically at this time and notifications
              sent to participants
            </Small>
          </>
        )}
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Row gap={6}>
          <Checkbox
            checked={reminderScheduled}
            onChange={() => {
              setReminderScheduled(!reminderScheduled);
            }}
          />{' '}
          <Text>
            Schedule a reminder notification to participants to take the survey
          </Text>
        </Row>
        {reminderScheduled && (
          <>
            <Text>Reminder Date/Time</Text>
            <SelectDateHour
              date={reminderScheduledEvent.date}
              hour={reminderScheduledEvent.hour}
              timezone={reminderScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                reminderScheduledEvent.onChange(newDate, newHour);
              }}
            />
          </>
        )}
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Row gap={6}>
          <Checkbox
            checked={finalReminderScheduled}
            onChange={() => {
              setFinalReminderScheduled(!finalReminderScheduled);
            }}
          />{' '}
          <Text>
            Schedule a final reminder notification to participants to take the
            survey
          </Text>
        </Row>
        {finalReminderScheduled && (
          <>
            <Text>Final reminder Date/Time</Text>
            <SelectDateHour
              date={finalReminderScheduledEvent.date}
              hour={finalReminderScheduledEvent.hour}
              timezone={finalReminderScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                finalReminderScheduledEvent.onChange(newDate, newHour);
              }}
            />
          </>
        )}
      </Column>

      <Column gap={6} style={{ alignItems: 'flex-start' }}>
        <Row gap={6}>
          <Checkbox
            checked={!endScheduledEvent.manual}
            onChange={() => {
              endScheduledEvent.setManual(!endScheduledEvent.manual);
            }}
          />{' '}
          <Text>Auto end survey and send notifications to participants</Text>
        </Row>
        {endScheduledEvent.manual ? (
          <>
            <Text>End Date</Text>
            <SelectDate
              value={endScheduledEvent.date}
              onChange={(newValue) => {
                endScheduledEvent.setDate(newValue);
              }}
            />
            <Small>
              Survey must be manually closed to prevent new submissions
            </Small>
          </>
        ) : (
          <>
            <Text>End Date/Time</Text>
            <SelectDateHour
              date={endScheduledEvent.date}
              hour={endScheduledEvent.hour}
              timezone={endScheduledEvent.timezone as string}
              onChange={(newDate, newHour) => {
                endScheduledEvent.onChange(newDate, newHour);
              }}
            />
            <Small>
              Survey will automatically end at this time and new submissions
              will no longer be accepted
            </Small>
          </>
        )}
      </Column>
      <Row>
        <Button type="primary" onClick={handleSave}>
          {surveyCycle ? 'Update' : 'Continue'}
        </Button>
      </Row>
    </Column>
  );
};
