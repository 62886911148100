import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useIndividualAnalytics } from '@client/AnalyticsClient';
import {
  IUserMetrics,
  METRIC_GREEN,
  METRIC_RED,
  METRIC_YELLOW,
  MetricType,
  UserMetrics,
} from '@shared/analytics';
import { IUser, TeamToken } from '@shared/types';
import { AlignmentStateIndicator } from '@web/1on1s/AlignmentStateIndicator';
import { ManagerOneOnOneTypeLabels } from '@web/1on1s/ManagerOneOnOneTypeLabels';
import { errorColor, successColor } from '@web/app/styles/ColorStyles';
import { usePagination } from '@web/common/usePagination';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, NoWrap, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { every, filter, sortBy } from 'lodash';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { MetricLabel } from './MetricColumnTitle';
import { UserMetricColumnTitle } from './UserMetricColumnTitle';
import { LineGraphMetric } from './visualizations/LineGraphMetric';
import { PieChartMetric } from './visualizations/PieChartMetric';

export const USER_PAGE_PARAM = 'upage';

export const UserMetricsTable: React.FC<{
  teamToken?: TeamToken;
  metricTypes: MetricType[];
}> = ({ teamToken, metricTypes }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamTokenFilter: TeamToken = searchParams.get('filter') as any;
  const { data } = useIndividualAnalytics(teamToken);
  const filteredTeam = teamTokenFilter
    ? data?.teams?.find((team) => team.token === teamTokenFilter)
    : undefined;
  const filteredUsers = filteredTeam ? filteredTeam.members : data?.users;
  const { pagination } = usePagination(filteredUsers?.length ?? 0, {
    pageSize: 10,
    paramName: USER_PAGE_PARAM,
  });
  if (!data) {
    return <Skeleton />;
  }

  const { userMetrics } = data;
  if (filteredUsers.length === 0) {
    return <Empty description="There are no employees on this team" />;
  }

  const columns: ColumnsType<IUser> = [
    {
      title: <MetricLabel>Employee</MetricLabel>,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render(name, user) {
        return (
          <HoverLink to={`/profile/${user.token}`}>
            <Row gap={6}>
              <UserAvatar user={user} size={28} />
              <NoWrap>{name}</NoWrap>
              <EyeOutlined style={{ color: '#666' }} className="onhover" />
            </Row>
          </HoverLink>
        );
      },
    },
  ];
  for (const metricType of metricTypes) {
    columns.push(createMetricColumn(metricType, userMetrics));
  }
  if (metricTypes.length < 4) {
    columns.push({
      title: '',
      width: '100%',
      render: () => '',
    });
  }

  const handleRemoveFilter = () => {
    setSearchParams((prev) => {
      prev.delete('filter');
      prev.delete(USER_PAGE_PARAM);
      return prev;
    });
  };

  const sortedUsers = sortBy(filteredUsers, 'name');
  return (
    <Column gap={12}>
      {filteredTeam && (
        <FilterTag>
          Showing only reports for{' '}
          {filteredTeam.manager ? filteredTeam.manager?.name : ' a single team'}{' '}
          <CloseOutlined onClick={handleRemoveFilter} />
        </FilterTag>
      )}
      <Table
        dataSource={sortedUsers}
        columns={filter(columns)}
        scroll={{ x: true }}
        locale={{
          emptyText: (
            <Empty description="There are no employees on this team" />
          ),
        }}
        pagination={pagination}
        onRow={() => ({ style: { height: 90 } })}
      />
    </Column>
  );
};

const createMetricColumn = (
  metricType: MetricType,
  userMetrics: UserMetrics,
): ColumnsType<IUser>[number] => {
  return {
    title: <UserMetricColumnTitle metricType={metricType} />,
    key: metricType,
    align: 'center',
    render(_, user: IUser) {
      return (
        <ViewTableMetric metrics={userMetrics[user.token]} type={metricType} />
      );
    },
  };
};

export const ViewTableMetric: React.FC<{
  type: MetricType;
  metrics: IUserMetrics;
}> = ({ type, metrics }) => {
  switch (type) {
    case MetricType.GOALS:
      if (
        metrics.goalsOnTrack + metrics.goalsAtRisk + metrics.goalsOffTrack ===
        0
      ) {
        return <Text>-</Text>;
      }

      return (
        <PieChartMetric
          data={[
            {
              name: 'On Track',
              value: metrics.goalsOnTrack,
              color: METRIC_GREEN,
            },
            {
              name: 'At Risk',
              value: metrics.goalsAtRisk,
              color: METRIC_YELLOW,
            },
            {
              name: 'Off Track',
              value: metrics.goalsOffTrack,
              color: METRIC_RED,
            },
          ]}
          circleSize={50}
        />
      );
    case MetricType.JOURNAL_WEEKLY_ENTRIES:
      if (
        !metrics.journalWeeklyUsage ||
        every(metrics.journalWeeklyUsage, (usage) => usage.value === 0)
      ) {
        return <Text>-</Text>;
      }

      return (
        <LineGraphMetric
          labeledMetrics={metrics.journalWeeklyUsage}
          width={150}
          height={70}
          hideLabels
        />
      );
    case MetricType.JOURNAL_USER_WITH_ENTRIES:
      return metrics.journalActivity ? (
        <CheckCircleOutlined style={{ color: successColor.string() }} />
      ) : (
        <CloseCircleOutlined style={{ color: errorColor.string() }} />
      );
    case MetricType.ONE_ON_ONES_ALIGNMENT:
      return <AlignmentStateIndicator state={metrics.oneOnOnesAlignment} />;
    case MetricType.ONE_ON_ONES_USERS_WITH_COMPLETED:
      return metrics.oneOneOnesCompleted > 0 ? (
        <CheckCircleOutlined style={{ color: successColor.string() }} />
      ) : (
        <CloseCircleOutlined style={{ color: errorColor.string() }} />
      );
    case MetricType.JOURNAL_ALIGNMENT:
      return <AlignmentStateIndicator state={metrics.journalAlignment} />;
    case MetricType.MANAGER_ONE_ON_ONES_SCHEDULED:
      return (
        <Text>
          {metrics.schedule
            ? ManagerOneOnOneTypeLabels[metrics.schedule]
            : 'Unscheduled'}
        </Text>
      );
    default:
      return null;
  }
};

const FilterTag = styled(Text)`
  background: #eee;
  border: 1px solid #bbb;
  border-radius: 12px;
  line-height: 24px;
  padding: 0 12px;
  align-self: flex-start;
`;

const HoverLink = styled(Link)`
  .onhover {
    opacity: 0;
  }

  &:hover .onhover {
    opacity: 1;
  }
`;
