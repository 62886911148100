import { RequestUpwardFeedbackRequest } from '@shared/feedback';
import {
  FeedbackTemplateToken,
  findTemplateByToken,
  upwardFeedbackTemplates,
} from '@shared/feedbackTemplates';
import {
  FeedbackVisibility,
  IUser,
  UserToken,
  isUserToken,
} from '@shared/types';
import { PageContent } from '@web/app/Page';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { FormButtons } from '@web/components/FormButtons';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectVisibility } from '@web/components/SelectVisibility';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { SelectReport } from '@web/components/users/SelectReport';
import { Button, Form, Select, Skeleton, Typography, message } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GiveUpwardFeedbackForm } from '../GiveUpwardFeedbackPage';

const { Option } = Select;

interface RequestFeedbackFields {
  giverToken: UserToken;
  receiverToken: UserToken;
  template: FeedbackTemplateToken;
  visibility: FeedbackVisibility;
}

export const RequestUpwardFeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<RequestFeedbackFields>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { userToken } = useParams<{ userToken?: string }>();
  const { data: receiver } = useApi<IUser>(`/users/${userToken}`);
  const templateToken = Form.useWatch('template', form);

  const feedbackRequestTemplates = upwardFeedbackTemplates;

  const [template, setTemplate] = React.useState(
    feedbackRequestTemplates.filter((template) => !template.disabled)[0],
  );

  useEffect(() => {
    if (!templateToken) {
      return;
    }
    const newTemplate = findTemplateByToken(templateToken);
    if (newTemplate) {
      setTemplate(newTemplate);
    }
  }, [templateToken]);

  // TODO: clean check
  if (!template || !userToken || !isUserToken(userToken)) {
    return null;
  }

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    let fields: RequestFeedbackFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      await post<RequestUpwardFeedbackRequest>('/feedback/request/upward', {
        receiverToken: userToken,
        giverToken: fields.giverToken,
        templateToken: fields.template,
        visibility: fields.visibility,
      });
      void message.success('Success');
      form.resetFields();
      goBack();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  if (!receiver) {
    return (
      <PageContent>
        <PageHeader
          title="Request upward feedback"
          navigateBack
          defaultNavigateBackTo="/team/members"
        />
        <Skeleton />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageHeader
        title="Request upward feedback"
        navigateBack
        defaultNavigateBackTo="/team/members"
      />
      <Pane>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            template: template.token,
            visibility: FeedbackVisibility.RECEIVER_MANAGER,
          }}
        >
          <UserMessage user={receiver} large style={{ marginBottom: 24 }} />
          <Form.Item
            label="Who would you like to request feedback from?"
            name="giverToken"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <SelectReport disabled={isSaving} managerToken={userToken} />
          </Form.Item>
          <Form.Item
            name="template"
            label="Select a template for this feedback request"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Select>
              {feedbackRequestTemplates
                .filter((template) => !template.disabled)
                .map((template) => {
                  return (
                    <Option key={template.token} value={template.token}>
                      {template.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="visibility"
            label="Visibility"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <SelectVisibility
              userName={receiver.name}
              size="normal"
              options={[
                FeedbackVisibility.MANAGER_ONLY,
                FeedbackVisibility.RECEIVER_MANAGER,
              ]}
            />
          </Form.Item>
          <Spacer size={12} />
          <FormButtons>
            <Button type="primary" onClick={handleSubmit}>
              Submit request
            </Button>
            <Button onClick={goBack}>Cancel</Button>
          </FormButtons>
        </Form>
      </Pane>
      <Spacer />
      <Column>
        <Typography.Title level={5} style={{ fontWeight: 400 }}>
          Request Preview
        </Typography.Title>
        <Pane>
          <GiveUpwardFeedbackForm
            receiver={receiver}
            templateToken={template.token}
            disabled={true}
            isViewing={true}
          />
        </Pane>
      </Column>
    </PageContent>
  );
};
