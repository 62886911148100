import { ReadOutlined } from '@ant-design/icons';
import { IImpact, entryLink, sortResponses } from '@shared/impacts';
import { IFeedback, UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import { BlockQuote } from '@web/components/BlockQuote';
import { Divider } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { FeedbackItem } from './FeedbackItem';
import { UserFeedback } from './UserFeedback';

export const EntryUserFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
  impactEntry?: IImpact;
}> = ({ feedback, giver, receiver, impactEntry }) => {
  const { user } = useAuth();
  const isManager = isManagerOf(receiver, user.token);
  const isAuthor = user.token === receiver.token;
  const isGiver = user.token === giver.token;
  const date = feedback?.updatedDate ?? undefined;

  const orderedResponses = sortResponses(feedback.responses ?? []);
  return (
    <UserFeedback giver={giver} receiver={receiver} date={date}>
      {impactEntry && (
        <>
          <Link
            title={impactEntry.title}
            to={entryLink({ isManager, isAuthor, entry: impactEntry })}
            style={{ marginBottom: 6 }}
          >
            <BlockQuote>
              <ReadOutlined
                style={{
                  fontSize: 14,
                  color: 'rgba(0,0,0,0.6)',
                  marginRight: 12,
                }}
              />
              {impactEntry.title}
            </BlockQuote>
          </Link>
        </>
      )}
      {orderedResponses.map((response, index) => {
        const handleSave = async (text: string) => {
          await patch(
            `/feedback/${feedback.token}/question/${response.questionToken}`,
            { text },
          );
        };

        return (
          <>
            {index > 0 && <Divider style={{ margin: '6px 0' }} />}
            <FeedbackItem
              key={response.token}
              response={response}
              receiver={receiver}
              onSave={user.token === giver.token ? handleSave : undefined}
              readonly={!isGiver && !isManager && !isAuthor}
            />
          </>
        );
      })}
    </UserFeedback>
  );
};
