import { ISurveySummaryRow } from '@shared/surveys';
import { Column } from '@web/components/layout';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import * as React from 'react';

export const SurveyCyclesTable: React.FC<{
  rows: ISurveySummaryRow[];
  onRowClick: (row: ISurveySummaryRow) => void;
  showColumns?: string[];
}> = ({ rows, onRowClick, showColumns }) => {
  const columns: ColumnsType<ISurveySummaryRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Start',
      dataIndex: 'startedDate',
      key: 'startedDate',
      align: 'center',
      width: 140,
      render: (startedDate) =>
        startedDate
          ? format(new Date(startedDate), 'yyyy-MM-dd')
          : 'Not started',
    },
    {
      title: 'End',
      dataIndex: 'endedDate',
      key: 'endedDate',
      align: 'center',
      width: 140,
      render: (endedDate) =>
        endedDate ? format(new Date(endedDate), 'yyyy-MM-dd') : 'Not ended',
    },
    {
      title: 'Questions',
      key: 'questions',
      dataIndex: 'numQuestions',
      align: 'center',
      width: 140,
    },
    {
      title: 'Participants',
      dataIndex: 'numParticipants',
      key: 'participants',
      align: 'center',
      width: 140,
    },
    {
      title: 'Responses',
      dataIndex: 'numSubmitted',
      key: 'responses',
      align: 'center',
      width: 140,
      render: (numSubmitted, row: ISurveySummaryRow) => {
        const percent =
          numSubmitted > 0
            ? Math.round((numSubmitted / row.numParticipants) * 100)
            : 0;
        return `${numSubmitted} (${percent}%)`;
      },
    },
  ].filter(
    (c) => !showColumns || showColumns.includes(c.key),
  ) as ColumnsType<ISurveySummaryRow>;

  return (
    <Column>
      <Table
        columns={columns}
        dataSource={rows}
        pagination={false}
        locale={{
          emptyText: <Empty description="No survey cycles yet" />,
        }}
        onRow={(cycle) => ({
          onClick: () => {
            onRowClick(cycle);
          },
          style: {
            cursor: 'pointer',
          },
        })}
        rowKey="token"
      />
    </Column>
  );
};
