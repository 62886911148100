import { QuestionType } from './QuestionType';
import { IQuestion, IRawQuestion, QuestionToken } from './questions';
import { createScale } from './rating_scales';
import { UserToken } from './types';

export type FeedbackTemplateToken = `frt_${string}` | `ft_${string}`;

export interface IRequestQuestion extends IRawQuestion {
  parameterName: string;
}

export interface GiveFeedbackRequest {
  feedbackResponses: Record<QuestionToken, string>;
}

export interface CreateTemplatedFeedbackRequest {
  giverToken: UserToken;
  receiverToken: UserToken;
  questionSetToken: FeedbackTemplateToken;
  templateParameters: Record<string, string>;
}

export type TemplateParameters = Record<string, string>;

export interface IFeedbackTemplate {
  token: FeedbackTemplateToken;
  name: string;
  templateParameters?: IRequestQuestion[];
  feedbackQuestions: IQuestion[];
  disabled?: boolean;
}

export const findTemplateByToken = (token?: FeedbackTemplateToken) => {
  if (!token) {
    return;
  }

  return (
    dimensionFeedbackTemplates.find((template) => template.token === token) ??
    upwardFeedbackTemplates.find((template) => template.token === token) ??
    managerFeedbackTemplates.find((template) => template.token === token) ??
    selfFeedbackTemplates.find((template) => template.token === token) ??
    managerFeedbackRequestTemplates.find(
      (template) => template.token === token,
    ) ??
    selfFeedbackRequestTemplates.find((template) => template.token === token) ??
    peerFeedbackRequestTemplates.find((template) => template.token === token) ??
    reflectionQuestionSets.find((template) => template.token === token)
  );
};

export const questionPosition = (
  templateToken: FeedbackTemplateToken,
  questionToken?: QuestionToken,
): number => {
  const template = findTemplateByToken(templateToken);
  if (!template) {
    throw new Error(`Template not found ${templateToken}`);
  }

  return template.feedbackQuestions.findIndex((q) => q.token === questionToken);
};

export const BASIC_DIMENSION_TEMPLATE_TOKEN: FeedbackTemplateToken =
  'frt_dimension_basic';
export const PROJECT_DIMENSION_TEMPLATE_TOKEN: FeedbackTemplateToken =
  'frt_dimension_proj';

export const dimensionFeedbackTemplates: IFeedbackTemplate[] = [
  {
    token: BASIC_DIMENSION_TEMPLATE_TOKEN,
    name: 'No Project',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_work_with_me',
        type: QuestionType.OPEN_ENDED,
        text: 'What is it like working with {{firstName}}? What strengths should {{firstName}} build on?',
      },
      {
        token: 'q_advice_for_me',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give {{firstName}} to grow?',
      },
    ],
  },
  {
    token: PROJECT_DIMENSION_TEMPLATE_TOKEN,
    name: 'Project',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_work_with_me_proj',
        type: QuestionType.OPEN_ENDED,
        text: 'How did {{firstName}} contribute to the success of the project?',
      },
      {
        token: 'q_advice_for_me_proj',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you offer {{firstName}} for future projects?',
      },
    ],
  },
];

export const upwardFeedbackTemplates: IFeedbackTemplate[] = [
  {
    token: 'frt_upward_refer',
    name: 'Refer',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_manager_refer',
        type: QuestionType.RATING,
        text: "How likely are you to refer someone to this person's team?",
        scale: createScale([
          'Very Unlikely',
          'Unlikely',
          'Unsure',
          'Likely',
          'Very likely',
        ]),
        required: true,
      },
    ],
  },
  {
    token: 'frt_upward_build_alignment',
    name: 'Team Environment',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_manager_fairness',
        type: QuestionType.RATING,
        text: 'Do you feel this person treats everyone on their team fairly?',
        scale: createScale(['Yes', 'Unsure', 'No']),
        required: false,
      },
      {
        token: 'q_manager_comms',
        type: QuestionType.RATING,
        text: "Do you feel this person helps you understand what's going on in the company?",
        scale: createScale(['Yes', 'No']),
        required: false,
      },
      {
        token: 'q_mgr_upward_additional',
        type: QuestionType.OPEN_ENDED,
        text: 'Any additional comments?',
        required: false,
      },
    ],
  },
];

export const managerFeedbackTemplates: IFeedbackTemplate[] = [
  {
    token: 'frt_manager_feedback',
    name: 'Flint Manager Feedback',
    feedbackQuestions: [
      {
        token: 'q_manager_accomplishments',
        type: QuestionType.OPEN_ENDED,
        text: 'What are the top 1-2 things that this person accomplished that highlight their work?',
        required: true,
      },
      {
        token: 'q_manager_growth',
        type: QuestionType.OPEN_ENDED,
        text: 'What are 1-2 areas where this person has room to develop to increase their impact?',
        required: true,
      },
      {
        token: 'q_manager_alignment',
        type: QuestionType.RATING,
        text: 'How is this person performing relative to expectations?',
        scale: createScale([
          'Needs Improvement',
          'Meets Expectations',
          'Exceeds Expectations',
        ]),
        required: true,
      },
    ],
  },
];

export const selfFeedbackTemplates: IFeedbackTemplate[] = [
  {
    token: 'ft_self_feedback',
    name: 'Flint Self Feedback',
    feedbackQuestions: [
      {
        token: 'q_self_accomplishments',
        type: QuestionType.OPEN_ENDED,
        text: 'What are the top 1-2 things that you accomplished that highlight your work?',
        required: true,
      },
      {
        token: 'q_self_growth',
        type: QuestionType.OPEN_ENDED,
        text: 'What are 1-2 areas where you have room to develop to increase your impact?',
        required: true,
      },
      {
        token: 'q_self_alignment',
        type: QuestionType.RATING,
        text: "How are you performing relative to manager's expectations?",
        scale: createScale([
          'Needs Improvement',
          'Meets Expectations',
          'Exceeds Expectations',
        ]),
        required: true,
      },
    ],
  },
];

export const managerFeedbackRequestTemplates: IFeedbackTemplate[] = [
  {
    token: 'frt_tracker_feedback',
    name: 'Tracker Feedback',
    disabled: true,
    templateParameters: [
      {
        parameterName: 'topic',
        token: 'q_tracker_describe_topic',
        type: QuestionType.OPEN_ENDED,
        text: 'Describe the topic that you’d like feedback about',
      },
    ],
    feedbackQuestions: [
      {
        token: 'q_did_well',
        type: QuestionType.OPEN_ENDED,
        text: 'What did they do well and should continue doing?',
      },
      {
        token: 'q_do_differently',
        type: QuestionType.OPEN_ENDED,
        text: 'What could they do differently in the future to have more impact?',
      },
    ],
  },
  {
    token: 'frt_project_feedback',
    name: 'Project Feedback',
    templateParameters: [
      {
        parameterName: 'topic',
        token: 'q_tracker_describe_topic',
        type: QuestionType.OPEN_ENDED,
        text: 'Describe the topic that you’d like feedback about',
      },
    ],
    feedbackQuestions: [
      {
        token: 'q_did_well',
        type: QuestionType.OPEN_ENDED,
        text: 'What did they do well and should continue doing?',
      },
      {
        token: 'q_do_differently',
        type: QuestionType.OPEN_ENDED,
        text: 'What could they do differently in the future to have more impact?',
      },
    ],
  },
  {
    token: 'frt_growth_advice',
    name: 'Advice on Growth',
    disabled: true,
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_grow_in_role',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give them to improve and grow in their role?',
      },
      {
        token: 'q_bigger_impact_team',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give them so that they could make a bigger impact on the team?',
      },
    ],
  },
  {
    token: 'frt_collab_advice',
    name: 'Advice on Collaboration',
    templateParameters: [],
    disabled: true,
    feedbackQuestions: [
      {
        token: 'q_collab_advice',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give them to be a better collaborator?',
      },
    ],
  },
  {
    token: 'frt_impact_advice',
    name: 'Advice on Impact',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_bigger_impact_team',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give them so that they could make a bigger impact on the team?',
      },
      {
        token: 'q_grow_in_role',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give them to improve and grow in their role?',
      },
    ],
  },
  {
    token: 'frt_teamwork_collaboration',
    name: 'Teamwork and Collaboration',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_collab_examples',
        type: QuestionType.OPEN_ENDED,
        text: 'What is it like to collaborate with {receiver}? Please provide any examples.',
      },
      {
        token: 'q_impact_collab_advice',
        type: QuestionType.OPEN_ENDED,
        text: 'What advice would you give to {receiver} on how to enhance their impact when collaborating with others? ',
      },
    ],
  },
  {
    token: 'frt_keep_start_stop',
    name: 'Keep / Stop / Start',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_keep_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What should they KEEP doing?',
      },
      {
        token: 'q_stop_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should they STOP doing?',
      },
      {
        token: 'q_start_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should they START doing?',
      },
    ],
  },
  {
    token: 'frt_custom_one_question',
    name: 'Custom - One Question',
    templateParameters: [
      {
        parameterName: 'question1',
        token: 'q_custom_question_parameter',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide a question you would like to ask:',
      },
    ],
    feedbackQuestions: [
      {
        token: 'q_custom_question',
        type: QuestionType.OPEN_ENDED,
        text: '{question1}',
      },
    ],
  },
  {
    token: 'frt_custom_two_question',
    name: 'Custom - Two Questions',
    templateParameters: [
      {
        parameterName: 'question1',
        token: 'q_custom_question1_parameter',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide a question you would like to ask:',
      },
      {
        parameterName: 'question2',
        token: 'q_custom_question2_parameter',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide another question you would like to ask:',
      },
    ],
    feedbackQuestions: [
      {
        token: 'q_custom_question1',
        type: QuestionType.OPEN_ENDED,
        text: '{question1}',
      },
      {
        token: 'q_custom_question2',
        type: QuestionType.OPEN_ENDED,
        text: '{question2}',
      },
    ],
  },
];

export const peerFeedbackRequestTemplates: IFeedbackTemplate[] = [
  {
    token: 'frt_agrovision_2024_07_pilot',
    name: '[Agrovision] Peer Reflection',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_agro_peer_thank_person',
        type: QuestionType.OPEN_ENDED,
        text: 'What should we thank this person for?',
        required: true,
      },
    ],
  },
  {
    token: 'frt_startout_2024_05_pilot',
    name: 'Pilot Peer Feedback [StartOut]',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_peer_startout_pilot_well',
        type: QuestionType.OPEN_ENDED,
        text: 'What is {{firstName}} doing well?',
      },
      {
        token: 'q_peer_startout_pilot_different',
        type: QuestionType.OPEN_ENDED,
        text: "What would you like to see {{firstName}} do differently in the future? This could be something you think would be helpful for them to start doing, something you'd like to see them change, or something you'd like to see them move away from doing.",
      },
      {
        token: 'q_peer_startout_pilot_share',
        type: QuestionType.OPEN_ENDED,
        text: "Any other feedback you'd like to share with {{firstName}} and their manager?",
      },
    ],
  },
  {
    token: 'frt_peer_keep_start_stop',
    name: 'Keep / Stop / Start',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_peer_keep_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What should they KEEP doing?',
      },
      {
        token: 'q_peer_stop_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should they STOP doing?',
      },
      {
        token: 'q_peer_start_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should they START doing?',
      },
    ],
  },
  {
    token: 'frt_peer_factoryfix',
    name: 'Peer Review',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_peer_factoryfix_strengths',
        type: QuestionType.OPEN_ENDED,
        required: true,
        text: 'What are this person’s strengths? Highlight any specific accomplishments, wins, contributions, skills, etc.',
      },
      {
        token: 'q_peer_factoryfix_constructive',
        type: QuestionType.OPEN_ENDED,
        required: true,
        text: 'What is one piece of constructive advice you would share with this person, and how would this change impact your work?',
      },
      {
        token: 'q_peer_factoryfix_thank',
        type: QuestionType.OPEN_ENDED,
        required: true,
        text: 'What should we thank this person for?',
      },
      {
        token: 'q_peer_factoryfix_other',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else you’d like to share?',
      },
    ],
  },
  {
    token: 'frt_kbo_h1_2024_perf_rev_peer',
    name: 'H1 2024 Performance Peer Reflection [Keebo]',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_kbo_h1_2024_per_rev_peer_thank',
        type: QuestionType.OPEN_ENDED,
        required: true,
        text: 'What should we thank {{firstName}} for? Think of things they do that are above and beyond their role. Please provide examples.',
      },
    ],
  },
];

export const selfFeedbackRequestTemplates: IFeedbackTemplate[] = [
  {
    token: 'frt_self_keep_start_stop',
    name: 'Keep / Stop / Start',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_self_keep_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What should I KEEP doing?',
      },
      {
        token: 'q_self_stop_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should I STOP doing?',
      },
      {
        token: 'q_self_start_doing',
        type: QuestionType.OPEN_ENDED,
        text: 'What if anything should I START doing?',
      },
    ],
  },
];

export const reflectionQuestionSets: IFeedbackTemplate[] = [
  {
    token: 'frt_self_reflection',
    name: 'Self Reflection',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_self_reflection_expectations',
        type: QuestionType.OPEN_ENDED,
        text: 'How did you perform compared to your goals and expectations? Consider your impact and the outcomes',
      },
      {
        token: 'q_self_reflection_progress',
        type: QuestionType.OPEN_ENDED,
        text: 'What growth areas do you want to focus on?',
      },
      {
        token: 'q_reflection_additional_thoughts',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide any additional thoughts',
      },
    ],
  },
  {
    token: 'frt_mgr_reflection',
    name: 'Manager Reflection',
    templateParameters: [],
    feedbackQuestions: [
      {
        token: 'q_reflection_expectations',
        type: QuestionType.OPEN_ENDED,
        text: 'How did they perform compared to goals and expectations? Consider their impact and the outcomes',
      },
      {
        token: 'q_reflection_progress',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should they focus on?',
      },
      {
        token: 'q_reflection_additional_thoughts',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide any additional thoughts',
      },
    ],
  },
];
