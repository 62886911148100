import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import {
  IOpenEndedQuestion,
  IQuestion,
  IRatingQuestion,
  QuestionToken,
} from '@shared/questions';
import { RatingScale, RatingTrend } from '@shared/rating_scales';
import { SurveyCycleToken, SurveyParticipantToken } from '@shared/surveys';
import { UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { complimentaryColor, primaryColor } from '@web/app/styles/ColorStyles';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Divider, Empty, Tag } from 'antd';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { sortBy, sum } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { RatingTrendChart } from '../RatingTrendChart';

export const ResponseSummaries: React.FC<{
  questions: IQuestion[];
  summaries?: any;
  participantMap?: Record<SurveyParticipantToken, UserMapItem>;
  averageRatingTrendMap?: Record<QuestionToken, RatingTrend>;
  currentSurveyCycleToken?: SurveyCycleToken;
  totalParticipants: number;
}> = ({
  questions,
  summaries,
  participantMap,
  averageRatingTrendMap,
  currentSurveyCycleToken,
  totalParticipants,
}) => {
  const hasSummaries = summaries && Object.keys(summaries).length > 0;

  return (
    <Column>
      {!hasSummaries ? (
        <Empty description="No response summaries available" />
      ) : (
        questions.map((question, index) => (
          <>
            {index !== 0 && <Divider />}

            {question.type === QuestionType.RATING ? (
              <RatingQuestionSummary
                key={question.token}
                question={question}
                summary={summaries[question.token]}
                averageTrend={
                  averageRatingTrendMap?.[question.correlationToken as any]
                }
                currentSurveyCycleToken={currentSurveyCycleToken}
                totalParticipants={totalParticipants}
              />
            ) : question.type === QuestionType.OPEN_ENDED ? (
              <OpenEndedQuestionSummary
                key={question.token}
                question={question}
                summary={summaries[question.token]}
                participantMap={participantMap}
              />
            ) : null}
          </>
        ))
      )}
    </Column>
  );
};
const OpenEndedQuestionSummary: React.FC<{
  question: IOpenEndedQuestion;
  summary?: IQuestionResponse[];
  participantMap?: Record<SurveyParticipantToken, UserMapItem>;
}> = ({ question, summary, participantMap }) => {
  if (!summary || summary?.length === 0) {
    return (
      <ResponseSummary>
        <Header3>{question.text}</Header3>
        <Text>No responses</Text>
      </ResponseSummary>
    );
  }

  return (
    <ResponseSummary>
      <Header3>{question.text}</Header3>
      <Column gap={18}>
        {summary.map((response) => (
          <Column gap={6} key={response.token}>
            <UserLabel
              user={participantMap?.[response.surveyParticipantToken as any]}
            />
            <Text>{response.text}</Text>
          </Column>
        ))}
      </Column>
    </ResponseSummary>
  );
};
const RatingQuestionSummary: React.FC<{
  question: IRatingQuestion;
  summary: Record<number, number>;
  averageTrend?: RatingTrend;
  currentSurveyCycleToken?: SurveyCycleToken;
  totalParticipants: number;
}> = ({
  question,
  summary,
  averageTrend,
  currentSurveyCycleToken,
  totalParticipants,
}) => {
  const { isMobile } = useResponsive();
  const totalResponses = sum(Object.values(summary));
  const totalRatings = sum(
    [1, 2, 3, 4, 5].map((value) => {
      return summary[value] ? summary[value] * value : 0;
    }),
  );
  const percent =
    totalResponses > 0
      ? Math.round((totalResponses / totalParticipants) * 100)
      : 0;

  return (
    <ResponseSummary>
      <Row style={{ maxWidth: 800 }}>
        <Header3>{question.text}</Header3>
      </Row>
      <Row style={{ maxWidth: 600 }}>
        <Tag>
          Responses: {totalResponses} ({percent}%)
        </Tag>
        <GrowingSpacer />
        <Tag color={primaryColor.string()}>
          Average:{' '}
          {totalResponses > 0
            ? (totalRatings / totalResponses).toFixed(1)
            : 'N/A'}
        </Tag>
      </Row>
      <ReactECharts
        option={createRatingScaleChartOptions(question.scale ?? [], summary)}
        style={{ width: isMobile ? 350 : 600, height: 150, padding: 0 }}
      />
      {averageTrend && averageTrend.length > 1 && (
        <Column>
          <Spacer size={12} />
          <Header3>Previous averages</Header3>
          <RatingTrendChart
            trend={averageTrend}
            currentKey={currentSurveyCycleToken}
          />
        </Column>
      )}
    </ResponseSummary>
  );
};
const createRatingScaleChartOptions = (
  scale: RatingScale,
  summary: any,
): EChartsOption => {
  const sortedScale = sortBy(scale, 'value');
  const data = sortedScale.map((rating) => summary[rating.value]);
  return {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '13%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: sortedScale.map((rating) => `${rating.value} - ${rating.label}`),
      // axisLabel: {
      //   rotate: 20,
      //   hideOverlap: false,
      // },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data,
        type: 'bar',
        itemStyle: {
          color: complimentaryColor.string(),
        },
        barWidth: 30,
      },
    ],
  };
};
const ResponseSummary = styled(Column)`
  gap: 12px;
`;

const UserLabel: React.FC<{ user?: UserMapItem }> = ({ user }) =>
  user ? (
    <Row gap={6}>
      <UserAvatar user={user} size={16} />
      <Text>{user.name}</Text>
    </Row>
  ) : null;
