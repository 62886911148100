import {
  ApartmentOutlined,
  ApiOutlined,
  BarChartOutlined,
  CloudSyncOutlined,
  CommentOutlined,
  GithubOutlined,
  HomeOutlined,
  InboxOutlined,
  LogoutOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RobotOutlined,
  SettingOutlined,
  ShopOutlined,
  SlackOutlined,
  SyncOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useAlignmentEnabled } from '@client/AlignmentClient';
import { Feature } from '@shared/features';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Subheading } from '@web/components/text';
import OrganizationsModal from '@web/organizations/OrganizationsModal';
import { Typography } from 'antd';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import useKeypress from 'react-use-keypress';
import styled from 'styled-components';

import { OpenRequestsLabel } from '../components/OpenRequestsLabel';
import { FlintLogo, Logo } from './Logo/Logo';
import { OrganizationPicker } from './OrganizationPicker';
import { QuickSearchModal } from './QuickSearchModal';
import { SidebarActionButton } from './SidebarActionButton';
import { useOrganizationFilter } from './organizationFilter';
import { useResponsive } from './responsive';

export const Sidebar: React.FC<{ top: number; width: number }> = ({
  top,
  width,
}) => {
  const { isOperations, isSuper } = useAuth();
  const isInternal = isSuper || isOperations;

  return (
    <Container style={{ top, height: `calc(100% - ${top}px)`, width }}>
      {isInternal ? <InternalSidebar /> : <CustomerSidebar />}
    </Container>
  );
};

const InternalSidebar: React.FC = () => {
  const { organizationTokenFilter } = useOrganizationFilter();

  const [showQuickSearchModal, setShowQuickSearchModal] = React.useState(false);
  const [showOrganizationsModal, setShowOrganizationsModal] =
    React.useState(false);
  const organizationPath = `/organizations/${organizationTokenFilter}`;

  useKeypress(['O', 'o'], (e) => {
    if (e.ctrlKey && e.shiftKey) {
      setShowOrganizationsModal(true);
      e.preventDefault();
    }
  });
  useKeypress(['P', 'p'], (e) => {
    if (e.ctrlKey) {
      setShowQuickSearchModal(true);
      e.preventDefault();
    }
  });
  return (
    <Column gap={18} style={{ height: '100%' }}>
      <Row gap={6}>
        <FlintLogo size="18px" />
        <Subheading>Flint Internal</Subheading>
      </Row>
      <OrganizationPicker
        onClick={() => {
          setShowOrganizationsModal(true);
        }}
      />
      <SidebarLinks>
        {organizationTokenFilter && (
          <>
            <SidebarLink to={`${organizationPath}/details`}>
              <ShopOutlined /> Organization
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/users`}>
              <ApartmentOutlined /> Users
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/feedback`}>
              <CommentOutlined /> Feedback
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/notifications`}>
              <SlackOutlined /> Notifications
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/workday`}>
              <CloudSyncOutlined /> Workday
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/github`}>
              <GithubOutlined /> Github
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/performance`}>
              <RobotOutlined /> Performance
            </SidebarLink>
            <GrowingSpacer />
          </>
        )}
        <SidebarLink to="/app/config">
          <SettingOutlined /> Configuration
        </SidebarLink>
      </SidebarLinks>
      {showOrganizationsModal && (
        <OrganizationsModal
          onClose={() => {
            setShowOrganizationsModal(false);
          }}
          open={true}
        />
      )}
      {showQuickSearchModal && (
        <QuickSearchModal
          onClose={() => {
            setShowQuickSearchModal(false);
          }}
          open={true}
        />
      )}
    </Column>
  );
};

const CustomerSidebar: React.FC<{}> = () => {
  return (
    <>
      <Link to="/home">
        <Logo
          style={{
            justifyContent: 'flex-start',
            paddingLeft: 18,
            height: 36,
          }}
        />
      </Link>
      <Spacer size={6} />
      <SidebarActionButton />
      <Spacer size={24} />
      <CustomerSidebarLinks />
    </>
  );
};

export const CustomerSidebarLinks: React.FC = () => {
  const { isMobile } = useResponsive();
  const { isHrAdmin, isAdmin, managesPeople, logout } = useAuth();
  const { booleanValue: enable1on1s } = useFeature(Feature.ENABLE_1ON1s);
  const { data: enableAlignmentIndicators } = useAlignmentEnabled();
  const { booleanValue: goalsEnabled } = useFeature(Feature.GOALS);
  const { booleanValue: reflectionsEnabled } = useFeature(Feature.REFLECTIONS);
  const { booleanValue: reviewCyclesEnabled } = useFeature(
    Feature.REVIEW_CYCLES,
  );
  const { booleanValue: surveysEnabled } = useFeature(Feature.SURVEYS);
  const { booleanValue: journalEnabled } = useFeature(Feature.JOURNAL);
  const { booleanValue: analticsDashboardEnabled } = useFeature(
    Feature.ANALYTICS_DASHBOARD,
  );
  const { outstandingTaskCount } = useOutstandingCount();

  const showAdminLink = (isHrAdmin || isAdmin) && !isMobile;
  return (
    <>
      <SidebarLinks>
        <SidebarLink to="/home">
          <HomeOutlined /> Home
        </SidebarLink>
        <SidebarLink to="/requests">
          <InboxOutlined /> Requests
          {outstandingTaskCount > 0 && (
            <OpenRequestsLabel>{outstandingTaskCount}</OpenRequestsLabel>
          )}
        </SidebarLink>
        {(managesPeople || isHrAdmin) && (
          <>
            <SidebarLink to="/team">
              <TeamOutlined /> Team
            </SidebarLink>
          </>
        )}
        {reviewCyclesEnabled && (
          <>
            <SidebarLink to="/cycles">
              <SyncOutlined /> Cycles
            </SidebarLink>
          </>
        )}
        <SidebarLink to="/feedback">
          <CommentOutlined /> Feedback
        </SidebarLink>
        {surveysEnabled && managesPeople ? (
          <SidebarLink to="/surveys">
            <PieChartOutlined /> Surveys
          </SidebarLink>
        ) : null}
        {journalEnabled && (
          <SidebarLink to="/journal">
            <ReadOutlined /> Journal
          </SidebarLink>
        )}
        {reflectionsEnabled && (
          <SidebarLink to="/reflections">
            <SyncOutlined /> Reflections
          </SidebarLink>
        )}
        {goalsEnabled && (
          <SidebarLink to="/goals">
            <TrophyOutlined /> Goals
          </SidebarLink>
        )}
        {enable1on1s && (
          <SidebarLink to="/1on1s">
            <UserSwitchOutlined /> 1-on-1s
          </SidebarLink>
        )}
        {enableAlignmentIndicators && (
          <SidebarLink to="/alignment">
            <ApiOutlined /> Alignment
          </SidebarLink>
        )}
        {isHrAdmin && analticsDashboardEnabled && (
          <>
            <SidebarLink to="/analytics">
              <BarChartOutlined /> Analytics
            </SidebarLink>
          </>
        )}
        <GrowingSpacer />
        {journalEnabled && (
          <SidebarLink to="/user/settings">
            <SettingOutlined /> Settings
          </SidebarLink>
        )}
        {showAdminLink && (
          <SidebarLink to="/admin">
            <ApartmentOutlined /> Admin
          </SidebarLink>
        )}
        {!journalEnabled && (
          <SidebarButton onClick={logout}>
            <LogoutOutlined /> Sign out
          </SidebarButton>
        )}
        <SidebarLink url="https://flint.cc/help-center">
          <QuestionCircleOutlined /> Help Center
        </SidebarLink>
      </SidebarLinks>
    </>
  );
};

const SidebarLink: React.FC<{
  to?: string;
  url?: string;
  children: React.ReactNode;
}> = ({ to, url, children }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer" className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </a>
  ) : (
    <NavLink to={to} className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </NavLink>
  );

const SidebarButton: React.FC<{
  onClick?: () => void;
  children: React.ReactNode;
}> = ({ onClick, children }) => (
  <div onClick={onClick} className="sidebar-link">
    <Typography.Text>{children}</Typography.Text>
  </div>
);

export const PoweredByFlint: React.FC = () => {
  return (
    <Row style={{ justifyContent: 'center' }}>
      <FlintLogo size="16px" />
      <Typography.Text
        style={{ fontSize: 12, color: '#888', lineHeight: '18px' }}
      >
        Powered by Flint
      </Typography.Text>
    </Row>
  );
};

const Container = styled.section`
  top: 0;
  height: 100%;
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-right: 1px solid #eee;
`;

const SidebarLinks = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 6px;

  svg {
    margin-right: 5px;
  }

  .sidebar-link {
    cursor: pointer;
    border-radius: var(--default-border-radius);
    transition: all 0.2s linear;
    padding: 0 12px;
    white-space: nowrap;

    > span {
      margin: 0;
      display: flex;
      gap: 6px;
      align-items: center;
      line-height: 34px;
      font-size: 14px;
      font-weight: 400;
      color: #666;

      svg {
        font-size: 18px;
        padding: 2px;
        margin: 0;
      }
    }
  }

  .sidebar-link:hover {
    background-color: var(--hover-background);
  }

  .sidebar-link.active {
    background-color: var(--ant-primary-color);

    > span {
      color: white;
    }
  }
`;
