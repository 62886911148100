import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { dateString } from '@shared/dateString';
import { formatLongDate } from '@shared/formatLongDate';
import { productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  ReviewCycleEventType,
  ReviewCycleToken,
  UpdateReviewCycleNotificationsRequest,
} from '@shared/review-cycles';
import { IAutoScheduledEvent } from '@shared/scheduled_event';
import { del, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { BackLink } from '@web/components/BackButton';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, Grid, Row } from '@web/components/layout';
import { Subheading } from '@web/components/text';
import { Header3, Text } from '@web/components/typography';
import { useScheduledEvent } from '@web/surveys/cycles/useScheduledEvent';
import { Button, Modal, Popconfirm, Skeleton, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AdminPageContent } from '../admin/AdminPageContent';
import { BorderedPane } from './BorderedPane';
import { countCycleComponents } from './countCycleComponents';

const ALL_NOTIFICATIONS: Record<ReviewCycleEventType, NotificationDetails> = {
  [ReviewCycleEventType.START_REVIEW_CYCLE]: {
    name: 'Start Cycle',
  },
  [ReviewCycleEventType.CYCLE_INTRO]: {
    name: 'Introduction',
  },
  [ReviewCycleEventType.START_PEER_REVIEW_CYCLE]: {
    name: 'Start Peer Review Cycle',
  },
  [ReviewCycleEventType.PEER_SELECTION_REMINDER]: {
    name: 'Peer Selection Reminder',
  },
  [ReviewCycleEventType.PEER_APPROVAL_REMINDER]: {
    name: 'Peer Approval Reminder',
  },
  [ReviewCycleEventType.SEND_PEER_FEEDBACK_REQUESTS]: {
    name: 'Send Peer Feedback Requests',
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_REMINDER]: {
    name: 'Feedback Request Reminder',
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_DUE_REMINDER]: {
    name: 'Feedback Request Due Reminder',
  },
  [ReviewCycleEventType.FEEDBACK_REQUEST_OVERDUE_REMINDER]: {
    name: 'Feedback Request Overdue Reminder',
  },
  [ReviewCycleEventType.START_SELF_REVIEWS]: {
    name: 'Start Self Reviews',
  },
  [ReviewCycleEventType.SELF_REVIEW_REMINDER]: {
    name: 'Self Review Reminder',
  },
  [ReviewCycleEventType.SELF_REVIEW_DUE_REMINDER]: {
    name: 'Self Review Due Reminder',
  },
  [ReviewCycleEventType.SELF_REVIEW_OVERDUE_REMINDER]: {
    name: 'Self Review Overdue Reminder',
  },
  [ReviewCycleEventType.START_MANAGER_REVIEWS]: {
    name: 'Start Manager Reviews',
  },
  [ReviewCycleEventType.MANAGER_REVIEW_REMINDER]: {
    name: 'Manager Review Reminder',
  },
  [ReviewCycleEventType.MANAGER_REVIEW_DUE_REMINDER]: {
    name: 'Manager Review Due Reminder',
  },
  [ReviewCycleEventType.MANAGER_REVIEW_OVERDUE_REMINDER]: {
    name: 'Manager Review Overdue Reminder',
  },
  [ReviewCycleEventType.RELEASE_REMINDER]: {
    name: 'Release Reminder',
  },
};

interface NotificationDetails {
  name: string;
}

interface DashboardRow {
  name: string;
  type: ReviewCycleEventType;
  event: IAutoScheduledEvent;
}

export const AdminEditCycleNotificationsPage: React.FC = () => {
  const { reviewCycleToken } = useParams<{
    reviewCycleToken: ReviewCycleToken;
  }>();

  const { data: reviewCycle, mutate: reloadReviewCycle } = useApi<IReviewCycle>(
    `/review-cycles/${reviewCycleToken}`,
  );

  if (!reviewCycle?.events) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const {
    peerReviewCycleEnabled,
    selfReflectionCycleEnabled,
    managerReflectionCycleEnabled,
  } = reviewCycle;
  const gridColumnCount = countCycleComponents(reviewCycle);
  const gridColumns = new Array<string>(gridColumnCount).fill('1fr').join(' ');

  const tableRows = Object.keys(ALL_NOTIFICATIONS).map(
    (type: ReviewCycleEventType) => {
      const details = ALL_NOTIFICATIONS[type];

      return {
        name: details.name,
        type,
        event: reviewCycle.events.find((e) => e.type === type)?.event,
      };
    },
  );

  const columns: ColumnsType<DashboardRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Enabled',
      key: 'enabled',
      render: (_, row) => (
        <Text key={`${row.type}-${row.event?.token}`}>
          {row.event && !row.event.manual ? 'Yes' : 'No'}
        </Text>
      ),
    },
    {
      title: 'Scheduled Time',
      key: 'scheduled-time',
      render: (_, row) => {
        if (!row.event) {
          return 'N/A';
        }
        const hourDate = new Date();
        hourDate.setHours(row.event.hour, 0, 0, 0);
        const time = format(hourDate, 'p');
        const date = formatLongDate(
          new Date(`${row.event.date}T23:59:59.999Z`),
        );
        return `${date} ${time}`;
      },
    },
    {
      title: 'Processed Time',
      key: 'processed-time',
      render: (_, row) => {
        return (
          <Text>
            {row.event?.processedDate
              ? formatLongDate(row.event.processedDate, true)
              : 'N/A'}
          </Text>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, row) => {
        const handleDelete = async () => {
          try {
            await del(
              `/review-cycles/${reviewCycle.token}/notifications/${row.type}`,
            );
            void reloadReviewCycle();
            void message.success('Success');
          } catch (error) {
            void message.error('Failed');
          }
        };
        const disabled = !!row.event?.processedDate;
        return (
          <Row gap={6}>
            <EditNotificationButton
              disabled={disabled}
              reviewCycle={reviewCycle}
              row={row}
              onSave={() => {
                void reloadReviewCycle();
              }}
            />
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={disabled} icon={<DeleteOutlined />} />
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink
            to={`/admin/review-cycles/${reviewCycle.token}/dashboard`}
          />
        </Row>
        <Row>
          <Subheading>{'Manage Notifications'}</Subheading>
        </Row>
      </Column>
      <Column gap={24}>
        <Grid gap={12} columns={gridColumns}>
          {peerReviewCycleEnabled && (
            <BorderedPane>
              <Column gap={12}>
                <Header3>Peer Feedback</Header3>
                <Column>
                  <Text>Peer Selection</Text>
                  <Text>
                    {dateString(reviewCycle.peerSelectionStartDate)} -{' '}
                    {dateString(reviewCycle.peerSelectionEndDate)}
                  </Text>
                </Column>
                <Column>
                  <Text>Peer Approval</Text>
                  <Text>
                    {dateString(reviewCycle.peerApprovalStartDate)} -{' '}
                    {dateString(reviewCycle.peerApprovalEndDate)}
                  </Text>
                </Column>

                <Column>
                  <Text>Feedback Requests</Text>
                  <Text>
                    {dateString(reviewCycle.peerFeedbackStartDate)} -{' '}
                    {dateString(reviewCycle.peerFeedbackEndDate)}
                  </Text>
                </Column>
              </Column>
            </BorderedPane>
          )}
          {selfReflectionCycleEnabled && (
            <BorderedPane>
              <Header3>Self {pluralize(selfReviewProduct.titleCase)}</Header3>
              <Column>
                <Text>Schedule</Text>
                <Text>
                  {dateString(reviewCycle.selfReviewStartDate)} -{' '}
                  {dateString(reviewCycle.selfReviewEndDate)}
                </Text>
              </Column>
            </BorderedPane>
          )}
          {managerReflectionCycleEnabled && (
            <BorderedPane>
              <Header3>
                Manager {pluralize(managerReviewProduct.titleCase)}
              </Header3>
              <Column>
                <Text>Schedule</Text>
                <Text>
                  {dateString(reviewCycle.managerReviewStartDate)} -{' '}
                  {dateString(reviewCycle.managerReviewEndDate)}
                </Text>
              </Column>
            </BorderedPane>
          )}
        </Grid>
        <BorderedPane>
          <Column gap={6}>
            <Header3>Events</Header3>
            <Table<DashboardRow>
              dataSource={tableRows}
              columns={columns}
              pagination={false}
            ></Table>
          </Column>
        </BorderedPane>
      </Column>
    </AdminPageContent>
  );
};

const EditNotificationButton: React.FC<{
  disabled: boolean;
  reviewCycle: IReviewCycle;
  row: DashboardRow;
  onSave: () => void;
}> = ({ disabled, reviewCycle, row, onSave }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSave();
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        icon={<EditOutlined />}
        type="default"
        onClick={showModal}
      />
      <EditNotificationModal
        reviewCycle={reviewCycle}
        row={row}
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </>
  );
};

const EditNotificationModal: React.FC<{
  reviewCycle: IReviewCycle;
  row: DashboardRow;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
}> = ({ reviewCycle, row, open, onSave, onCancel }) => {
  const state = useScheduledEvent(row.event);

  const handleSave = async () => {
    try {
      const update = state.getEntity();
      if (update.manual) {
        throw new Error('cannot schedule manual events');
      }

      await put<UpdateReviewCycleNotificationsRequest>(
        `/review-cycles/${reviewCycle.token}/notifications`,
        {
          type: row.type,
          update: update as IAutoScheduledEvent,
        },
      );
      onSave();
    } catch (error) {
      console.log(error);
      void message.error('Failed');
    }
  };

  if (state.manual) {
    state.setManual(false);
    return (
      <Modal open={open} title={row.event ? 'Edit Event' : 'Schedule Event'}>
        <Skeleton />
      </Modal>
    );
  }
  return (
    <Modal
      open={open}
      title={row.event ? 'Edit Event' : 'Schedule Event'}
      onCancel={onCancel}
      onOk={handleSave}
    >
      <Header3>{row.name}</Header3>
      <SelectDateHour
        date={state.date}
        hour={state.hour}
        onChange={state.onChange}
        timezone={state.timezone}
      />
    </Modal>
  );
};
