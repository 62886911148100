import { useSummaries } from '@client/PerformanceClient';
import { IPerformanceSummary } from '@shared/performance';
import { OrganizationToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useDefault } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { SummariesTable } from './SummariesTable';

const ListSummariesPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: summaries } = organizationToken
    ? useSummaries(organizationToken)
    : useDefault<IPerformanceSummary[]>([]);

  return (
    <PageContent>
      <PageHeader
        title="Performance Summaries"
        primaryAction={{
          label: 'New Summary',
          linkTo: 'new',
        }}
      />
      {summaries ? <SummariesTable summaries={summaries} /> : <Skeleton />}
    </PageContent>
  );
};

export default ListSummariesPage;
