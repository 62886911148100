import { ClockCircleOutlined } from '@ant-design/icons';
import { Row } from '@web/components/layout';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { times } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

export const SelectDateTime: React.FC<{
  value: Date;
  disabled?: boolean;
  onChange: (value: Date) => void;
}> = ({ value = new Date(), onChange, disabled }) => {
  const [time, setTime] = React.useState(findTimeOption(value));
  React.useEffect(() => {
    if (value) {
      setTime(findTimeOption(value));
    }
  }, [value]);
  const dayjsDate = dayjs(value);
  const handleDatePickerChanged = (newDate: dayjs.Dayjs) => {
    if (!newDate) {
      return;
    }

    fireOnChange(newDate, time);
  };

  const handleTimeChanged = (newTime: ITimeOption) => {
    setTime(newTime);
    fireOnChange(dayjsDate, newTime);
  };

  const fireOnChange = (dayjsDate: dayjs.Dayjs, time: ITimeOption) => {
    const newDate = new Date(dayjsDate.toDate());
    newDate.setHours(time.hour);
    newDate.setMinutes(time.minute);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    onChange(newDate);
  };

  return (
    <Container>
      <DatePicker
        value={dayjsDate}
        disabled={disabled}
        onChange={handleDatePickerChanged}
      />
      <SelectTime
        value={time}
        onChange={handleTimeChanged}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled(Row)`
  gap: 6px;
`;

interface ITimeOption {
  hour: number;
  minute: number;
  label: string;
}

export const SelectTime: React.FC<{
  value: ITimeOption;
  onChange: (time: ITimeOption) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  const handleOptionChange = (timeLabel: string) => {
    const newValue = timeOptions.find((option) => option.label === timeLabel);
    onChange(newValue);
  };

  return (
    <Select
      value={value.label}
      onChange={handleOptionChange}
      suffixIcon={<ClockCircleOutlined />}
      style={{ width: 150 }}
      disabled={disabled}
    >
      {timeOptions.map((option) => (
        <Select.Option key={option.label} value={option.label}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const findTimeOption = (date: Date) => {
  const hour = date.getHours();
  const minute = date.getMinutes() % 15 === 0 ? date.getMinutes() : 0;
  return timeOptions.find(
    (option) => option.hour === hour && option.minute === minute,
  );
};
const timeOptions: ITimeOption[] = times(23).flatMap((hour) => {
  const displayHour =
    hour === 0 ? '12' : hour > 12 ? `${hour - 12}` : `${hour}`;
  const ampm = hour > 11 ? 'pm' : 'am';
  return [
    { hour, minute: 0, label: `${displayHour}:00${ampm}` },
    { hour, minute: 15, label: `${displayHour}:15${ampm}` },
    { hour, minute: 30, label: `${displayHour}:30${ampm}` },
    { hour, minute: 45, label: `${displayHour}:45${ampm}` },
  ];
});
