import { useFindJournalEntries } from '@client/ImpactsClient';
import { formatDate } from '@shared/formatDate';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { Column, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const RecentEntries: React.FC<{
  userToken: UserToken;
  title?: string;
  limit?: number;
}> = ({ userToken, title = 'Journal', limit = 3 }) => {
  const { user } = useAuth();
  const { data: journalResults } = useFindJournalEntries(0, limit, userToken);
  if (!journalResults) {
    return <Skeleton />;
  }

  const isAuthor = user.token === userToken;
  return (
    <Column>
      <Link to={isAuthor ? '/journal' : `/team/${userToken}/journal`}>
        <Header2>{title}</Header2>
      </Link>
      <Spacer size={6} />
      {journalResults.impacts?.length > 0 && (
        <ul style={{ paddingInlineStart: 18 }}>
          {journalResults.impacts?.map((impact) => (
            <li key={impact.token} style={{ marginBottom: 6 }}>
              <Link
                to={
                  isAuthor
                    ? `/journal/${impact.token}`
                    : `/team/${userToken}/journal/${impact.token}`
                }
              >
                <Text style={{ lineHeight: '18px' }}>
                  {impact.title}{' '}
                  <span style={{ color: '#aaa', whiteSpace: 'nowrap' }}>
                    {formatDate(impact.date)}
                  </span>
                </Text>
              </Link>
            </li>
          ))}
        </ul>
      )}
      {journalResults.impacts.length === 0 && <Text>No journal entries</Text>}
    </Column>
  );
};
