import { CommentOutlined } from '@ant-design/icons';
import { findTemplateByToken } from '@shared/feedbackTemplates';
import { IFeedback, IFeedbackRequest, UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { BlockQuote } from '@web/components/BlockQuote';
import { Column } from '@web/components/layout';
import { QuestionResponses } from '@web/questions/QuestionResponses';
import * as React from 'react';

import { Header3, Text } from '../../components/typography';
import { UserFeedback } from './UserFeedback';

export const DimensionFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
  requester?: UserMapItem;
}> = ({ feedback, giver, receiver, requester }) => {
  const feedbackRequest = feedback.feedbackRequest;
  if (!feedbackRequest?.questionSetToken) {
    return null;
  }

  const templateToken = feedback.templateToken;
  const template = findTemplateByToken(templateToken);

  if (!template) {
    return null;
  }
  return (
    <UserFeedback giver={giver} receiver={receiver} date={feedback.updatedDate}>
      {requester && (
        <TemplatedFeedbackTitle
          feedbackRequest={feedbackRequest}
          requester={requester}
        />
      )}

      {feedbackRequest.context?.trim() && (
        <Column gap={6}>
          <Header3>Feedback on</Header3>
          <BlockQuote>
            <CommentOutlined
              style={{
                fontSize: 14,
                color: 'rgba(0,0,0,0.6)',
                marginRight: 12,
              }}
            />
            {feedbackRequest.context}
          </BlockQuote>
        </Column>
      )}

      <QuestionResponses
        questions={template.feedbackQuestions}
        responses={feedback.responses}
        receiverName={receiver.name}
      />
    </UserFeedback>
  );
};

const TemplatedFeedbackTitle: React.FC<{
  feedbackRequest: IFeedbackRequest;
  requester: UserMapItem;
}> = ({ feedbackRequest, requester }) => {
  const { user } = useAuth();

  const template = findTemplateByToken(feedbackRequest.questionSetToken);
  if (!template || !!feedbackRequest.reviewCycleToken) {
    return null;
  }
  const isRequester = requester.token === user.token;
  switch (template.token) {
    case 'frt_tracker_feedback':
    case 'frt_project_feedback':
      return (
        <Column gap={6}>
          <Text>
            Feedback requested by {requester.name} {isRequester && '(you)'} on:
          </Text>
          <BlockQuote>
            <CommentOutlined
              style={{
                fontSize: 14,
                color: 'rgba(0,0,0,0.6)',
                marginRight: 12,
              }}
            />
            {feedbackRequest.templateParameters?.topic ?? ''}
          </BlockQuote>
        </Column>
      );
    default:
      return (
        <Text>
          Feedback requested by {requester.name}
          {isRequester && ' (you)'}
        </Text>
      );
  }
};
