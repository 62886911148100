import {
  archiveRelationship,
  unarchiveRelationship,
} from '@client/OneOnOnesClient';
import { IOneOnOneDetails, canArchive } from '@shared/one-on-one';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { message } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const ArchiveButton: React.FC<{ oneOnOne?: IOneOnOneDetails }> = ({
  oneOnOne,
}) => {
  const navigate = useNavigate();

  if (!canArchive(oneOnOne) && !oneOnOne.relationship.archivedDate) {
    return null;
  }

  const handleArchive = async () => {
    try {
      await archiveRelationship(oneOnOne.relationship.token);
      void message.success('Success');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleUnarchive = async () => {
    try {
      await unarchiveRelationship(oneOnOne.relationship.token);
      void message.success('Success');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };

  return oneOnOne.relationship.archivedDate ? (
    <ConfirmButton
      title="Do you want to unarchive this 1-on-1?"
      onConfirm={handleUnarchive}
    >
      Unarchive
    </ConfirmButton>
  ) : (
    <ConfirmButton
      title="Do you want to archive this 1-on-1?"
      onConfirm={handleArchive}
    >
      Archive
    </ConfirmButton>
  );
};
