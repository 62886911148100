import {
  InboxOutlined,
  MessageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useGetProfile } from '@client/UsersClient';
import { Feature } from '@shared/features';
import { UserMapItem, UserToken } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { OneOnOneAlignment } from '@web/1on1s/OneOnOneAlignment';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { BackButton } from '@web/components/BackButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Row, Spacer, SplitLayout } from '@web/components/layout';
import { Button, Divider, Skeleton, Tooltip } from 'antd';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { FeedbackWidget } from './FeedbackWidget';
import { GoalsWidget } from './GoalsWidget';
import { JournalWidget } from './JournalWidget';
import { ManagerReviewCycleWidget } from './ManagerReviewCycleWidget';
import { TeamWidget } from './TeamWidget';
import { UserProfile } from './UserProfile';

export const ProfilePage: React.FC = () => {
  const { user: currentUser, isHrAdmin } = useAuth();
  const { userToken } = useParams<{ userToken?: UserToken }>();
  const { data: profile } = useGetProfile(userToken ?? currentUser.token);
  const { isMobile } = useResponsive();
  const { booleanValue: reviewCyclesEnabled } = useFeature(
    Feature.REVIEW_CYCLES,
  );
  const { booleanValue: goalsEnabled } = useFeature(Feature.GOALS);
  const { booleanValue: journalEnabled } = useFeature(Feature.JOURNAL);
  const { booleanValue: alignmentEnabled } = useFeature(
    Feature.ENABLE_ALIGNMENT_INDICATORS,
  );

  if (!profile) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const { managesPeople, user, organizationMembers } = profile;
  const reports = organizationMembers.filter((member) =>
    isManagerOf(member, user.token),
  );
  const isManager = isManagerOf(user, currentUser.token);
  const isSelf = user.token === currentUser.token;

  const feedbackReceived = (
    <FeedbackWidget
      user={user}
      extra={<FeedbackButtons user={user} isManager={isManager} />}
      placeholder="No feedback received yet"
    />
  );
  const feedbackGiven = (
    <FeedbackWidget given user={user} placeholder="No feedback given yet" />
  );
  const journalWidget = (
    <JournalWidget
      user={user}
      extra={
        <Tooltip title="Suggest a Journal Entry">
          <Link to={`/team/${user.token}/journal/suggest`}>
            <Button size="small">
              <PlusOutlined style={{ position: 'relative', top: -1 }} />
            </Button>
          </Link>
        </Tooltip>
      }
    />
  );
  const goalsWidget = (
    <GoalsWidget
      user={user}
      useTeamLink={!isSelf}
      extra={
        isManager || isHrAdmin ? (
          <Tooltip title="Add Goal">
            <Link to={`/team/${user.token}/goals/new`}>
              <Button size="small">
                <PlusOutlined style={{ position: 'relative', top: -1 }} />
              </Button>
            </Link>
          </Tooltip>
        ) : undefined
      }
    />
  );

  const alignmentWidget = <OneOnOneAlignment userToken={user.token} />;

  if (isMobile) {
    return (
      <PageContent>
        <PageHeader mobileTitle="Profile" navigateBack />
        <Column>
          <UserProfile user={user} />
          <Divider />
          <TeamWidget reports={reports} managerToken={user.token} />
          {journalEnabled && <Divider />}
          {journalEnabled && journalWidget}
          <Divider />
          {feedbackReceived}
          <Divider />
          {feedbackGiven}
          {goalsEnabled && <Divider />}
          {goalsEnabled && goalsWidget}
          {alignmentEnabled && <Divider />}
          {alignmentEnabled && alignmentWidget}
        </Column>
        <Spacer size={48} />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <BackButton />
      <Spacer size={12} />
      <UserProfile user={user} />
      <Spacer size={32} />
      <Pane style={{ minWidth: 0, padding: '24px 32px' }}>
        <SplitLayout>
          <Column gap={32} style={{ paddingBottom: 24 }}>
            {journalEnabled && journalWidget}
            {feedbackReceived}
            {feedbackGiven}
          </Column>
          <Column
            gap={24}
            style={{
              padding: '24px 0 48px 32px',
              borderLeft: '1px solid var(--border-color)',
              margin: '-24px 0',
            }}
          >
            {managesPeople && (
              <TeamWidget reports={reports} managerToken={user.token} />
            )}
            {goalsEnabled && goalsWidget}
            {alignmentEnabled && alignmentWidget}
          </Column>
        </SplitLayout>
        {reviewCyclesEnabled && <ManagerReviewCycleWidget user={user} />}
      </Pane>
    </PageContent>
  );
};

const FeedbackButtons: React.FC<{ isManager: boolean; user: UserMapItem }> = ({
  isManager,
  user,
}) => {
  return (
    <Row gap={6}>
      <Tooltip title="Give Feedback">
        <Link to={`/feedback/give?user=${user.token}`}>
          <Button size="small">
            <MessageOutlined style={{ position: 'relative', top: -1 }} />
          </Button>
        </Link>
      </Tooltip>
      {isManager ? (
        <Tooltip title="Request Feedback">
          <Link to={`/team/feedback/${user.token}/request`}>
            <Button size="small">
              <InboxOutlined style={{ position: 'relative', top: -1 }} />
            </Button>
          </Link>
        </Tooltip>
      ) : undefined}
    </Row>
  );
};
