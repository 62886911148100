import * as React from 'react';

import { Row } from './layout';

export interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const stopPropagation = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
  return false;
};

export const StopPropagation: React.FC<Props> = ({ children, style }) => {
  return (
    <Row
      gap={6}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      style={style}
    >
      {children}
    </Row>
  );
};
