import { formatDate } from '@shared/formatDate';
import { IUser } from '@shared/types';
import { Row, Spacer } from '@web/components/layout';
import { SubduedText } from '@web/components/typography';
import { Tooltip } from 'antd';
import * as React from 'react';

export const ItemDates: React.FC<{
  item: IItemWithDates;
  style: React.CSSProperties;
}> = ({ item, style }) => {
  return (
    <Row gap={6} style={style}>
      <Spacer size={28} />
      <Tooltip title={`Created by ${item.createdBy?.name ?? 'Flint'}`}>
        <SubduedText style={{ fontSize: 12 }}>
          Created {formatDate(item.createdDate)}
        </SubduedText>
      </Tooltip>
      {item.completedDate && (
        <>
          <SubduedText style={{ fontSize: 12 }}>•</SubduedText>
          <Tooltip title={`Resolved by ${item.completedBy.name}`}>
            <SubduedText style={{ fontSize: 12 }}>
              Resolved {formatDate(item.completedDate)}
            </SubduedText>
          </Tooltip>
        </>
      )}
    </Row>
  );
};
interface IItemWithDates {
  createdBy?: IUser;
  createdDate?: Date;
  completedBy?: IUser;
  completedDate?: Date;
}
