import { CloseOutlined } from '@ant-design/icons';
import { updateRelationship } from '@client/OneOnOnesClient';
import { IOneOnOneRelationship, ScheduleType } from '@shared/one-on-one';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { SelectDateTime } from '@web/components/SelectDateTime';
import { Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header2 } from '@web/components/typography';
import { Button, Radio, Select, message } from 'antd';
import { addWeeks } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

export const SchedulingBanner: React.FC<{
  relationship: IOneOnOneRelationship;
  onChange: () => void;
  onClose?: () => void;
}> = ({ onChange, onClose, relationship }) => {
  const [schedule, setSchedule] = React.useState<ScheduleType>(
    relationship.scheduleUpdatedDate ? relationship.schedule : undefined,
  );
  const [nextDate, setNextDate] = React.useState<Date>(
    initialNextDate(relationship),
  );

  const handleSave = async () => {
    try {
      await updateRelationship(relationship.token, {
        schedule,
        startDate: nextDate,
        scheduleUpdatedDate: new Date(),
      });
      onChange();
      onClose?.();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <SchedulingBannerContainer>
      <Header2>Schedule this 1-on-1</Header2>
      <Text>
        By adding a schedule, participants will receive helpful reminders
        leading up to each 1-on-1
      </Text>
      <Spacer size={12} />
      <Row gap={6}>
        <Radio
          checked={[ScheduleType.WEEKLY, ScheduleType.BIWEEKLY].includes(
            schedule,
          )}
          onClick={() => {
            setSchedule(ScheduleType.BIWEEKLY);
          }}
        />
        <Text>Next 1-on-1</Text>
        <Spacer size={6} />
        <SelectDateTime
          value={nextDate}
          onChange={setNextDate}
          disabled={schedule === ScheduleType.AD_HOC}
        />
        <Select
          value={
            [ScheduleType.WEEKLY, ScheduleType.BIWEEKLY].includes(schedule)
              ? schedule
              : ScheduleType.BIWEEKLY
          }
          onChange={setSchedule}
          style={{ width: 120 }}
          disabled={schedule === ScheduleType.AD_HOC}
        >
          <Select.Option value={ScheduleType.WEEKLY}>Weekly</Select.Option>
          <Select.Option value={ScheduleType.BIWEEKLY}>Biweekly</Select.Option>
        </Select>
      </Row>
      <Spacer size={6} />
      <Row gap={6}>
        <Radio
          checked={ScheduleType.AD_HOC === schedule}
          onClick={() => {
            setSchedule(ScheduleType.AD_HOC);
          }}
        />
        <Text>{"1-on-1's aren't regularly scheduled"}</Text>
      </Row>
      <Spacer size={18} />
      <Row gap={6}>
        <Button type="primary" onClick={handleSave} disabled={!schedule}>
          Save
        </Button>
      </Row>
      {onClose && (
        <CloseButton onClick={onClose}>
          <CloseOutlined />
        </CloseButton>
      )}
    </SchedulingBannerContainer>
  );
};

const SchedulingBannerContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 12px 18px;
  background: ${complimentaryColor.lighten(0.95)};
  border: 1px solid ${complimentaryColor.lighten(0.5)};
  border-radius: var(--default-border-radius);
  position: relative;
  top: -12px;

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  .ant-picker {
    border-radius: var(--default-border-radius);
  }

  .ant-btn:disabled,
  .ant-picker-disabled,
  .ant-select-disabled .ant-select-selector {
    background: white !important;
  }
`;

const CloseButton = styled(Button).attrs({ type: 'text' })`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const initialNextDate = (relationship?: IOneOnOneRelationship) => {
  let initial: Date;
  const now = Date.now();
  if (
    [ScheduleType.WEEKLY, ScheduleType.BIWEEKLY].includes(relationship.schedule)
  ) {
    const weekIncrement = relationship.schedule === ScheduleType.WEEKLY ? 1 : 2;
    initial = new Date(relationship.startDate);
    while (initial.getTime() < now) {
      initial = addWeeks(initial, weekIncrement);
    }
  } else {
    initial = new Date();
    initial.setHours(9);
    initial.setMinutes(0);
    initial.setMilliseconds(0);
    return initial;
  }
  return initial;
};
