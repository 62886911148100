import { possessive } from '@backend/common/possessive';
import {
  updatePrivateNote,
  updateSharedNote,
  useOneOnOneNotes,
} from '@client/OneOnOnesClient';
import { OneOnOneToken } from '@shared/one-on-one';
import { UserToken } from '@shared/types';
import { WebSocketEventType } from '@shared/webSocketEvents';
import { Column, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { useAutoSave } from '@web/reflections/AutoSaveState';
import { Skeleton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import humanparser from 'humanparser';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useWebSocketEvent } from '../common/useWebSocketEvent';

export const OneOnOneNotes: React.FC<{
  oneOnOneToken: OneOnOneToken;
  otherUserToken: UserToken;
}> = ({ oneOnOneToken, otherUserToken }) => {
  const { data: notes } = useOneOnOneNotes(oneOnOneToken);
  useWebSocketEvent(
    oneOnOneToken,
    WebSocketEventType.SHARED_NOTES_UPDATED,
    ({ payload }) => {
      if (payload[otherUserToken] !== undefined) {
        setOtherSharedNote(payload[otherUserToken]);
      }
    },
  );
  const [firstFetch, setFirstFetch] = useState(true);
  const [otherSharedNote, setOtherSharedNote] = useState('');
  const [sharedNote, setSharedNote] = useState('');
  const [privateNote, setPrivateNote] = useState('');
  useEffect(() => {
    setOtherSharedNote('');
    setSharedNote('');
    setPrivateNote('');
    setFirstFetch(true);
  }, [oneOnOneToken]);
  useEffect(() => {
    if (!notes) {
      return;
    }
    if (firstFetch) {
      setSharedNote(notes.sharedNotes);
      setPrivateNote(notes.privateNote);
      setOtherSharedNote(notes.otherSharedNotes);
      setFirstFetch(false);
    }
  }, [notes, firstFetch]);
  const { handleAutosave: handleSharedNoteSave } = useAutoSave({
    debounceWaitMillis: 200,
  });
  const { handleAutosave: handlePrivateNoteSave } = useAutoSave();

  const handleSharedNoteChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const note = e.target.value;
    setSharedNote(note);
    void handleSharedNoteSave(async () => {
      await updateSharedNote(oneOnOneToken, note);
    });
  };
  const handlePrivateNoteChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const note = e.target.value;
    setPrivateNote(note);
    void handlePrivateNoteSave(async () => {
      await updatePrivateNote(oneOnOneToken, note);
    });
  };

  if (!notes) {
    return <Skeleton />;
  }

  const otherParsedName = humanparser.parseName(notes.otherUser.name);
  return (
    <Column>
      <Header2>{possessive(otherParsedName.firstName)} Shared Notes</Header2>
      <Spacer size={6} />
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {otherSharedNote?.length > 0 ? otherSharedNote : 'No notes shared'}
      </Text>
      <Spacer size={12} />
      <Header2>Your Shared Notes</Header2>
      <Spacer size={6} />
      <TextArea
        value={sharedNote}
        onChange={handleSharedNoteChanged}
        autoSize
        style={{ minHeight: 80 }}
      />
      <Spacer size={12} />
      <Header2>Private Notes</Header2>
      <Spacer size={6} />
      <TextArea
        value={privateNote}
        onChange={handlePrivateNoteChanged}
        autoSize
        style={{ minHeight: 80 }}
      />
    </Column>
  );
};
