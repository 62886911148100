import { getQuestion } from '@shared/questions';
import {
  FeedbackVisibility,
  FeedbackVisibilityLabels,
  IFeedback,
  IFeedbackRequest,
  INACTIVE_USER_ROLES,
  ITask,
  IUser,
  UserMap,
} from '@shared/types';
import { post, put } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { FormButtons } from '@web/components/FormButtons';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { SelectUser } from '@web/components/users/SelectUser';
import { Button, Form, Select, Typography, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

export const GiveBasicFeedbackForm: React.FC<{
  user: IUser;
  receiver?: IUser;
  task?: ITask;
  feedbackRequest?: IFeedbackRequest;
  userMap?: UserMap;
  onSuccess?: () => void;
  onCancel?: () => void;
}> = ({
  user,
  task,
  feedbackRequest,
  onSuccess,
  onCancel,
  userMap,
  receiver,
}) => {
  const [form] = Form.useForm<IFeedback>();
  const [isSaving, setIsSaving] = React.useState(false);

  let visibilityOptions = [
    FeedbackVisibility.RECEIVER_MANAGER,
    FeedbackVisibility.MANAGER_ONLY,
    FeedbackVisibility.RECEIVER_ONLY,
  ];
  if (feedbackRequest?.visibility === FeedbackVisibility.RECEIVER_ONLY) {
    visibilityOptions = [FeedbackVisibility.RECEIVER_ONLY];
  } else if (
    feedbackRequest?.visibility === FeedbackVisibility.RECEIVER_MANAGER
  ) {
    visibilityOptions = [FeedbackVisibility.RECEIVER_MANAGER];
  }

  const handleGive = async () => {
    setIsSaving(true);
    let feedback: any;
    try {
      feedback = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const feedbackUrl = task
        ? `/feedback/basic?taskToken=${task.token}`
        : '/feedback/basic';
      await post<IFeedback>(feedbackUrl, {
        receiverToken: feedbackRequest?.receiverToken ?? receiver?.token,
        questionToken: feedbackRequest?.questionToken,
        ...feedback,
      });
      void message.success('Success');
      form.resetFields();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDecline = async () => {
    setIsSaving(true);
    try {
      await put(`/tasks/${task?.token}/decline`);
      void message.success('Feedback request was declined');
      form.resetFields();
      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const feedbackRequestReceiver =
    receiver ??
    (feedbackRequest?.receiverToken
      ? userMap?.[feedbackRequest.receiverToken]
      : undefined);
  let formDisabled = isSaving;
  if (feedbackRequestReceiver) {
    const isUserActive =
      !feedbackRequestReceiver.deletedDate &&
      !INACTIVE_USER_ROLES.includes(feedbackRequestReceiver.role);
    formDisabled = formDisabled || !isUserActive;
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      initialValues={
        visibilityOptions.length === 1
          ? { visibility: visibilityOptions[0] }
          : undefined
      }
    >
      {feedbackRequestReceiver && (
        <UserMessage
          user={feedbackRequestReceiver}
          large
          style={{ marginBottom: 24 }}
        />
      )}
      {task ? (
        <FeedbackInstructions task={task} />
      ) : !feedbackRequestReceiver ? (
        <Form.Item
          label="Who would you like to give feedback to?"
          name="receiverToken"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <SelectUser disabled={formDisabled} omitUserTokens={[user.token]} />
        </Form.Item>
      ) : null}
      <Form.Item
        label="Your feedback"
        name="text"
        rules={[{ required: true, message: 'Field is required' }]}
      >
        <TextArea style={{ height: '100px' }} disabled={formDisabled} />
      </Form.Item>
      <Form.Item
        label="Who will see this feedback?"
        name="visibility"
        rules={[{ required: true, message: 'Field is required' }]}
      >
        <Select disabled={formDisabled}>
          {visibilityOptions.map((visibility) => (
            <Select.Option key={visibility} value={visibility}>
              {FeedbackVisibilityLabels[visibility]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <FormButtons>
        <Button
          type="primary"
          disabled={formDisabled}
          onClick={() => {
            void handleGive();
          }}
        >
          Give feedback
        </Button>
        {task ? (
          <ConfirmButton
            description="Are you sure you wish to decline giving feedback?"
            disabled={formDisabled}
            onConfirm={handleDecline}
          >
            Decline
          </ConfirmButton>
        ) : (
          <Button disabled={formDisabled} onClick={onCancel}>
            Cancel
          </Button>
        )}
      </FormButtons>
    </Form>
  );
};
const FeedbackInstructions: React.FC<{ task: ITask }> = ({ task }) => {
  const question = getQuestion(task.feedbackRequests?.[0]?.questionToken);
  return (
    <Column>
      <Typography.Text>{task.title}:</Typography.Text>
      <Spacer size={10} />
      {question ? (
        <Typography.Text style={{ fontStyle: 'italic' }}>
          {question.text}
        </Typography.Text>
      ) : (
        <Typography.Text style={{ fontStyle: 'italic' }}>
          {task.feedbackRequests?.[0]?.text}
        </Typography.Text>
      )}
      <Spacer size={20} />
    </Column>
  );
};
