// Step 1: Choose Your Feedback Dimensions
import { IUser } from '@shared/types';
import { ImpersonationBanner } from '@web/app/ImpersonationBanner';
import { Logo } from '@web/app/Logo/Logo';
import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { put } from '@web/common/api';
import { Center, Column, ResponsiveRow, Spacer } from '@web/components/layout';
import { Header1, Header3, Text } from '@web/components/typography';
import { Button, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const OnboardingPage: React.FC = () => {
  const { user } = useAuth();
  const { isMobile } = useResponsive();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleGetStarted = async () => {
    setIsSaving(true);
    try {
      await put<IUser>(`/onboarding/complete`);
      window.location.href = '/redirect';
    } catch (error) {
      console.error(error);
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <OnboardingPageContainer>
      <ImpersonationBanner />
      <Spacer size={!isMobile ? 50 : 20} />
      <Center>
        <OnboardingPagePane>
          <Logo />
          {!isMobile ? (
            <Header1>Welcome to Flint, {user.name}!</Header1>
          ) : (
            <Header3>Welcome to Flint, {user.name}!</Header3>
          )}
          <ResponsiveRow
            style={{
              alignItems: isMobile ? 'center' : 'flex-start',
              gap: 20,
            }}
          >
            <img
              style={{
                width: 330,
                borderRadius: '20px',
              }}
              src="https://images.squarespace-cdn.com/content/v1/64149b26ee736e04b77bcab6/210e2fe2-12e2-4aad-9b2d-1f518114cb0e/My+project-7+%281%29.png?format=500w"
            />
            <Column
              style={{
                width: 330,
              }}
            >
              <Text>
                Flint is your go-to platform for exchanging timely, contextual
                feedback.
              </Text>
              <Spacer size={10} />
              <Text>
                Your journal in Flint makes it quick and easy to keep a record
                of your work, enabling the following benefits:
              </Text>
              <Spacer size={10} />
              <ul
                style={{
                  width: isMobile ? '360px' : '400px',
                  margin: 0,
                  padding: '0 25px',
                }}
              >
                <li>
                  <Text>Collect peer feedback on your work</Text>
                </li>
                <li>
                  <Text>Get recognition for your impact</Text>
                </li>
                <li>
                  <Text>Align on goals and progress with your manager</Text>
                </li>
                <li>
                  <Text>Save time in future performance reviews</Text>
                </li>
              </ul>
            </Column>
          </ResponsiveRow>
          <Button loading={isSaving} type="primary" onClick={handleGetStarted}>
            Get Started
          </Button>
        </OnboardingPagePane>
      </Center>
    </OnboardingPageContainer>
  );
};

const OnboardingPageContainer = styled.div`
  background: white;
  min-height: 100vh;
  width: 100%;

  ${IF_MOBILE} {
    background: white;
  }
`;

const OnboardingPagePane = styled.section`
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    width: 200px;
    height: 50px;
  }

  ${IF_MOBILE} {
    box-shadow: none;
    border: 0;
    gap: 20px;

    .ant-btn {
      width: 300px;
    }
  }
`;
